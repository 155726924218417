
import { create } from 'apisauce'
import { constants } from '../App.constants'


const api = create({
    baseURL: constants.apiUrl,
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    },
    paramsSerializer: (params) => {
        let combined = Object.entries(params).reduce(
            (map, [key, value]) => {
                if (value !== undefined) {
                    if (Array.isArray(value))
                        map.push(key + '=' + encodeURIComponent(JSON.stringify(value)).replace(/%2C/g, ','))
                    else
                        map.push(key + '=' + value)
                }
                return map
            },
            []
        ).join('&')

        return combined

    }
})

export default api