import { Event, PageView, initGA } from './Tracking';
import React, { Component } from 'react'
import { editTicket, makeTicketLive, changeWaitlist, dynamicOptions } from '../store/actions/editTicketActions';
import { getTicketImages, getTicketImagesFromDB, updateTicketUploads, uploadTicketImage } from '../store/actions/ticketsUploadActions';
import { googleTrackingCategory, googleTrackingLabel } from '../store/types';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import InfoIcon from '@material-ui/icons/InfoTwoTone';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import NumericInput from 'react-numeric-input';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import UploadTickets from './uploadTickets'
import classnames from 'classnames';
import { connect } from 'react-redux';
import { filter } from 'lodash'
import { getTicketPostings } from '../store/actions/manageTicketsActions';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = theme => ({
    root: {
        width: '100%',
        cursor: 'pointer'
    },
    rootExpansionPanel: {
        width: '100%',
    },
    summaryExpansionPanel: {
        padding: theme.spacing(1) + 'px ' + theme.spacing(2) + 'px',

    },
    expandedSummary: {
        margin: theme.spacing(0) + ' !important'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(12)
    },
    heading: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontSize: theme.spacing(3),
        fontWeight: 600
    },
    subHeading: {
        padding: theme.spacing(2),
        fontWeight: 600
    },
    inset: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    fragmentUpload: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flexDirection: 'column'

    },
    insetMid: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        backgroundColor: '#0000000f'
    },
    editBtnDiv: {
        display: 'flex',
        justifyContent: 'center'
    },
    closeBtnDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    buttonCancel: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        letterSpacing: '1px',
        color: theme.palette.primary.main,
        background: 'white',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        },
        border: '1px solid ' + theme.palette.primary.main
    },
    buttonBack: {
        paddingRight: theme.spacing(9),
        paddingLeft: theme.spacing(9),
        letterSpacing: '1px',
        color: theme.palette.primary.main,
        background: 'white',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        },
        border: '1px solid ' + theme.palette.primary.main
    },
    link: {
        textDecoration: 'none'
    },
    counter: {
        paddingLeft: '24px !important',
        paddingRight: '24px !important',
        height: '34px',
        outline: 'none'
    },
    counterDiv: {
        minWidth: '100px',
        marginLeft: theme.spacing(1),
        touchAction: 'manipulation',
        '& b': {
            background: theme.palette.primary.main + ' !important',
            '& i': {
                background: theme.palette.primary.contrastText + ' !important'
            }
        }
    },
    counterCustomWidth: {
        width: '138px'
    },
    bold: {
        fontWeight: 600,
        minWidth: '100px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    btnsDiv: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(10)
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    textAlignRight: {
        textAlign: 'right'
    },
    errMsg: {
        fontSize: theme.spacing(1.75),
        color: 'red'
    },
    titleDiv: {
        minWidth: '100px',
        flexShrink: 4,
        overflow: 'hidden',
        display: '-webkit-box',
        alignItems: 'center',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },

    freeToPaid: {},
    disabledColor: {
        color: 'grey'
    },
    liveNotLiveDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1.5)
    },
    indianRed: {
        color: 'indianred'
    },
    editTopDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'

    },
    link: {

        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    moreTicks: {
        verticalAlign: 'super',
        fontSize: theme.spacing(2),
        paddingLeft: theme.spacing(0.5)
    },
    uploadTicketsDiv: {
        fontSize: theme.spacing(1.75),
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    linkOverrideColor: {
        color: '#038ddd'
    },
    marginTopHelpLinksDiv: {
        marginTop: theme.spacing(4)
    },
    marginTopHelpTexts: {
        marginTop: theme.spacing(0.75)
    },
    stretch: {
        display: 'flex',
        alignItems: 'stretch'
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    noteSection: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacing(1.7),
        color: 'rgba(0, 0, 0, 0.54)'
    },
    green: {
        color: '#008000a6'
    },
    gray: {
        color: 'gray'
    },
    themeColor: {
        color: theme.palette.primary.main
    },
    icon: {
        fontSize: theme.spacing(2)
    },
    paddingRight: {
        paddingRight: theme.spacing(0.75)
    },
    iconExpand: {
        verticalAlign: "bottom",
        height: 20,
        width: 20
    },
    details: {
        alignItems: "center",
        padding: theme.spacing(0)
    },
    column: {
        flexBasis: "33.33%"
    },
    formLabel: {
        marginRight: 0
    }
});

class editTickets extends Component {

    cancel = () => {
        this.redirect(true);
    }
    editTicket = () => {
        Event(googleTrackingCategory.EDIT_TICKET, "Edit Ticket Clicked", localStorage.getItem('userId'));

        (this.state.ticketTitle.trim() === '' || this.state.ticketTitle === 'Click to add Title')
            ? this.setState({
                titleMandErr: true
            }, this.callbackAfterMandCheck)
            : this.setState({
                titleMandErr: false
            }, this.callbackAfterMandCheck)

    }

    callbackAfterMandCheck = () => {
        //console.log("After Mand Check"); console.log(this.state.titleMandErr);
        if (this.state.titleMandErr) {
            Event(googleTrackingCategory.EDIT_TICKET, "Title Not Entered Err", localStorage.getItem('userId'));
            return;
        }
        this.setState({
            disabledBtn: true
        })

        this
            .props
            .editTicket(localStorage.getItem('loginToken'), this.props.eventId, this.props.postingId, '', false, this.state.maxTicketsPerPerson, this.state.totalTickets, this.state.pricePerTicket, this.state.ticketTitle, this.redirect);

    }
    redirect = (noRedirect) => {

        this.props.changeWaitlist(localStorage.getItem('loginToken'), this.props.eventId, this.props.postingId, this.state.waitlist, () => {
            let ticketText = this.state.pricePerTicket > 0 ? 'Buy Tickets' : 'Get Tickets'
            let boughtTicketText = 'My Tickets'
            if (this.state.ticketText !== '') {
                ticketText = this.state.ticketText;
            }
            if (this.state.boughtTicketText != '') {
                boughtTicketText = this.state.boughtTicketText
            }

            this.props.dynamicOptions(localStorage.getItem('loginToken'), this.props.eventId, this.props.postingId, ticketText, boughtTicketText, () => {
                if (this.state.ticketImageModified) {
                    this
                        .props
                        .updateTicketUploads(localStorage.getItem('loginToken'), this.props.calendarId, this.props.eventId, this.props.postingId, (error) => {
                            if (!error) {

                                this
                                    .props
                                    .history
                                    .push('/manageTickets?posting=' + this.props.postingId);

                                this.props.closeDialog();

                            } else {
                                this.setState({
                                    disabledBtn: false
                                })
                            }

                        });
                } else {
                    this.props.closeDialog();
                }

            })
        })


    }

    handleWaitListChange = () => {
        let googletrackingMsg = !this.state.waitlist
            ? 'Waitlist turned on'
            : 'Waitlist turned off'

        Event(googleTrackingCategory.EDIT_TICKET, googletrackingMsg, localStorage.getItem('userId'));
        this.setState({
            disabledBtn: false,
            waitlist: !this.state.waitlist
        })
    }

    callbackFnAfterFetch = () => {

        let matchedPosting = filter(this.props.ticketPostings, o => o.posting === this.props.postingId)[0];
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });
        if (this.props.currentUser.userId !== matchedPosting.seller.userId || (matchedPosting.proxyUserInfo && (this.props.currentUser.userId === matchedPosting.seller.userId))) {
            if (this.props.permission === 'EDIT') {
                if (matchedPosting.proxyUserInfo && (this.props.currentUser.userId === matchedPosting.proxyUserInfo.userId)) {
                    this.setState({
                        showNote: true,
                        note: 'You have created this posting on behalf of ' + this.props.calendarOwner.name
                    })
                } else {
                    this.setState({
                        showNote: true,
                        note: 'You have edit rights in this calendar and so you can manage this posting by ' + this.props.calendarOwner.name
                    })
                }

            } else if (this.props.permission === 'ADD') {
                // PROXY USER ! SELLER DIFF BUT HE CREATED THIS
                if (matchedPosting.proxyUserInfo && (this.props.currentUser.userId === matchedPosting.proxyUserInfo.userId)) {
                    this.setState({
                        showNote: true,
                        note: 'You have created this posting on behalf of ' + this.props.calendarOwner.name
                    })
                }

            } else if (this.props.permission == 'OWNER') {
                if (matchedPosting.proxyUserInfo && matchedPosting.ticketAmount > 0) {
                    this.setState({
                        showNote: true,
                        note: matchedPosting.proxyUserInfo.name + ' have created this posting on your behalf (you will receive the payouts)'
                    })
                } else if (matchedPosting.proxyUserInfo && matchedPosting.ticketAmount == 0) {
                    this.setState({
                        showNote: true,
                        note: matchedPosting.proxyUserInfo.name + ' have created this posting on your behalf'
                    })
                } else if (!matchedPosting.proxyUserInfo && (matchedPosting.seller.userId != this.props.currentUser.userId)) {
                    if (matchedPosting.ticketAmount > 0) {
                        this.setState({
                            showNote: true,
                            note: 'Sold by ' + matchedPosting.seller.name + ' (you will not receive payouts)'
                        })
                    } else {
                        this.setState({
                            showNote: true,
                            note: 'Sold by ' + matchedPosting.seller.name
                        })
                    }

                }
            }
        } else {
            // NO PROXY AT ALL
            if (this.props.permission === 'ADD' || this.props.permission === 'EDIT') {
                // Add Permission But Own Posting

                this.setState({ showNote: true, note: 'You own this posting' })

            } else if (this.props.permission === 'OWNER') {

                if (matchedPosting.seller.userId == this.props.currentUser.userId) {
                    if (matchedPosting.ticketAmount > 0 && matchedPosting.isPostingLive) {
                        this.setState({ showNote: true, note: 'you own this posting (will receive payouts)' })
                    } else {
                        this.setState({ showNote: true, note: 'you own this posting' })
                    }

                }
            }
        }
        this.setState({
            ticketTitle: matchedPosting.title,
            totalTickets: matchedPosting.noOfTicketsIssued,
            maxTicketsPerPerson: matchedPosting.noOfTicketsForEach,
            pricePerTicket: matchedPosting.ticketAmount,
            isPostingLive: matchedPosting.isPostingLive,
            ticketText: matchedPosting.ticketText,
            boughtTicketText: matchedPosting.boughtTicketText,
            waitlist: matchedPosting.waitlist,
            pricePerTicketText: matchedPosting.ticketAmount > 0
                ? formatter.format(Math.abs(matchedPosting.ticketAmount))
                : 'Free'
        });
    }
    componentDidMount() {

        initGA('UA-106455531-7');
        PageView('/edit');

        this.props.ticketPostings && this.props.ticketPostings.length > 0
            ? this.callbackFnAfterFetch()
            : this
                .props
                .getTicketPostings(this.props.eventId, [], this.callbackFnAfterFetch);
        this
            .props
            .getTicketImagesFromDB(localStorage.getItem('loginToken'), this.props.calendarId, this.props.eventId, this.props.postingId);


    }

    state = {
        isPostingLive: false,
        waitlist: false,
        totalTicketsClicked: false,
        maxTicketClicked: false,
        pricePerTicketClicked: false,
        disabledBtn: true,
        open: false
    }

    formatAbs = (num) => {
        //console.log(num)
        return num
            ? (num <= 0
                ? (num === 0
                    ? 1
                    : Math.abs(num))
                : num)
            : 1
    }
    formatAmount = (num) => {
        //console.log("Format called" + num);
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formatter.format(Math.abs(num));
        //return  '$' + Math.abs(num)   ;
    }
    parseFn = (num) => {
        //console.log(num + "Parse" + num.replace(/^\$/, "").replace(/\,/g,""))
        return num
            .replace(/^\$/, "")
            .replace(/\,/g, "");
    }
    changeCounter = (valueAsNumber, valueAsString, element) => {
        ////console.log(element.name);
        let obj = {};
        element.name === 'pricePerTicket'
            ? (obj[element.name] = valueAsNumber
                ? valueAsNumber
                : 0)
            : (obj[element.name] = valueAsNumber
                ? valueAsNumber
                : 1);
        if (element.name === 'totalTickets') {
            obj['maxTicketsPerPerson'] = element.name == 'totalTickets' && (valueAsNumber <= this.state.maxTicketsPerPerson)
                ? valueAsNumber
                : this.state.maxTicketsPerPerson;

            if (valueAsNumber < this.state.totalTickets) {
                this
                    .props
                    .getTicketImages(valueAsNumber);
            }

        }

        if (element.name === 'maxTicketsPerPerson') {
            obj['maxTicketsPerPerson'] = (valueAsNumber <= this.state.totalTickets)
                ? valueAsNumber
                : this.state.totalTickets;
        }
        if (element.name == 'pricePerTicket') {
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });

            obj['pricePerTicketText'] = formatter.format(Math.abs(valueAsNumber))

        }
        obj['disabledBtn'] = false;
        this.setState(obj);

    }

    ticketTitleChanged = (e) => {
        //console.log(e.target.value);

        this.setState({
            disabledBtn: false,
            ticketTitle: e.target.value,
            titleMandErr: e.target.value === ''
                ? true
                : false
        })
    }
    ticketTextChanged = (e) => {
        this.setState({
            disabledBtn: false,
            ticketText: e.target.value
        })
    }
    boughtTicketTextChanged = (e) => {
        this.setState({
            disabledBtn: false,
            boughtTicketText: e.target.value
        })
    }
    totalTicketsEdit = () => {
        //console.log("In here");
        this.setState({ ticketTitleClicked: false, totalTicketsClicked: true, maxTicketClicked: false, pricePerTicketClicked: false, ticketTextClicked: false, boughtTicketTextClicked: false })
    }
    maxTicketsPerPersonEdit = () => {
        this.setState({ ticketTitleClicked: false, totalTicketsClicked: false, maxTicketClicked: true, pricePerTicketClicked: false, ticketTextClicked: false, boughtTicketTextClicked: false })
    }
    handleLiveChange = () => {
        //console.log(!this.state.isPostingLive ? 'Live' : 'Not Live');
        let googletrackingMsg = !this.state.isPostingLive
            ? 'Live Click '
            : 'Not Live Click'

        Event(googleTrackingCategory.EDIT_TICKET, googletrackingMsg, localStorage.getItem('userId'));

        this
            .props
            .makeTicketLive(localStorage.getItem('loginToken'), this.props.eventId, this.props.postingId, () => {
                this.setState({
                    isPostingLive: !this.state.isPostingLive
                })
            });

    }
    blur = () => {

        this.setState({ ticketTitleClicked: false, totalTicketsClicked: false, maxTicketClicked: false, pricePerTicketClicked: false, ticketTextClicked: false, boughtTicketTextClicked: false })
    }
    pricePerTicketEdit = () => {
        this.setState({ ticketTitleClicked: false, totalTicketsClicked: false, maxTicketClicked: false, pricePerTicketClicked: true, ticketTextClicked: false, boughtTicketTextClicked: false })
    }
    ticketTitleEdit = () => {
        this.setState({ ticketTitleClicked: true, totalTicketsClicked: false, maxTicketClicked: false, pricePerTicketClicked: false, ticketTextClicked: false, boughtTicketTextClicked: false })
    }

    ticketTextEdit = () => {
        Event(googleTrackingCategory.EDIT_TICKET, "Ticket Text Edit clicked", localStorage.getItem('userId'));
        this.setState({ ticketTitleClicked: false, totalTicketsClicked: false, maxTicketClicked: false, pricePerTicketClicked: false, ticketTextClicked: true, boughtTicketTextClicked: false })
    }

    boughtTicketTextEdit = () => {
        Event(googleTrackingCategory.EDIT_TICKET, "Bought Ticket Text Edit clicked", localStorage.getItem('userId'));

        this.setState({ ticketTitleClicked: false, totalTicketsClicked: false, maxTicketClicked: false, pricePerTicketClicked: false, ticketTextClicked: false, boughtTicketTextClicked: true })

    }

    openUpload = () => {
        this.setState({ open: true })
    }
    handleUploadClose = (isModified) => {
        this.setState({
            open: false,
            disabledBtn: !isModified,
            ticketImageModified: isModified
        })


    }

    render() {
        //console.log("Edit render");
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.editTopDiv}>
                    <div className={classes.heading} onClick={this.blur}>Edit Tickets

                    </div>

                    <div className={classes.liveNotLiveDiv}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={< Switch color="primary" checked={
                                        this.state.isPostingLive
                                    }
                                        onChange={
                                            this.handleLiveChange
                                        }
                                        value={
                                            this.state.isPostingLive
                                        } />}
                                    label={this.state.isPostingLive
                                        ? <span className={classes.green}>Live</span>
                                        : <span className={classes.indianRed}>Not Live</span>} />

                            </FormGroup>

                        </FormControl>

                    </div>

                </div>

                <div className={classes.subHeading} onClick={this.blur}>Please edit various ticket options</div>
                {this.state.showNote && <div className={classes.noteSection}>
                    <InfoIcon
                        className={classnames(classes.themeColor, classes.paddingRight, classes.icon)}></InfoIcon>
                    {this.state.note
                    }
                </div>
                }
                <List className={classes.root}>
                    <ListItem className={classes.stretch}>
                        <ListItemText
                            primary="Ticket Title"
                            onClick={this.ticketTitleEdit}
                            secondary="Name of the tickets people will see" /> {this.state.ticketTitleClicked && <TextField
                                name="ticketTitle"
                                className={classes.textField}
                                autoFocus={true}
                                value={this.state.ticketTitle === 'Click to edit Title'
                                    ? ''
                                    : this.state.ticketTitle}
                                onChange={this.ticketTitleChanged}
                                margin="normal"
                                inputProps={{
                                    'aria-label': 'bare'
                                }} />}
                        {!this.state.ticketTitleClicked && <div
                            className={classnames(classes.bold, classes.textAlignRight, classes.titleDiv)}
                            onClick={this.ticketTitleEdit}>{this.state.ticketTitle} {this.state.titleMandErr && <div
                                className={classnames(classes.bold, classes.textAlignCenter, classes.errMsg)}
                                onClick={this.totalTicketsEdit}>Please enter title</div>}</div>}
                    </ListItem>
                    <Divider component="li" className={classes.inset} />
                    <ListItem className={classes.stretch}>
                        <ListItemText
                            primary="Total Tickets"
                            secondary="Total Availability"
                            onClick={this.totalTicketsEdit} />
                        <div className={classes.fragmentUpload}>

                            {!this.state.totalTicketsClicked && <div className={classes.bold} onClick={this.totalTicketsEdit}>{this.state.totalTickets}</div>}
                            {this.state.totalTicketsClicked && <div className={classes.counterDiv}><NumericInput
                                name="totalTickets"
                                className={classes.counter}
                                value={this.state.totalTickets}
                                onChange={this.changeCounter}
                                min={1}
                                max={100}
                                step={1}
                                precision={0}
                                size={5}
                                noValidate
                                inputMode="numeric"
                                format={this.formatAbs}
                                mobile /></div>}
                            <div className={classes.uploadTicketsDiv}>
                                {this.state.open && <UploadTickets
                                    numberOfTickets={this.state.totalTickets}
                                    open={this.state.open}
                                    handleClose={this.handleUploadClose}
                                    token={queryString
                                        .parse(this.props.location.search)
                                        .token}></UploadTickets>}

                                {this.props.ticketImages && this.props.ticketImages.length == 0 && <div className={classes.link} onClick={this.openUpload}>Upload your own tickets ?
                                </div>}
                                {<div
                                    className={
                                        classes.link
                                    }
                                    onClick={
                                        this.openUpload
                                    } > {
                                        this
                                            .props
                                            .ticketImages
                                            .map((ticketImg, index) => (index < 3 && <img
                                                key={index}
                                                style={{
                                                    width: "30px",
                                                    height: 'auto',
                                                    paddingRight: '4px'
                                                }}
                                                src={ticketImg.ticketImage} />))
                                    }
                                    {
                                        this.props.ticketImages && this.props.ticketImages.length > 3 && <span className={classes.moreTicks}>+ {this.props.ticketImages.length - 3}</span>
                                    } </div>}
                            </div>
                        </div>
                    </ListItem>
                    <Divider component="li" className={classes.insetMid} />
                    <ListItem className={classes.stretch}>
                        <ListItemText
                            primary="Maximum Tickets Per Person"
                            onClick={this.maxTicketsPerPersonEdit}
                            secondary="Number of tickets each person can get"></ListItemText>
                        {!this.state.maxTicketClicked && <div className={classes.bold} onClick={this.maxTicketsPerPersonEdit}>{this.state.maxTicketsPerPerson}</div>}

                        {this.state.maxTicketClicked && <div className={classes.counterDiv}>
                            <NumericInput
                                name="maxTicketsPerPerson"
                                className={classes.counter}
                                value={this.state.maxTicketsPerPerson}
                                onChange={this.changeCounter}
                                min={1}
                                max={this.state.totalTickets}
                                step={1}
                                precision={0}
                                size={5}
                                noValidate
                                inputMode="numeric"
                                format={this.formatAbs}
                                mobile />
                        </div>}

                    </ListItem>
                    <Divider component="li" className={classes.inset} />
                    <ListItem className={classes.stretch}>
                        <ListItemText
                            primary="Price Per Ticket"
                            onClick={this.state.pricePerTicket > 0
                                ? this.pricePerTicketEdit
                                : undefined}
                            classes={this.state.pricePerTicket == 0
                                ? {
                                    secondary: classes.freeToPaid
                                }
                                : undefined}
                            secondary={this.state.pricePerTicket > 0
                                ? 'You can edit the price for the ticket'
                                : 'You cannot edit the price for a free ticket'} /> {!this.state.pricePerTicketClicked && <div
                                    className={classes.bold}
                                    onClick={this.state.pricePerTicket > 0
                                        ? this.pricePerTicketEdit
                                        : undefined}>{this.state.pricePerTicket && this.state.pricePerTicket > 0
                                            ? <span>{this.state.pricePerTicketText}
                                            </span>
                                            : <span className={classes.disabledColor}>Free</span>}</div>}

                        {this.state.pricePerTicketClicked && <div className={classes.counterDiv}><NumericInput
                            name="pricePerTicket"
                            className={classnames(classes.counter, classes.counterCustomWidth)}
                            value={this.state.pricePerTicket}
                            onChange={this.changeCounter}
                            min={0}
                            step={1}
                            precision={2}
                            size={10}
                            noValidate
                            inputMode="numeric"
                            format={this.formatAmount}
                            parse={this.parseFn}
                            mobile />
                        </div>}
                    </ListItem>
                    <Divider component="li" className={classes.inset} />

                    <div className={classes.rootExpansionPanel}>
                        <ExpansionPanel elevation={0}>
                            <ExpansionPanelSummary
                                classes={{
                                    root: classes.summaryExpansionPanel,
                                    expanded: classes.expandedSummary
                                }
                                }
                                expandIcon={<ExpandMoreIcon className={classes.iconExpand} />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                            >
                                <div className={classes.column}>
                                    <Typography>
                                        Advanced options
                                    </Typography>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.details}>
                                <List className={classes.root}>
                                    <ListItem className={classes.stretch}>
                                        <ListItemText
                                            primary="Ticket Button Text"
                                            onClick={this.ticketTextEdit}
                                            secondary="Text in the ticket purchase button " /> {this.state.ticketTextClicked && <TextField
                                                name="ticketText"
                                                className={classes.textField}
                                                autoFocus={true}
                                                value={!this.state.ticketText && this.state.ticketText != ''
                                                    ? this.state.pricePerTicket > 0 ? 'Buy Tickets' : 'Get Tickets'
                                                    : this.state.ticketText}
                                                onChange={this.ticketTextChanged}
                                                margin="normal"
                                                inputProps={{
                                                    'aria-label': 'bare'
                                                }} />}
                                        {!this.state.ticketTextClicked && <div
                                            className={classnames(classes.bold, classes.textAlignRight, classes.titleDiv)}
                                            onClick={this.ticketTextEdit}>{!this.state.ticketText
                                                ? this.state.pricePerTicket > 0 ? 'Buy Tickets' : 'Get Tickets'
                                                : this.state.ticketText} </div>}
                                    </ListItem>
                                    <Divider component="li" className={classes.inset} />
                                    <ListItem className={classes.stretch}>
                                        <ListItemText
                                            primary="After Purchase Button Text"
                                            onClick={this.boughtTicketTextEdit}
                                            secondary="Text in the button which users will see to view their purchased tickets" /> {this.state.boughtTicketTextClicked && <TextField
                                                name="boughtTicketText"
                                                className={classes.textField}
                                                autoFocus={true}
                                                value={!this.state.boughtTicketText && this.state.boughtTicketText != ''
                                                    ? 'My Tickets'
                                                    : this.state.boughtTicketText}
                                                onChange={this.boughtTicketTextChanged}
                                                margin="normal"
                                                inputProps={{
                                                    'aria-label': 'bare'
                                                }} />}
                                        {!this.state.boughtTicketTextClicked && <div
                                            className={classnames(classes.bold, classes.textAlignRight, classes.titleDiv)}
                                            onClick={this.boughtTicketTextEdit}>{!this.state.boughtTicketText
                                                ? 'My Tickets'
                                                : this.state.boughtTicketText}</div>}
                                    </ListItem>

                                    <Divider component="li" className={classes.inset} />
                                    <ListItem className={classes.stretch}>
                                        <ListItemText
                                            primary="Waitlist"
                                            onClick={this.blur}
                                            secondary="Users will be able to enroll in waitlist after ticket are sold out and will get tickets alloted automatically if any of the ticket holders 'let go' of their tickets" />

                                        <div className={classes.liveNotLiveDiv}>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        classes={{
                                                            root: classes.formLabel
                                                        }}
                                                        control={< Switch color="primary" checked={
                                                            this.state.waitlist
                                                        }
                                                            onChange={
                                                                this.handleWaitListChange
                                                            }
                                                            value={
                                                                this.state.waitlist
                                                            } />}
                                                        label={this.state.waitlist
                                                            ? <span className={classes.green}>On</span>
                                                            : <span className={classes.gray}>Off</span>} />

                                                </FormGroup>

                                            </FormControl>

                                        </div>

                                    </ListItem>
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>


                </List>

                <div className={classes.btnsDiv} onClick={this.blur}>
                    <div className={classes.editBtnDiv}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={this.state.disabledBtn}
                            className={classes.button}
                            onClick={this.editTicket}>
                            Update Tickets
                        </Button>
                    </div>
                    {/*<div className={classes.closeBtnDiv}>
                        {!this.state.disabledBtn && <Button
                            variant="contained"
                            color="default"
                            className={classes.buttonCancel}
                            onClick={this.cancel}>
                            Cancel Changes
                        </Button>}
                        {this.state.disabledBtn && <Button
                            variant="contained"
                            color="default"
                            className={classes.buttonBack}
                            onClick={this.cancel}>
                            Back
                        </Button>}
                        </div> */}

                </div>

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return { ticketPostings: state.ticketPostings.postings, ticketImages: state.ticketsUpload.ticketImages, currentUser: state.authentication.currentUser, permission: state.authentication.permission, calendarOwner: state.authentication.calendarOwner }
}
const mapDispatchToProps = (dispatch) => {

    return {
        getTicketPostings: (eventId, filterArr, callback) => dispatch(getTicketPostings(eventId, filterArr, callback)),
        changeWaitlist: (token, eventId, postingId, waitlist, callback) => dispatch(changeWaitlist(token, eventId, postingId, waitlist, callback)),
        dynamicOptions: (token, eventId, postingId, ticketText, boughtTicketText, callback) => dispatch(dynamicOptions(token, eventId, postingId, ticketText, boughtTicketText, callback)),
        editTicket: (token, eventId, postingId, desc, isValidationNeeded, maxClaimableTickets, noOfTicketsIssued, ticketAmount, title, callbackFn) => dispatch(editTicket(token, eventId, postingId, desc, isValidationNeeded, maxClaimableTickets, noOfTicketsIssued, ticketAmount, title, callbackFn)),
        makeTicketLive: (token, eventId, postingId, callbackFn) => dispatch(makeTicketLive(token, eventId, postingId, callbackFn)),
        getTicketImages: (numberOfTickets) => dispatch(getTicketImages(numberOfTickets)),
        getTicketImagesFromDB: (token, calendarId, eventId, postingId) => dispatch(getTicketImagesFromDB(token, calendarId, eventId, postingId)),
        updateTicketUploads: (token, calendarId, eventId, postingId, callbackFn) => dispatch(updateTicketUploads(token, calendarId, eventId, postingId, callbackFn))

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(editTickets)))
