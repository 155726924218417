import {dispatchErrorWrap, dispatchSuccessWrap} from './genericActions'

import {actionTypes} from '../types'
import {constants} from '../../App.constants';
import {filter} from 'lodash';
import moment from 'moment-timezone'

const axios = require('axios');

export const getAllMyCalendars = (token, callbackFn) =>{
    return async(dispatch) => {
        try {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }
            const payload = await axios.get(constants.apiUrl + "/calendar/myCalendars?ignoreDeleted=true", header);
            
            dispatchSuccessWrap(actionTypes.GET_MY_CALENDARS, payload.data, dispatch);
            
            callbackFn(null, filter(payload.data, (cal)=>{return cal.permissions && (cal.permissions.canEdit || cal.permissions.canAdd) }) )


        }catch(error){
            dispatchErrorWrap(error, dispatch);
            callbackFn(error)
        }
    }
}
export const getCalendarEvents = (token, calendarId, callbackFn) =>{
    return async(dispatch) => {
        try {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }
            const payload = await axios.get(constants.apiUrl + "/calendars/" + calendarId + "/events?showDeleted=false&timeMin=" + new Date(moment().subtract(30,'days').format('YYYY-MM-DD')).toISOString(), header);
            
            dispatchSuccessWrap(actionTypes.GET_CALENDAR_EVENTS, payload.data, dispatch);
            
            callbackFn(null, payload.data )


        }catch(error){
            dispatchErrorWrap(error, dispatch);
            callbackFn(error)
        }
    }
}

export const createNewCalendar = (token, calendarName, visibility, callbackFn) => {
    return async(dispatch) => {
        // Make async call
        try {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }

            let reqPayload = {
                "name": calendarName,
                "calendarSettings": {
                    "enableEta": true,
                    "enableNotice": true
                },
                "color": "#4256c1",
                "defaultPermissions": {
                    "canView": true,
                    "canAdd": true,
                    "canEdit": false,
                    "canShare": true
                },
                "lastActionType": "create",
                "visibility": visibility
            }

            const payload = await axios.post(constants.apiUrl + "/calendar", reqPayload, header);
            console.log('Payload On Creating Cal', payload);

            dispatchSuccessWrap(actionTypes.CREATE_NEW_CALENDAR, payload.data, dispatch);
            callbackFn(null, payload.data)

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
            callbackFn(error)
        }

    }
}

export const createNewEvent = (token, eventCreationObj, callbackFn) => {
    return async(dispatch) => {

        try {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }

            const eventPayload = await axios.post(constants.apiUrl + "/event/", eventCreationObj, header);

            dispatchSuccessWrap(actionTypes.CREATE_NEW_EVENT, eventPayload.data, dispatch);
            callbackFn(null, eventPayload.data)

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
            callbackFn(error)
        }
    }
}


export const simulate = (sleepTime, postingId, cb) =>{
    return async(dispatch, getState) =>{
        //console.log(postingId);
        await axios.get('https://httpstat.us/200?sleep='+sleepTime);
        cb(postingId);
    }
}
export const postTickets = (token, type, calendarId, desc, eventId, isValidationNeeded, maxClaimableTickets, noOfTicketsIssued, ticketAmount, title, callbackFn, seller) => {
    return async (dispatch, getState) => {
        
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }
            let reqPayload = {};
            reqPayload.calendarId = calendarId;
            reqPayload.desc = desc;
            reqPayload.eventId = eventId;
            reqPayload.isValidationNeeded = isValidationNeeded;
            reqPayload.maxClaimableTickets = maxClaimableTickets;
            reqPayload.noOfTicketsIssued = noOfTicketsIssued;
            reqPayload.ticketAmount = ticketAmount;
            reqPayload.title = title;
            if(seller){
                reqPayload.seller = seller;
            }

            const payload = await axios.post(constants.ticketsBaseUrl + "/admin/tickets/" + type, reqPayload, headers);
            //console.log(payload.data.postingId);
            
            let makeLivePayload = {};
            makeLivePayload.modifyType = 'stop';
            makeLivePayload.posting = payload.data.postingId;
            const payloadAfterLive = await axios.put(constants.ticketsBaseUrl + "/admin/tickets/" + eventId, makeLivePayload, headers);

            if(!payloadAfterLive.data.error){
                callbackFn(payloadAfterLive.data.posting)
                dispatchSuccessWrap(actionTypes.POST_TICKETS, payloadAfterLive.data, dispatch);

            }else{
                dispatchErrorWrap('Error in ticket Posting creation', dispatch)
            }
            
        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }

    }


}

