import React, { Component } from 'react';

import { autoLoginUser, autoSignOut } from '../store/actions/authenticationActions'
import { connect } from 'react-redux';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid'

class AutoLogin extends Component {

    componentDidMount() {

        this.props.autoLoginUser(this.props.match.params.loginToken).then((success) => {
            if (queryString.parse(this.props.location.search).path) {
                this.props.history.push(`/${queryString.parse(this.props.location.search).path}`)
            } else {
                this.props.history.push('/');
            }
        }, (error) => {
            this.props.autoSignOut().then(() => {
                this.props.history.push('/SignIn')
            })
        })
    }

    render() {
        return <Grid container item justify="center" alignItems="center">
            <CircularProgress></CircularProgress>
        </Grid>
    }
}


const mapDispatchToProps = (dispatch) => {

    return {
        autoLoginUser: (token) => dispatch(autoLoginUser(token)),
        autoSignOut: () => dispatch(autoSignOut())
    }

}
export default connect(null, mapDispatchToProps)(AutoLogin);