import {Link as ReactLink, withRouter} from 'react-router-dom';
import {appStoreLinks, googleTrackingCategory, googleTrackingLabel} from '../../store/types'

import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {Event} from '../Tracking';
import {Link} from '@material-ui/core';
import React from 'react'
import classnames from 'classnames'
import {connect} from 'react-redux';
import {isAndroid} from 'react-device-detect';
import queryString from 'query-string';
import ticketImg from '../../assets/images/ticketSuccess.png';
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(4),
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            width: '600px'
        },
        marginLeft : 'auto',
        marginRight : 'auto'
       
        
    },
    imageDiv: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    linkDiv: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: theme.spacing(3)
    },
    linkDivSuccess: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        background: theme.palette.primary.main,
        borderRadius: theme.spacing(2),
        color: theme.palette.primary.contrastText
    },
    linkDivDone: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: theme.spacing(4)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px'
    },

    buttonCustom1: {
        color: theme.palette.primary.main,
        background: 'white',
        border: '1px solid ' + theme.palette.primary.main
    },
    buttonCustom2: {
        color: 'white',
        background: '#1dc21d',
        border: '1px solid #1dc21d',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: '#1dc21d'
        }
    },
    textDiv: {
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    textDivSuccess: {
        fontWeight: 500,
        paddingLeft: theme.spacing(1)

    },
    link: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    }

})

function successPage(props) {

    const {classes} = props;

    const handleClick = (e) => {
       // if (e.target.name) 
         //   Event(googleTrackingCategory.BANNER_SUCCESS_TICKET, e.target.name + " Button Clicked", props.match.params.eventId);
    }
    const handleDone = () => {
        if (props.ticketImages && props.ticketImages.length > 0) {
            props.saveSelectedTicketImages([]);

        }
        props
            .history
            .push('/manageTickets?posting='+ props.match.params.postingId)
    }

    return (
        <div className={classes.root}>

            <div className={classes.imageDiv}>
                <img alt="loader" src={ticketImg} height="100"></img>
            </div>
            <div className={classes.linkDivSuccess}>
                <div>
                    <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                </div>
                <div className={classes.textDivSuccess}>Congratulations. Your tickets have been created successfully</div>
            </div>

            
            <div className={classes.linkDiv}>
                <div className={classes.textDiv}>You can add more ticket types to this event, share these tickets to users in allcal; get shareable link to share it with non allcal users, get analytics on ticket sales and much more ! </div>

            </div>
           
            <div className={classes.linkDivDone}>
                <div className={classes.textDiv}>You can use our validator app to check-in/out users with tickets</div>
                <div>
                    <Link
                        className={classes.link}
                        href={isAndroid
                        ? appStoreLinks.ANDROID_TICKET_VALIDATOR_URL
                        : appStoreLinks.IOS_TICKET_VALIDATOR_URL}
                        target="_blank">
                        <Button
                            variant="contained"
                            name="Allcal Validator App"
                            onClick={handleClick}
                            color="default"
                            className={classnames(classes.button, classes.buttonCustom1)}>
                            Allcal Validator app
                        </Button>
                    </Link>

                </div>

            </div>

            <div className={classes.linkDiv}>
                <div>
                    <Button
                        variant="contained"
                        color="default"
                        name="Done"
                        className={classnames(classes.button, classes.buttonCustom2)}
                        onClick={handleDone}>
                        Done
                    </Button>
                </div>
            </div>

        </div>
    )
}



export default withStyles(styles)(withRouter(successPage))
