import { dispatchErrorWrap, dispatchSuccessWrap } from './genericActions'

import { actionTypes } from '../types'
import { constants } from '../../App.constants';

const axios = require('axios');

export const autoLoginUser = (token) => {
    return async (dispatch) => {
        let header = {
            headers: {
                'Content-Type': 'application/json',
                'login-token': token
            }
        }
        try {
            const payload = await axios.get(constants.apiUrl + "/account/", header);
            let stripeConnected;
            if (payload.data && payload.data.userId) {

                localStorage.setItem('loginToken', token);
                localStorage.setItem('email', payload.data.email);
                localStorage.setItem('userId', payload.data.userId);
                localStorage.setItem('userName', payload.data.name);
                localStorage.setItem('user', JSON.stringify(payload.data));

                let headerForStripeCheck = {
                    headers: {
                        'Content-Type': 'application/json',
                        'login-token': token
                    }
                }

                try {
                    const payloadForStripeVerification = await axios.get(constants.ticketsBaseUrl + "/connectedUser", headerForStripeCheck);
                    payloadForStripeVerification.data.connected
                        ? stripeConnected = true
                        : stripeConnected = false
                } catch (err) { }

                dispatchSuccessWrap(actionTypes.SIGN_IN, { isLoginTokenValid: true, currentUser: payload.data, stripeConnected: stripeConnected }, dispatch);

            }


        } catch{
            dispatchErrorWrap(actionTypes.SIGN_IN, { isLoginTokenValid: false, currentUser: null, stripeConnected: false }, dispatch);

        }

    }
}
export const signIn = (email, password) => {
    return async (dispatch) => {
        try {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }

            let reqPayload = {
                email: email,
                password: password
            }

            const payload = await axios.post(constants.apiUrl + "/auth/login/", reqPayload, header);

            let stripeConnected;
            localStorage.setItem('loginToken', payload.data.loginToken);
            localStorage.setItem('email', payload.data.email);
            localStorage.setItem('userId', payload.data.userId);
            localStorage.setItem('userName', payload.data.name);
            localStorage.setItem('user', JSON.stringify(payload.data));
            let headerForStripeCheck = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': payload.data.loginToken
                }
            }

            try {
                const payloadForStripeVerification = await axios.get(constants.ticketsBaseUrl + "/connectedUser", headerForStripeCheck);
                payloadForStripeVerification.data.connected
                    ? stripeConnected = true
                    : stripeConnected = false

                //console.log('payloadForStripeVerification', stripeConnected);

            } catch (err) { }
            dispatchSuccessWrap(actionTypes.SIGN_IN, { isLoginTokenValid: true, currentUser: payload.data, stripeConnected: stripeConnected }, dispatch);

        } catch (error) {
            dispatchErrorWrap(actionTypes.SIGN_IN, { isLoginTokenValid: false, currentUser: null, stripeConnected: false }, dispatch);
        }

    }


}
export const autoSignOut = () => {
    return async (dispatch) => {
        localStorage.removeItem('loginToken');
        localStorage.removeItem('email');
        localStorage.removeItem('userId');
        localStorage.removeItem('userName');
        localStorage.removeItem('user');
        dispatchSuccessWrap(actionTypes.RESET_POSTINGS_STATE, {}, dispatch)
        dispatchSuccessWrap(actionTypes.CLEAR_IMAGES, {}, dispatch)
        dispatchSuccessWrap(actionTypes.SIGN_OUT, { isLoginTokenValid: false, currentUser: undefined }, dispatch)
    }

}

export const signOut = () => {
    return async (dispatch, getState) => {
        let isLoginTokenValid = false;
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }
            let reqPayload = JSON.parse(localStorage.getItem('user'));

            const payload = await axios.post(constants.apiUrl + "/auth/logout", reqPayload, headers);
            localStorage.removeItem('loginToken');
            localStorage.removeItem('email');
            localStorage.removeItem('userId');
            localStorage.removeItem('userName');
            localStorage.removeItem('user');
            dispatchSuccessWrap(actionTypes.RESET_POSTINGS_STATE, {}, dispatch)
            dispatchSuccessWrap(actionTypes.CLEAR_IMAGES, {}, dispatch)
            dispatchSuccessWrap(actionTypes.SIGN_OUT, { isLoginTokenValid: false, currentUser: undefined }, dispatch)

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }

    }


}

export const checkIfLoggedIn = () => {
    return async (dispatch, getState) => {

        let isLoginTokenValid = false;
        let currentUser;
        let stripeConnected = false;

        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'login-token': localStorage.getItem('loginToken')
            }
        }

        try {

            const payload = await axios.get(constants.apiUrl + "/account", headers);
            //console.log('payload cal Info', payload);
            if (payload.data && payload.data.userId) {
                isLoginTokenValid = true;
                currentUser = payload.data;

            } else {
                isLoginTokenValid = false;
            }

        } catch (error) {
            isLoginTokenValid = false;
        }

        try {
            const payloadForStripeVerification = await axios.get(constants.ticketsBaseUrl + "/connectedUser?owner=" + currentUser.userId, headers);
            payloadForStripeVerification.data.connected
                ? stripeConnected = true
                : stripeConnected = false

            //console.log('payloadForStripeVerification', stripeConnected);

        } catch (err) { }

        dispatchSuccessWrap(actionTypes.CHECK_AUTHENTICATION_TOKEN, {
            isLoginTokenValid: isLoginTokenValid,
            currentUser: currentUser,
            stripeConnected: stripeConnected

        }, dispatch)

    }
}

export const getCalendarInfoAndStripeStatus = (token, calId) => {
    return async (dispatch, getState) => {

        let ownerStripeConnected = false;
        let calendarOwner;
        let visibility;
        let permission;

        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'login-token': token
            }
        }
        if (calId) {

            const payload = await axios.get(constants.apiUrl + "/calendars/" + calId, headers);
            //console.log('payload cal Info', payload);
            if (payload.data.calendarId) {
                visibility = payload.data.visibility;
                if (payload.data.currentUser) {
                    if (payload.data.currentUser.isOwner) {
                        permission = 'OWNER';
                    } else if (payload.data.currentUser.permissions && (payload.data.currentUser.permissions.canEdit || payload.data.currentUser.permissions.canAdd)) {

                        permission = payload.data.currentUser.permissions.canEdit
                            ? 'EDIT'
                            : 'ADD';
                    }
                    calendarOwner = payload.data.owner;
                } else {
                    //Public calendar
                }

            }

            const payloadForStripeVerification = await axios.get(constants.ticketsBaseUrl + "/connectedUser?owner=" + calendarOwner.userId, headers);
            payloadForStripeVerification.data.ownerConnected
                ? ownerStripeConnected = true
                : ownerStripeConnected = false

            //console.log('payloadForStripeVerification', stripeConnected);

            dispatchSuccessWrap(actionTypes.GET_CAL_INFO_STRIPE_STATUS, {
                ownerStripeConnected: ownerStripeConnected,
                calendarOwner: calendarOwner,
                visibility: visibility,
                permission: permission

            }, dispatch)
        } else {
            dispatchSuccessWrap(actionTypes.GET_CAL_INFO_STRIPE_STATUS, {
                ownerStripeConnected: undefined,
                calendarOwner: undefined,
                visibility: undefined,
                permission: undefined

            }, dispatch)
        }

    }
}

export const updateConnectedNowStatus = () => {
    return (dispatch, getState) => {
        dispatchSuccessWrap(actionTypes.STRIPE_CONNECTED, {
            stripeConnected: true
        }, dispatch)
    }

}


export const connectToStripePlatform = (token, code, callbackAfterConnectingToStripe) => {
    return async (dispatch, getState) => {

        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'login-token': token
            }
        }
        let reqPayload = {};
        try {

            const payload = await axios.post(constants.ticketsBaseUrl + "/connectToPlatform/" + code, reqPayload, headers);

            if (!payload.data.error) {
                dispatchSuccessWrap(actionTypes.STRIPE_CONNECTED, {
                    stripeConnected: true
                }, dispatch)
                callbackAfterConnectingToStripe();

            }

        } catch (err) {
            dispatchErrorWrap(err, dispatch);
        }

    }
}

export const broadCastSellerConnect = (token, seller, broadcastData) => {
    return async (dispatch, getState) => {

        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'login-token': token
            }
        }
        let reqPayload = {
            sellerBroadCastData: broadcastData
        };
        try {

            const payload = await axios.post(constants.dashboardBaseUrl + "/broadcast/" + seller.userId, reqPayload, headers);

            if (!payload.data.error) {
                dispatchSuccessWrap(actionTypes.BROADCAST_SELLER_CONNECT, {}, dispatch)
            }

        } catch (err) {
            dispatchErrorWrap(err, dispatch);
        }

    }
}
