import { Event, PageView, initGA } from './Tracking';
import React, { Component, Fragment } from 'react'
import { getAllTicketPostings, getTicketPostings } from '../store/actions/manageTicketsActions';
import { getAllTicketsReport, getTicketsReport } from '../store/actions/ticketsReportActions';
import { googleTrackingCategory, googleTrackingLabel } from '../store/types'
import { slideInLeft, slideInRight } from 'react-animations';
import styled, { keyframes } from 'styled-components';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Chip from '@material-ui/core/Chip';
import DateCard from './ui/cards/dateCard'
import DateRangePicker from './ui/pickers/dateRangePicker'
import Edit from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterSummary from './filterSummary'
import IconButton from '@material-ui/core/IconButton';
import LineChart from '../components/ui/charts/lineChart'
import MoreReports from './moreReports';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const bounceAnimation = keyframes`${slideInLeft}`;
const fadeInRightAnime = keyframes`${slideInRight}`;

const FadeInLeftDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;
const FadeInRightDiv = styled.div`
  animation: 1s ${fadeInRightAnime};
`;
const styles = theme => ({
    fullWidth: {
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            width: '100%'
        },
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            width: '30%'
        }
    },
    flexWithWrap: {
        display: 'flex',
        flexWrap: 'wrap',

        width: '100%',
        justifyContent: 'center'
    },
    editIcon: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing.unit * 2,
        cursor: 'pointer'
    },
    container: {
        display: 'flex',
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            width: '80%'
        },
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            width: '100%'
        },

        justifyContent: 'flex-start',
        overflow: 'auto',
        alignItems: 'center',
        marginTop: theme.spacing.unit * 1
    },
    containerForDateFilter: {
        display: 'block',
        width: '50%',
        margin: 'auto'

    },
    moreDiv: {
        [
            theme
                .breakpoints
                .up('md')
        ]: {

            width: '30%'
        },
        [
            theme
                .breakpoints
                .down('md')
        ]: {

            width: '100%'
        }

    },
    fontIcon: {
        color: 'transparent',
        fontSize: theme.spacing(4),
        cursor: 'pointer'
    },
    filterIcon: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    filterIconSelected: {
        color: 'green',
        cursor: 'pointer',
        fontWeight: 800
    },
    disabledRightIcon: {
        color: 'transparent',
        fontSize: theme.spacing(4)
    },
    iconWhite: {

        color: 'white',
        fontSize: theme.spacing(4),
        cursor: 'pointer'
    },
    ticketSummaryCard: {
        padding: theme.spacing(3, 2),
        margin: theme.spacing(1, 1, 1, 1),
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -' +
            '1px rgba(0,0,0,0.12)'
    },

    ticketSummaryCardChart: {
        //backgroundImage: 'linear-gradient(to right, #6d5ccd, #786cbb)',
        background: theme.palette.primary.main,
        padding: theme.spacing(2, 2, 2, 2),
        margin: theme.spacing(1, 1),
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -' +
            '1px rgba(0,0,0,0.12)'

    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 600
    },
    headingChartDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    scrollDiv: {
        maxHeight: '80vh',
        overflowX: 'hidden',
        maxWidth: '100%',
        overflowY: 'auto'
    },
    rowChart: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '340px',
        color: 'white'

    },
    rowChartHeading: {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
        fontWeight: 600
    },
    rowChartSubHeading: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: theme.spacing(1.75),
        color: 'white'
    },
    paddingBtm: {
        paddingBottom: theme.spacing(2)
    },
    heading: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: theme.spacing(3),
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headingTrend: {
        fontSize: theme.spacing(3),
        fontWeight: 600,
        padding: theme.spacing(2, 1, 0, 1)
    },

    backBtnDiv: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },
    buttonCustom3: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }

    },
    chipsDiv: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    buttonCustom2: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        color: theme.palette.primary.main,
        background: 'white',
        border: '1px solid ' + theme.palette.primary.main
    },
    btnsDiv: {
        marginTop: theme.spacing(5)
    },
    paddingChip: {
        marginLeft: theme.spacing(1)
    },
    infoBar: {
        padding: theme.spacing(1),
        fontSize: theme.spacing(1.75),
        color: 'lightslategrey',
        display: 'flex',
        alignItems: 'center'
    },
    themeColor: {
        color: theme.palette.primary.main
    },
    icon: {
        fontSize: theme.spacing(2)
    },
    paddingRight: {
        paddingRight: theme.spacing(0.75)
    },
    salesSummary: {
        width: '100%',
        marginTop: theme.spacing(3)
    },
    spaceBtwn: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    },
    filterIcon: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    filterIconSelected: {
        color: 'green',
        cursor: 'pointer',
        fontWeight: 800
    }
});

class salesSummary extends Component {
    state = {
        leftClicked: true,
        rightClicked: false,
        openFilter: false,
        selectedPostings: {},
        isFilterSelected: false,
        chipsArr: [],
        filterOpen: false,
        startDate: moment(new Date()).subtract(30, 'd'),
        endDate: moment(new Date())
    }
    leftClickedFn = () => {
        Event(googleTrackingCategory.REPORTS_SECTION, "Left Arrow click - Chart", localStorage.getItem('userId'));

        this.setState({ leftClicked: true, rightClicked: false })
    }
    rightClickedFn = () => {
        Event(googleTrackingCategory.REPORTS_SECTION, "Right Arrow click - Chart", localStorage.getItem('userId'));

        this.setState({ rightClicked: true, leftClicked: false })
    }
    handleFilterClick = () => {
        this.setState({ filterOpen: true })
    }
    handleFilterClose = () => {
        this.setState({ filterOpen: false })
    }
    handleFilterDate = (filterDates) => {
        //console.log('filterDates', filterDates);
        this.setState({
            filterOpen: false,
            startDate: moment(filterDates.startDate),
            endDate: moment(filterDates.endDate)
        }, () => {
            this
                .props
                .getAllTicketPostings(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString(), undefined, this.callbackFromPostingsFetch);

        })

    }
    componentDidMount() {
        initGA('UA-106455531-7');
        PageView('/reports');
        this
            .props
            .getAllTicketPostings(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString(), undefined, this.callbackFromPostingsFetch);

    }
    callbackFromPostingsFetch = () => {
        ////console.log("Fetched");
        this
            .props
            .getAllTicketsReport(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString())

    }
    back = () => {
        ////console.log("Back");
        this.props.history && this
            .props
            .history
            .goBack();
    }
    more = () => {
        Event(googleTrackingCategory.REPORTS_SECTION, "More Button Clicked", localStorage.getItem('userId'));

        ////console.log("Back");
        this
            .props
            .history
            .push('/more/calendar/' + this.props.match.params.calendarId + '/event/' + this.props.match.params.eventId + '?token=' + localStorage.getItem('loginToken'))
    }
    onRefresh = () => { }
    handleDelete = (key, label) => {
        // //console.log(key)
        let postingsToFilter = [];
        var tempObj = {};
        tempObj[key] = {
            "selected": false,
            "label": label
        };

        this.setState(tempObj, () => {
            let filterSel = false;
            Object
                .keys(this.state)
                .map((keyIn, index) => {
                    if (this.state[keyIn] && this.state[keyIn].selected && key != keyIn) {
                        filterSel = true;
                        postingsToFilter.push(keyIn)
                    }
                });
            this.setState({ isFilterSelected: filterSel })

            this
                .props
                .getAllTicketsReport(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString(), postingsToFilter)
            this
                .props
                .getAllTicketPostings(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString(), postingsToFilter, undefined);

        })

    }
    onFilterClick = () => {
        //console.log("IN here")
        this.setState({
            openFilter: !this.state.openFilter
        })
    }
    handleCloseFilter = () => {
        //console.log("filter close clicked");
        this.setState({ openFilter: false })
    }

    handleChangeFilter = (val, selected, label) => {
        let postingsToFilter = [];
        //console.log(val)
        var tempObj = {};
        tempObj[val] = {
            "selected": selected,
            "label": label
        };

        this.setState(tempObj, () => {
            Object
                .keys(this.state)
                .map((key, index) => {
                    if (this.state[key] && this.state[key].selected) {
                        postingsToFilter.push(key)
                    }
                });

            this
                .props
                .getAllTicketsReport(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString(), postingsToFilter)
            this
                .props
                .getAllTicketPostings(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString(), postingsToFilter, undefined);


        })
        if (selected) {

            this.setState({ isFilterSelected: selected })

        } else {

            let filterSel = false;
            Object
                .keys(this.state)
                .map((key, index) => {
                    if (this.state[key] && this.state[key].selected) {
                        filterSel = true;
                    }
                });
            this.setState({ isFilterSelected: filterSel })
        }

        //console.log(this.state.selectedPostings);

    }
    render() {
        const { classes, weeklySales, ticketPostingsMeta, summary } = this.props;

        return (
            <div className={classes.flexWithWrap}>
                <FilterSummary
                    fullWidth={true}
                    maxWidth={'md'}
                    open={this.state.openFilter}
                    handleClose={this.handleCloseFilter}
                    ticketPostings={this.props.ticketPostings}
                    handleChange={this.handleChangeFilter}
                    passedOnStateWithSelectedPostings={this.state}></FilterSummary>

                <div className={classes.container}>

                    {(() => {
                        return !this.state.filterOpen && <span className={classes.spaceBtwn}>
                            <div className={classes.container}>
                                <DateCard
                                    text={this
                                        .state
                                        .startDate
                                        .format('MMM Do YY') + ' - ' + this
                                            .state
                                            .endDate
                                            .format('MMM Do YY')}></DateCard>
                                <Edit className={classes.editIcon} onClick={this.handleFilterClick}></Edit>

                            </div>
                            <div>
                                <IconButton
                                    onClick={this.onFilterClick}
                                    color="primary"
                                    className={classes.iconButton}
                                    aria-label="filter">
                                    <FilterListIcon
                                        className={!this.state.isFilterSelected
                                            ? classes.filterIcon
                                            : classes.filterIconSelected}></FilterListIcon>
                                </IconButton>
                            </div>
                        </span>

                    })()}
                    {(() => {
                        return this.state.filterOpen && <div className={classes.containerForDateFilter}>
                            <DateRangePicker
                                onFilterClose={this.handleFilterClose}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onFilterDate={this.handleFilterDate}></DateRangePicker>
                        </div>
                    })()}

                </div>

                <div className={classes.fullWidth}>
                    <div className={classes.chipsDiv}>{Object
                        .keys(this.state)
                        .map((key, index) => {
                            return this.state[key] && this.state[key].label && this.state[key].selected && <Chip
                                key={index}
                                className={classes.paddingChip}
                                variant="outlined"
                                size="small"
                                label={this.state[key].label}
                                color="primary"
                                onDelete={() => {
                                    this.handleDelete(key, this.state[key].label)
                                }} />
                        })}</div>
                    <div className={classes.heading}>
                        <Fragment>Summary</Fragment>

                    </div>

                    <div className={classes.scrollDiv} id="summary">
                        {/*this.props.permission === 'ADD' && <div className={classes.infoBar}>
                        <InfoIcon
                            className={classnames(classes.themeColor, classes.paddingRight, classes.icon)}></InfoIcon>Only
                        Postings you own (or) ones you created on behalf of owner are considered as you
                        have only add rights in this calendar
                    </div>*/}
                        <Paper className={classes.ticketSummaryCard}>

                            <div className={classnames(classes.row, classes.paddingBtm)}>
                                <div>Total Tickets</div>
                                <div>{ticketPostingsMeta.totalTickets}</div>
                            </div>
                            <div className={classnames(classes.row, classes.paddingBtm)}>
                                <div>Tickets Sold</div>
                                <div>{summary.totalCount}</div>
                            </div>
                            <div className={classnames(classes.row, classes.paddingBtm)}>
                                <div>Total Sales Amount</div>
                                <div>${summary.totalSales}</div>
                            </div>
                            <div className={classnames(classes.row, classes.paddingBtm)}>
                                <div>Total Stamped</div>
                                <div>{summary.totalStamped}</div>
                            </div>
                            <div className={classes.row}>
                                <div>Remaining Tickets</div>
                                {/*<div>{ticketPostingsMeta.totalRemaining}</div>  This needs to be debugged - ticket number jumping at times*/}
                                {ticketPostingsMeta.totalTickets && summary.totalCount >= 0 && <div>{ticketPostingsMeta.totalTickets - summary.totalCount}</div>}
                            </div>
                        </Paper>
                        <div className={classes.heading}>Trends</div>
                        <Paper className={classes.ticketSummaryCardChart}>
                            <div className={classes.headingChartDiv}>
                                <ArrowLeftIcon
                                    className={classnames(this.state.rightClicked && classes.disabledRightIcon, this.state.rightClicked && classes.iconWhite, classes.fontIcon)}
                                    onClick={this.leftClickedFn}></ArrowLeftIcon>
                                {this.state.leftClicked && <FadeInRightDiv>
                                    <div className={classes.rowChartHeading}>{weeklySales.meta.totalCount}
                                        Ticket{(weeklySales.meta.totalCount > 1 || weeklySales.meta.totalCount == 0)
                                            ? 's'
                                            : ''}</div>
                                    <div className={classes.rowChartSubHeading}>
                                        {moment(this.state.startDate).format('ll') + ' to ' + moment(this.state.endDate).format('ll')}
                                    </div>
                                </FadeInRightDiv>}

                                {this.state.rightClicked && <FadeInLeftDiv>
                                    <div className={classes.rowChartHeading}>${weeklySales.meta.totalSales}</div>
                                    <div className={classes.rowChartSubHeading}>
                                        {moment(this.state.startDate).format('ll') + ' to ' + moment(this.state.endDate).format('ll')}</div>
                                </FadeInLeftDiv>}
                                <ArrowRightIcon
                                    className={classnames(this.state.leftClicked && classes.disabledLeftIcon, this.state.leftClicked && classes.iconWhite, classes.fontIcon)}
                                    onClick={this.rightClickedFn}></ArrowRightIcon>
                            </div>

                            <div className={classes.rowChart}>
                                <LineChart
                                    data={weeklySales.data}
                                    dataKey={this.state.leftClicked
                                        ? 'count'
                                        : 'amount'}
                                    xAxisDataKey="name"></LineChart>

                            </div>

                        </Paper>

                    </div>

                </div>
                <div className={classes.moreDiv}>
                    <MoreReports></MoreReports>
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return { ticketsInfo: state.ticketsReport.ticketsInfo, weeklySales: state.ticketsReport.weeklySales, ticketPostingsMeta: state.ticketPostings.meta, ticketPostings: state.ticketPostings.postings, summary: state.ticketsReport.summary }
}
const mapDispatchToProps = (dispatch) => {

    return {
        getAllTicketsReport: (from, to, filterArr, callbackFn) => dispatch(getAllTicketsReport(from, to, filterArr, callbackFn)),
        getAllTicketPostings: (from, to, filter, callback) => dispatch(getAllTicketPostings(from, to, filter, callback))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(salesSummary)))