import './App.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { Component } from 'react';

import AppBar from './components/ui/layout/AppBar'
import { BrowserRouter } from 'react-router-dom';
import DrawerComponent from './components/ui/layout/DrawerComponent';
import { connect } from 'react-redux';
import { signOut } from './store/actions/authenticationActions'

const theme = createMuiTheme({
    typography: {
        fontFamily: "'Alegreya Sans', sans-serif;",
        useNextVariants: true
    },
    shadows: Array(25).fill('none'),
    palette: {
        primary: {
            main: "#0075B8",
            dark: "#0075B8",
            light: '#b6c0f2',
            default: '#000000de'
        },
        error: {
            'main': '#ff0000'
        }
    },
    overrides: {
        MUIDataTable: {
            responsiveScroll: {
                maxHeight: '600px !important'
            }
        },
        MUIDataTableBodyRow: {
            root: {
                border: 'none',
                color: 'rgba(0, 0, 0, 0.87)'
            },


        },
        MuiTableCell: {
            root: {
                padding: "14px 7px 4px 24px",
                borderBottom: 'none',
                fontSize: '16px',
                cursor: 'pointer'
            },
            head: {
                color: '#646C7B',
                textTransform: 'uppercase',
                fontWeight: '600'
            },
            body: {
                color: 'inherit'
            }

        }
    }

});

class App extends Component {
    render() {
        const { isLoginTokenValid } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <div className="App">
                    <BrowserRouter>

                        <DrawerComponent isLoginTokenValid={isLoginTokenValid} />
                    </BrowserRouter>
                </div>
            </MuiThemeProvider>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        isLoginTokenValid: state.authentication.isLoginTokenValid
    };
}
const mapDispatchToProps = (dispatch) => {

    return {
        logout: () => dispatch(signOut()),

    }

}


export default connect(mapStateToProps, mapDispatchToProps)(App);
