import React, {Component, Fragment} from 'react'

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InsertPhoto from '@material-ui/icons/PhotoLibrary';
import classnames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit
    },
    input: {
        display: 'none'
    },
    paddingIcon: {
        padding: theme.spacing(3) + 'px ' + theme.spacing(0.75) + 'px'
    },
    deleteIcon: {
        float: 'right',
        color: 'indianred'
    }
});

export class upload extends Component {
    state = {
        file: null
    }

    componentDidMount() {
        if (this.props.ticketImage) {
            this.setState({file: this.props.ticketImage.ticketImage})
        }
    }

    onChange = (event) => {

        if (this.uploadInput.files && this.uploadInput.files.length > 0) {
            let file = this.uploadInput.files[0];

            this.setState({
                file: URL.createObjectURL(file)
            }, () => {

                this
                    .props
                    .onImageUploadCallback(this.props.id, this.state.file, this.uploadInput.files[0], this.props.index);
            });
        }

    }
    removePictureFromCtx = () => {
        this.setState({
            file: null
        }, () => {
            this
                .props
                .onImageUploadCallback(this.props.id, this.state.file, null, this.props.index);
        });
    }

    render() {
        const {classes, id, ticketImage} = this.props;
        return (
            <span>
                <input
                    accept="image/*"
                    onChange={this.onChange}
                    ref={(ref) => {
                    this.uploadInput = ref;
                }}
                    className={classes.input}
                    id={id}
                    type="file"/>

                <label htmlFor={id}>
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        className={classes.paddingIcon}>
                        <InsertPhoto/>
                    </IconButton>

                </label>

                {(this.state.file) && <IconButton
                    color="primary"
                    aria-label="Discard Picture"
                    component="span"
                    onClick={this.removePictureFromCtx}
                    className={classnames(classes.paddingIcon, classes.deleteIcon)}>
                    <DeleteIcon></DeleteIcon>
                </IconButton>}

                {ticketImage && <img
                    style={{
                    width: "100%",
                    height: 'auto'
                }}
                    src={ticketImage.ticketImage}/>}
            </span>
        )
    }
}

export default withStyles(styles)(upload)
