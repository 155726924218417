import { NavLink, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MultilineChartOutlinedIcon from '@material-ui/icons/MultilineChartOutlined';
import PropTypes from 'prop-types';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    maxWidth: 360,
    backgroundColor: 'azure',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.primary.main
  },
  rootForListIcon: {
    minWidth: theme.spacing(5)
  },
  unselectedIconColor: {
    color: theme.palette.primary.default,
  },
  selectedIconColor: {
    color: theme.palette.primary.main,
    transform: 'scale(1.2)'
  },
  selectedTextColor: {
    color: theme.palette.primary.main,
    transform: 'scale(1.2)',
    paddingLeft: theme.spacing(1)
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },

});

class ShiftsNavItems extends Component {

  state = {
    selected: 0,
    open: false
  }

  updateSelected = (selectedIndex) => {
    if (selectedIndex == 2) {
      this.setState({ selected: selectedIndex, open: !this.state.open });
    } else {
      this.setState({ selected: selectedIndex });
    }

  }
  render() {
    const { classes } = this.props;
    const { selected, open } = this.state;
    //console.log(this.props);
    const navItems = [{ name: 'Create', path: 'create' }, { name: 'Manage', path: 'manageTickets' }, { name: 'Share', path: 'share' }, { name: 'Analytics', path: 'analytics' }, { name: 'Settings', path: 'settings' }];
    let selectedIndex = 0;
    if (this.props.location.pathname.indexOf('create') !== -1 && selected !== 0) {
      this.updateSelected(0);
    }
    if (this.props.location.pathname.indexOf('manageTickets') !== -1 && selected !== 1) {

      this.updateSelected(1);
    }
    if (this.props.location.pathname.indexOf('share') !== -1 && selected !== 2) {

      this.updateSelected(2);
    }

    if (this.props.location.pathname.indexOf('analytics') !== -1 && selected !== 3) {
      this.updateSelected(3);
    }
    if (this.props.location.pathname.indexOf('settings') !== -1 && selected !== 4) {

      this.updateSelected(4);
    }

    const builtNavItems = navItems.map((text, index) => {
      return (
        <ListItem component={NavLink} to={'/' + text.path} key={index} onClick={() => this.updateSelected(index)} >
          <ListItemIcon classes={{ root: classes.rootForListIcon }}>

            {index === 0 && <AddCircleOutlineIcon className={classNames(classes.unselectedIconColor, {
              [classes.selectedIconColor]: selected === index,
            })} />
            }

            {index === 1 && <CreateOutlinedIcon className={classNames(classes.unselectedIconColor, {
              [classes.selectedIconColor]: selected === index,
            })} />
            }
            {index === 2 && <ShareOutlinedIcon className={classNames(classes.unselectedIconColor, {
              [classes.selectedIconColor]: selected === index,
            })} />
            }
            {index === 3 && <MultilineChartOutlinedIcon className={classNames(classes.unselectedIconColor, {
              [classes.selectedIconColor]: selected === index,
            })} />
            }
            {index === 4 && <SettingsOutlinedIcon className={classNames(classes.unselectedIconColor, {
              [classes.selectedIconColor]: selected === index,
            })} />
            }


          </ListItemIcon>
          <ListItemText className={classNames(classes.unselectedIconColor, {
            [classes.selectedTextColor]: selected === index,
          })} primary={text.name} />
        </ListItem>

      )

    });
    return (
      <div className={classes.root}>
        <CssBaseline />
        <List component="nav" className={classes.root}>{builtNavItems}

        </List>
      </div>

    )
  }
}

ShiftsNavItems.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ShiftsNavItems));

