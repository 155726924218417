import React, { Component } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { signIn } from '../store/actions/authenticationActions';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Logo from '../assets/images/LogoText.svg'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { Link, Hidden } from '@material-ui/core';
import CopyrightIcon from '@material-ui/icons/Copyright';
import { constants } from '../App.constants';
import { Event, PageView, initGA } from './Tracking';
import { googleTrackingCategory, googleTrackingLabel } from '../store/types'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    paddingBottom: theme.spacing(8)
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    marginLeft: -theme.spacing(2.5),
    backgroundColor: theme.palette.primary.contrastText,
    width: theme.spacing.unit * 20,
    height: 'auto',
    borderRadius: 0
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  signUpDiv: {
    marginTop: theme.spacing(2)
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  error: {
    color: theme.palette.error.main
  },
  footer: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      position: 'absolute',

    },
    [theme.breakpoints.down(400 + theme.spacing.unit * 3 * 2)]: {
      paddingTop: theme.spacing(6),

    },
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    paddingBottom: theme.spacing(4)

  },
  copyRight: {
    display: 'flex',
    alignItems: 'center'
  },
  copyIcon: {
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(0.5)
  }

});

class SignIn extends Component {
  state = {
    errorExists: false,
    errorMsg: ''
  }
  handleSubmit = (e) => {

    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    let email = data.get("email");
    let password = data.get("password");
    this.props.signIn(email, password).then((success) => {
      Event(googleTrackingCategory.SIGN_IN_SECTION, "Sign In Successful")
      this.setState({
        errorExists: false,
        errorMsg: ''
      }, () => {
        this.props.history.push('/');
      })

    }, (error) => {
      Event(googleTrackingCategory.SIGN_IN_SECTION, "Invalid credentials")
      this.setState({
        errorExists: true,
        errorMsg: 'Invalid credentials'
      })
    })



  }
  componentDidMount() {
    initGA('UA-106455531-7');
    PageView('/SignIn');

    if (this.props.isLoginTokenValid) {
      this.props.history.push('/');
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />
        {this.state.errorExists && <Typography className={classes.error}>{this.state.errorMsg} </Typography>}
        <Card raised={true} className={classes.paper} variant='outlined'>
          <CardContent>
            <Avatar className={classes.avatar}>
              <img src={Logo} width="100%" />
            </Avatar>
            <Typography className={classes.header} variant="h5"> Sign in to your account</Typography>
            <form className={classes.form} onSubmit={this.handleSubmit} validate>
              <FormControl margin="normal" required fullWidth pattern="[a-zA-Z]{3,}@[a-zA-Z]{3,}[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,}">
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input id="email" name="email" autoComplete="email" autoFocus
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input name="password" type="password" id="password" />
              </FormControl>
              <Grid container justify="flex-end">
                <Grid item><Link href={constants.appBaseUrl + "/reset-password"} target="_blank" onClick={() => { Event(googleTrackingCategory.SIGN_IN_SECTION, "Forgot Password clicked") }}> Forgot password ?</Link></Grid>
              </Grid>
              {/*<FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                className={classes.submit}
              >
                Sign in
              </Button>

            </form>
          </CardContent>
        </Card>
        <Grid container className={classes.signUpDiv} justify="center">
          <Grid item> Don't have an account ? <Link href={constants.appBaseUrl + "/register"} onClick={() => { Event(googleTrackingCategory.SIGN_IN_SECTION, "New User Sign Up with allcal clicked") }} target="_blank">Sign up</Link></Grid>
        </Grid>
        <div className={classes.footer}>
          <Grid container justify="center" spacing={3}>

            <Grid item><Link href="https://allcal.com" target="_blank"><div className={classes.copyRight}><CopyrightIcon className={classes.copyIcon}></CopyrightIcon> <Typography> allcal</Typography> </div></Link></Grid>
            <Grid item><Link href="https://allcal.com/contact-us/" target="_blank"><Typography>Contact us</Typography></Link></Grid>
            <Grid item><Link href="https://allcal.com/terms/" target="_blank"><Typography>Terms</Typography></Link></Grid>
            <Grid item><Link href="https://allcal.com/privacy-policy/" target="_blank"><Typography>Privacy</Typography></Link></Grid>

          </Grid>

        </div>
      </main >
    );

  }

}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoginTokenValid: state.authentication.isLoginTokenValid
  };
}
const mapDispatchToProps = (dispatch) => {

  return {
    signIn: (email, password) => dispatch(signIn(email, password))

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(SignIn)));