import { default as apiService } from '../../../shared/api.service'

const getEventRsvpData = async (eventId, skipNumber) => {

    apiService.setHeader('login-token', localStorage.getItem('loginToken'))
    return await apiService.get(`/events/${eventId}/rsvps`, { resultsPerPage: 10, skipNumber: skipNumber, sortKey: 'name' })
}

const getRsvps = async () => {

    apiService.setHeader('login-token', localStorage.getItem('loginToken'))
    return await apiService.get('/rsvp')
}

const postMyRsvp = async (status, attendanceStatus, rsvpId) => {

    apiService.setHeader('login-token', localStorage.getItem('loginToken'))
    return await apiService.put('/rsvp', [{ status: status, attendanceStatus: attendanceStatus, rsvpId: rsvpId }])
}

const shareEvent = async (searchString, eventId, groupId, callback) => {

    apiService.setHeader('login-token', localStorage.getItem('loginToken'))
    try {
        const searchMatch = await apiService.get('/user', { searchString: searchString })
        let name = searchMatch.length > 0 ? searchMatch[0].name : searchString
        let email = searchMatch.length > 0 ? searchMatch[0].email : searchString
        const rsvpResponse = await apiService.post('/rsvp', { email: email, name: name, eventId: eventId, groupId: groupId })
        await apiService.post('/share/event', { eventId: eventId, groupId: groupId, rsvpId: rsvpResponse.data.rsvpId })

        callback(false)
        return { eventId: eventId, newUser: rsvpResponse.data }

    } catch (err) {
        callback(true)
        return
    }
}

export {
    getEventRsvpData,
    getRsvps,
    postMyRsvp,
    shareEvent
}   