import React, { Component, Fragment } from 'react';
import {difference, map} from 'lodash';

import Accepted from '@material-ui/icons/HowToRegOutlined';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Done from '@material-ui/icons/Done';
import Edit from '@material-ui/icons/Edit';
import Emailed from '@material-ui/icons/SendOutlined';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LocationOff from '@material-ui/icons/LocationOffOutlined';
import LocationOn from '@material-ui/icons/LocationOnOutlined';
import NotificationsActive from '@material-ui/icons/NotificationsActiveOutlined';
import NotificationsOff from '@material-ui/icons/NotificationsOffOutlined';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import {connect} from 'react-redux';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing.unit * 2,
    flex: 1,
  },
  responsive : {
    maxWidth : '100%',
    maxHeight : '240px',
    padding: theme.spacing.unit * 1
  },
  avatar: {
    backgroundColor: grey[100],
    color: indigo[500],
    borderRadius : '0px',
    alignItems: 'center',
    maxWidth : '100%',
    maxHeight : '240px',
    height : '300px',
    width : '240px',
    marginLeft: 'calc(50% - (233px / 2))',
    padding: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 2,
    marginTop : theme.spacing.unit * 1,
    fontSize : theme.spacing.unit * 5
  },
 
  noMargin:{
    margin: '0'
  },
  grey:{
    color: grey[500]
  },
  noMarginBottom:{
    marginBottom: 0
  },
  noMarginTop:{
    marginTop: 0
  },
  marginTopXs:{
    marginTop: '0.5em',
    textAlign : 'center'
  },
  editIcon:{
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  editIconEmail : {
    paddingLeft : theme.spacing.unit * 1
  },
  skillsGrid:{
    marginTop: theme.spacing.unit * 1,
    marginBottom : theme.spacing.unit * 1
  },
  editBtnContainer:{
    display: 'block',
    width: '100%',
    marginTop:  theme.spacing.unit * 2
  },
  accept:{
    color: 'green',
    marginRight : theme.spacing.unit * 1,
    cursor: 'pointer'
  },
  textField: {
    width:'100%'
},
  cancel:{
    color : 'red',
    cursor : 'pointer'
  },
  pointer : {
    cursot : 'pointer'
  },
  confirmButton: {
    background : theme.palette.primary.main +' !important',
  },
  noEmailBlock:{  
    display : 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconDiv:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paddingLeft: {
    paddingLeft : theme.spacing.unit * 1
  },
  fontWeight300 : {
    fontWeight : 300
  },
  editEmailBtnContainer:{
    display: 'flex',
    justifyContent : 'center',
    width : '100%'
    
  }
});

function FullWidthDialog (props) { 
    const {classes} = props;
  return (
    
    <Dialog fullScreen open={props.open} onClose={props.clearOnClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <h2> {props.heading} </h2>
         
          </Toolbar>
        </AppBar>
        <div className={classes.contentDiv}>
            {props.componentToDisplay}
        </div> 
        
      </Dialog>
  );

}
  

export default withStyles(styles)(FullWidthDialog);