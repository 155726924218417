import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { Event, PageView, initGA } from './Tracking';
import React, { Component } from 'react'
import { createNewCalendar, createNewEvent, getAllMyCalendars, getCalendarEvents } from '../store/actions/createTicketActions'
import { fadeInUp, pulse } from 'react-animations';
import { getTicketImages, uploadTicketImage } from '../store/actions/ticketsUploadActions'
import { googleTrackingCategory, googleTrackingLabel } from '../store/types'
import { postTickets, simulate } from '../store/actions/createTicketActions'
import styled, { keyframes } from 'styled-components';
import { withStyles, withTheme } from '@material-ui/core/styles';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DateFnsUtils from '@date-io/date-fns';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText';
import { Fragment } from 'react';
import Grow from '@material-ui/core/Grow';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { Link, Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import NumericInput from 'react-numeric-input';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import ProcessingScreen from './ui/processingScreen';
import { Link as RouterLink } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import UploadTickets from './uploadTickets'
import classnames from 'classnames';
import { connect } from 'react-redux';
import connectWithStripe from '../assets/images/blue-on-light.png'
import { constants } from '../App.constants';
import { getCalendarInfoAndStripeStatus } from '../store/actions/authenticationActions'
import { isMethod } from '@babel/types';
import { isThisSecond } from 'date-fns';
import moment from 'moment-timezone'
import poweredByStripe from '../assets/images/powered_by_stripe.png'
import { purple } from '@material-ui/core/colors';
import { pusher } from '../store/actions/genericActions';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

const bounceAnimation = keyframes`${fadeInUp}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;

const heartBeatAnimation = keyframes`${pulse}`;

const HeartBeatDiv = styled.div`
  animation: 1s ${heartBeatAnimation} infinite;
`;
const stylesForSwitch = (theme) => ({

    switchBase: {
        '&$checked': {
            color: theme.palette.primary.main
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.primary.main
        }
    },
    checked: {},
    track: {}

})
const PurpleSwitch = withStyles(stylesForSwitch)(Switch);

const styles = (theme, props) => ({
    root: {
        width: '100%',
        cursor: 'pointer'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(12)
    },
    heartBeatDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(3),
        flexDirection: 'column',
        paddingBottom: theme.spacing(2),
        textAlign: 'center'
    },
    animationDiv: {
        'maxWidth': '100%',
        'overflow': 'hidden'
    },
    welcome: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 600
    },
    popoverDiv: {
        maxWidth: '600px'
    },
    green: {
        color: 'green'
    },
    heading: {
        paddingLeft: theme.spacing(2),
        fontSize: theme.spacing(2),
        fontWeight: 600,
        textTransform: 'uppercase'
    },
    headingNoPadding: {
        fontSize: theme.spacing(2),
        fontWeight: 600,
        textTransform: 'uppercase'
    },
    step: {
        fontSize: theme.spacing(2),
        fontWeight: 600,
        textTransform: 'uppercase',
        color: '#21212182'
    },
    headingContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(2)
    },
    headingContainerNonFlex: {
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        color: 'gray',
        fontSize: theme.spacing(1.75)
    },
    headingContainerNoPadding: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(3)
    },
    calendarShareDiv: {
        color: '#21212182',
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(1)
    },
    spaceBtwn: {
        justifyContent: 'space-between'
    },
    mildText: {
        color: '#21212182'
    },
    marginTop8: {
        marginTop: theme.spacing(1)
    },
    paddingRight: {
        paddingRight: theme.spacing(1)
    },
    scrollDiv: {
        height: '25vh',
        overflowX: 'hidden',
        maxWidth: '100%',
        overflowY: 'auto',
        marginBottom: theme.spacing(12)
    },
    helperErrText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    subHeading: {
        padding: theme.spacing(2, 2, 0, 2),
        fontWeight: 600
    },
    inset: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    fragmentUpload: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column'

    },
    insetMid: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        backgroundColor: '#0000000f'
    },
    createBtnDiv: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2)
    },
    backBtnDiv: {

        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(2)

    },
    ticketsDiv: {
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            minWidth: '600px'
        },

        marginLeft: 'auto',
        marginRight: 'auto'
    },
    processingDiv: {

        marginLeft: 'auto',
        marginRight: 'auto'
    },
    button: {
        background: theme.palette.primary.main,
        color: 'white',
        fontSize: theme.spacing(2),
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    flexed: {
        display: 'flex'
    },

    zIndex: {
        zIndex: 100
    },
    newGroupForEase: {
        padding: theme.spacing(2)
    },
    buttonInGroup: {
        width: '100%',
        border: '1px solid $theme.palette.primary.main',
        borderRadius: 0,
        textTransform: 'capitalize',
        fontSize: theme.spacing(2),
        cursor: 'pointer'
    },
    paper: {
        position: 'relative',
        background: '#f1f1f1',
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    absoluteDiv: {
        position: 'absolute',
        top: 16,
        right: 16
    },
    buttonInGroupNoWidth: {

        borderRight: '1px solid  ' + theme.palette.primary.main + ' !important',
        borderColor: theme.palette.primary.main + ' !important',
        border: '1px solid ' + theme.palette.primary.main,
        borderRadius: 0,
        textTransform: 'capitalize',
        fontSize: theme.spacing(2),
        cursor: 'pointer',
        background: 'white',
        color: 'black',
        '&:hover': {
            background: '#EBEBEB'
        }

    },
    flexGrow1: {
        flexGrow: 1
    },

    fullWidthTextField: {
        width: '100%',
        marginTop: theme.spacing(1.5)
    },
    counter: {
        paddingLeft: '24px !important',
        paddingRight: '24px !important',
        height: theme.spacing(5),
        outline: 'none'
    },
    counterDiv: {
        marginLeft: theme.spacing(1),
        touchAction: 'manipulation',
        '& b': {
            background: theme.palette.primary.main + ' !important',
            '& i': {
                background: theme.palette.primary.contrastText + ' !important'
            }
        }
    },
    counterCustomWidth: {
        width: '138px'
    },
    bold: {
        fontWeight: 600
    },
    statusDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: theme.spacing(3),
        padding: theme.spacing(2, 2)
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    textAlignRight: {
        textAlign: 'right'
    },
    errMsg: {
        fontSize: theme.spacing(1.75),
        color: 'red',
        display: 'contents'
    },
    icon: {
        fontSize: theme.spacing(8),
        color: '#038ddd'
    },
    ticketsTotalDiv: {},
    titleDiv: {
        flexShrink: 4,
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    connectWithStripeBtnDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    clicked: {
        color: 'white',
        background: theme.palette.primary.main + ' !important'
    },

    connectWithStripeBtnDivText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: 'indianred',
        padding: theme.spacing(1)
    },
    stripeTextDiv: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    iconBase: {
        color: 'gray',
        cursor: 'pointer'
    },
    spacedBtwn: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    marginBottom: {
        marginBottom: theme.spacing(4)
    },
    disclaimer: {
        display: 'flex',
        fontSize: theme.spacing(1.75),
        textAlign: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        fontWeight: 600,
        justifyContent: 'center'
    },
    note: {
        display: 'flex',
        fontSize: theme.spacing(1.75),
        textAlign: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        fontWeight: 600,
        justifyContent: 'center',
        color: 'grey'
    },
    link: {

        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        }
    },
    newBorderColor: {

        '&::before': {
            borderBottom: '2px solid red !important'
        }
    },
    moreTicks: {
        verticalAlign: 'super',
        fontSize: theme.spacing(2),
        paddingLeft: theme.spacing(0.5)
    },
    uploadTicketsDiv: {
        fontSize: theme.spacing(1.75),
        paddingTop: theme.spacing(3),
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    linkOverrideColor: {
        color: '#038ddd'
    },
    switchColorOverride: {
        color: theme.palette.primary.main,
        '&$checked': {
            color: theme.palette.primary.main
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.primary.main
        }
    },
    checked: {},
    track: {},
    marginTopHelpLinksDiv: {
        marginTop: theme.spacing(4)
    },
    marginTopHelpTexts: {
        marginTop: theme.spacing(0.75)
    },
    marginTop16: {
        marginTop: theme.spacing(2)
    },
    marginTop24: {
        marginTop: theme.spacing(3)
    },
    subnote: {
        fontSize: theme.spacing(1.75),
        color: 'gray'
    },
    stretch: {
        display: 'flex',
        alignItems: 'stretch'
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    noteSection: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacing(1.7),
        color: 'rgba(0, 0, 0, 0.54)'
    },
    emailHelpIcon: {
        color: theme.palette.primary.main,
        fontSize: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    typography: {
        padding: theme.spacing(2),
        fontSize: theme.spacing(1.75),
        color: theme.palette.primary.main
    },
    flexEnd: {
        justifyContent: 'flex-end'
    },
    ticketCountDiv: {
        paddingTop: theme.spacing(2)
    },

    buttonBack: {
        paddingRight: theme.spacing(9),
        paddingLeft: theme.spacing(9),
        letterSpacing: '1px',
        color: theme.palette.primary.main,
        background: 'white',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        },
        border: '1px solid ' + theme.palette.primary.main
    },
    paddingRight: {
        paddingRight: theme.spacing(0.75)
    },
    flexPayout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5)
    },
    payoutText: {
        fontSize: theme.spacing(1.5),
        color: theme.palette.primary.main
    },

    dateTimeSlot: {
        display: 'flex',
        alignItems: 'baseline'
    },
    dateTimeSlotCenter: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    marginLeft16: {
        marginLeft: theme.spacing(2)
    },
    datePicker: {
        maxWidth: '80px'
    },
    timePicker: {
        maxWidth: '80px'
    },
    internalPickerDiv: {
        display: 'flex',
        justifyContent: 'flex-start'
    },

    pickerDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    pickerDivEnd: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    paddingRight16: {
        paddingRight: theme.spacing(2)
    },
    labelDT: {
        fontWeight: 600,
        textTransform: 'capitalize'
    }
});

class createTickets extends Component {
    state = {
        ticketTitle: 'Click to add Title',
        totalTickets: 10,
        maxTicketsPerPerson: 1,
        pricePerTicket: 0,
        pricePerTicketText: 'Free',
        totalTicketsClicked: true,
        maxTicketClicked: false,
        pricePerTicketClicked: false,
        valueStart: 0,
        valueEnd: 60,
        loadingCards: [],
        loading: false,
        imgLink: 'https://cdn4.iconfinder.com/data/icons/businessman-set-3/512/21-512.png',
        helpId: undefined,
        anchorEl: null,
        open: false,
        anchorRef: null,
        selectedIndex: 0,
        selectedIndexEvent: 0,
        openPopper: false,
        openPopperEvent: false,
        anchorRefEvent: null,
        options: [
            {
                calendar: {
                    calendarId: undefined,
                    name: 'Pick a calendar'
                }
            }
        ],
        eventOptions: [
            {
                name: 'Pick an event',
                eventId: undefined
            }
        ],
        helpVisibiltySection: false,
        isAllDay: true,
        isMultiDay: false,
        calendarName: 'My Tickets calendar',
        isPublic: false,
        startDate: new Date().toISOString(),
        startTime: moment(new Date().toISOString()),
        endTime: moment(new Date().toISOString()).add(60, 'm'),
        createTicketsClicked: false

    }

    componentDidMount() {
        // userId

        initGA('UA-106455531-7');
        PageView('/create');


        ////console.log(this.props.currentUser.userId);
        var channel = this.props.currentUser && this.props.currentUser.userId && pusher.subscribe(this.props.currentUser.userId);
        //const getShiftsForPusher = this.props.connectedNowFn;
        channel.bind('seller', function (data) {

            if (!this.props.stripeConnected) {
                this.setState({ connectedNow: true })
                Event(googleTrackingCategory.CREATE_TICKET_SECTION, "Socket after successful Stripe Connect", localStorage.getItem('userId'));

            }
        }, this);

        this
            .props
            .getAllMyCalendars(localStorage.getItem('loginToken'), (err, payload) => {
                if (!err) {
                    //console.log(payload);
                    this.setState({
                        options: [
                            ...this.state.options,
                            ...payload
                        ]
                    })
                }

            })
    }

    back = () => {
        ////console.log("Back");
        this.props.history && this
            .props
            .history
            .goBack();
    }

    openUpload = () => {
        this.setState({ open: true })
        Event(googleTrackingCategory.CREATE_TICKET_SECTION, "Upload your own tickets clicked", localStorage.getItem('userId'));

    }
    handleUploadClose = () => {
        this.setState({ open: false })

        //console.log('Ticket Images');
        //console.log(this.props.ticketImages);

    }
    createTickets = () => {
        // //console.log("Clicked"); //console.log(this.state);
        Event(googleTrackingCategory.CREATE_TICKET_SECTION, "Create Ticket Clicked", localStorage.getItem('userId'));

        if (!this.state.createTicketsClicked) {
            this.setState({ createTicketsClicked: true })
        }
        if (this.state.eventName == undefined) {
            this.setState({ eventName: '' })

        }

        (this.state.ticketTitle.trim() === '' || this.state.ticketTitle === 'Click to add Title')
            ? this.setState({
                titleMandErr: true
            }, this.callbackAfterMandCheck)
            : this.setState({
                titleMandErr: false
            }, this.callbackAfterMandCheck)

        //;
    }

    callbackAfterMandCheck = () => {
        ////console.log("After Mand Check"); //console.log(this.state.titleMandErr);
        if (this.state.titleMandErr) {
            Event(googleTrackingCategory.CREATE_TICKET_SECTION, "Title Not Entered Err", localStorage.getItem('userId'));

            return
        }

        if (!this.state.inPickedEvent && this.state.eventName == '' || this.state.eventName.trim() == undefined) {
            return;
        }

        if (!this.state.inPickedCalendar && this.state.calendarName && this.state.calendarName.trim() == '') {
            return;
        }

        if (!this.state.isAllDay && (this.state.errEndTime || this.state.errStartTime || this.state.errStartDateTime || this.state.errEndDateTime)) {
            return;
        }

        this.setState({ loading: true });
        //console.log(this.state);

        if (this.state.inPickedCalendar && this.state.pickedCalendar) {
            // Proceed with creating event
            this.callbackAfterCalendarCreation(null, { calendarId: this.state.pickedCalendar })
        } else {
            // create calendar
            this
                .props
                .createNewCalendar(localStorage.getItem('loginToken'), this.state.calendarName, this.state.isPublic
                    ? 'public'
                    : 'private', this.callbackAfterCalendarCreation)
        }

        /* if (this.state.proxy) {
            this
                .props
                .postTickets(localStorage.getItem('loginToken'), type, this.props.match.params.calendarId, '', this.props.match.params.eventId, false, this.state.maxTicketsPerPerson, this.state.totalTickets, this.state.pricePerTicket, this.state.ticketTitle, this.callbackFn, this.props.calendarOwner);
        } else {


            this
                .props
                .postTickets(localStorage.getItem('loginToken'), type, this.props.match.params.calendarId, '', this.props.match.params.eventId, false, this.state.maxTicketsPerPerson, this.state.totalTickets, this.state.pricePerTicket, this.state.ticketTitle, this.callbackFn);
        } */

    }
    callbackAfterCalendarCreation = (err, payload) => {
        if (!err) {

            if (this.state.inPickedEvent && this.state.pickedEvent) {
                this.callbackAfterEventCreation(null, {
                    'originatingCalendar': {
                        'calendarId': payload.calendarId
                    },
                    'eventId': this.state.pickedEvent
                })
            } else {

                //console.log(payload.calendarId)

                var eventCreationObj = {};
                eventCreationObj.calendarId = payload.calendarId;
                eventCreationObj.details = "";

                if (!this.state.isAllDay) {
                    eventCreationObj.startDate = new Date(moment(this.state.startDate).format('YYYY/MM/DD') + ' ' + moment(this.state.startTime).format('LT')).toISOString();
                    eventCreationObj.endDate = new Date(moment(this.state.endDate).format('YYYY/MM/DD') + ' ' + moment(this.state.endTime).format('LT')).toISOString();
                } else {
                    eventCreationObj.startDate = moment(this.state.startDate).format('YYYY-MM-DD') + 'T00:00:00.000Z';
                    eventCreationObj.endDate = moment(this.state.startDate).format('YYYY-MM-DD') + 'T23:59:00.000Z';
                }

                eventCreationObj.extendedProperties = {};
                eventCreationObj.extendedProperties.shouldHideDates = false;
                eventCreationObj.extendedProperties.shouldHideLocation = false;
                eventCreationObj.isAllDay = this.state.isAllDay;
                eventCreationObj.isMultiDay = this.state.isMultiDay;
                eventCreationObj.kind = "classic";
                if (this.state.eventLocation && this.state.eventLocation.trim() != '') {
                    eventCreationObj.location = {};
                    eventCreationObj.location.radius = 50;
                    eventCreationObj.location.name = this.state.eventLocation;
                    eventCreationObj.location.autoAttendance = false;
                }

                eventCreationObj.name = this.state.eventName;
                eventCreationObj.startTimeZone = moment
                    .tz
                    .guess();
                eventCreationObj.endTimeZone = moment
                    .tz
                    .guess();
                //console.log(eventCreationObj);
                this
                    .props
                    .createNewEvent(localStorage.getItem('loginToken'), eventCreationObj, this.callbackAfterEventCreation);
            }
        }
    }
    callbackAfterEventCreation = (err, payload) => {
        if (!err) {
            //console.log(payload);
            let type = this.state.pricePerTicket > 0
                ? 'paid'
                : 'free';

            if (this.state.proxy) {
                this
                    .props
                    .postTickets(localStorage.getItem('loginToken'), type, payload.originatingCalendar.calendarId, '', payload.eventId, false, this.state.maxTicketsPerPerson, this.state.totalTickets, this.state.pricePerTicket, this.state.ticketTitle, this.callbackFn, this.props.calendarOwner);
            } else {

                this
                    .props
                    .postTickets(localStorage.getItem('loginToken'), type, payload.originatingCalendar.calendarId, '', payload.eventId, false, this.state.maxTicketsPerPerson, this.state.totalTickets, this.state.pricePerTicket, this.state.ticketTitle, this.callbackFn);
            }
            //this.callbackFn({posting: 'abc'});
        }
    }
    callbackFn = (payload) => {
        //console.log(payload);
        this.setState({
            loadingCards: [
                {
                    'text': 'Issuing Validators'
                }
            ]
        })

        if (payload.posting) {
            if (this.props.ticketImages && this.props.ticketImages.length > 0) {

                let counter = 0;
                this
                    .props
                    .ticketImages
                    .forEach((img) => {

                        if (img.ticketImage) {

                            if (counter == 1) {
                                this.setState({
                                    loadingCards: [
                                        ...this.state.loadingCards, {
                                            'text': 'Uploading Ticket Images'
                                        }
                                    ],
                                    imgLink: img.ticketImage
                                }, () => {
                                    this
                                        .props
                                        .uploadTicketImage(img.rawFile, localStorage.getItem('loginToken'), payload.calendar, payload.event, payload.posting, img.ticketNumber, img.ticketTitle, img.moreInfo, () => {
                                            counter++;
                                            this
                                                .props
                                                .simulate(2000, payload.posting, this.sim1);
                                            Event(googleTrackingCategory.CREATE_TICKET_SECTION, "Ticket Creation with one Image Succeeded", localStorage.getItem('userId'));
                                        });

                                })
                            } else {
                                this.setState({
                                    imgLink: img.ticketImage
                                }, () => {
                                    this
                                        .props
                                        .uploadTicketImage(img.rawFile, localStorage.getItem('loginToken'), payload.calendar, payload.event, payload.posting, img.ticketNumber, img.ticketTitle, img.moreInfo, () => {
                                            counter++;
                                            if (counter == this.props.ticketImages.length) {
                                                this
                                                    .props
                                                    .simulate(2000, payload.posting, this.sim1);
                                                Event(googleTrackingCategory.CREATE_TICKET_SECTION, "Ticket Creation with " + this.props.ticketImages.length + " Images Succeeded", localStorage.getItem('userId'));
                                            }
                                            //console.log(counter);
                                        });

                                })
                            }

                        }
                    })

            } else {
                this
                    .props
                    .simulate(2000, payload.posting, this.sim1);
                Event(googleTrackingCategory.CREATE_TICKET_SECTION, "Ticket Creation Succeeded", localStorage.getItem('userId'));

            }

        } else {
            this.setState({

                loadingCards: [
                    ...this.state.loadingCards, {
                        'text': 'Error issuing tickets, please reach out to us using allcal help chat thread'
                    }
                ]
            })
        }
        // payload && payload.posting && this.processing(payload.posting);
        // this.props.history.push('/calendar/'+this.props.match.params.calendarId+'/eve
        // n
        // t/'+this.props.match.params.eventId+'/posting/'++'/success?token='+queryStrin
        // g .parse(this.props.location.search).token);

    }

    formatAbs = (num) => {
        ////console.log(num)
        return num
            ? (num <= 0
                ? (num === 0
                    ? 1
                    : Math.abs(num))
                : num)
            : 1
    }
    formatAmount = (num) => {
        ////console.log("Format called" + num);
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formatter.format(Math.abs(num));
        //return  '$' + Math.abs(num)   ;
    }
    parseFn = (num) => {
        //////console.log(num + "Parse" + num.replace(/^\$/, "").replace(/\,/g,""))
        return num
            .replace(/^\$/, "")
            .replace(/\,/g, "");
    }
    changeCounter = (valueAsNumber, valueAsString, element) => {
        //////console.log(element.name);
        let obj = {};
        element.name === 'pricePerTicket'
            ? (obj[element.name] = valueAsNumber
                ? valueAsNumber
                : 0)
            : (obj[element.name] = valueAsNumber
                ? valueAsNumber
                : 1);
        if (element.name === 'totalTickets') {
            obj['maxTicketsPerPerson'] = (valueAsNumber <= this.state.maxTicketsPerPerson)
                ? valueAsNumber
                : this.state.maxTicketsPerPerson;

            if (valueAsNumber < this.state.totalTickets) {
                this
                    .props
                    .getTicketImages(valueAsNumber);
            }

        }

        if (element.name === 'maxTicketsPerPerson') {
            obj['maxTicketsPerPerson'] = (valueAsNumber <= this.state.totalTickets)
                ? valueAsNumber
                : this.state.totalTickets;
        }
        if (element.name == 'pricePerTicket') {
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });

            obj['pricePerTicketText'] = formatter.format(Math.abs(valueAsNumber))

        }
        this.setState(obj);

    }
    ticketTitleChanged = (e) => {
        ////console.log(e.target.value);
        this.setState({
            ticketTitle: e.target.value,
            titleMandErr: e.target.value === ''
                ? true
                : false
        })
    }
    totalTicketsEdit = () => {
        ////console.log("In here");
        this.setState({ ticketTitleClicked: false, totalTicketsClicked: true, maxTicketClicked: false, pricePerTicketClicked: false })
    }
    maxTicketsPerPersonEdit = () => {
        this.setState({ ticketTitleClicked: false, totalTicketsClicked: false, maxTicketClicked: true, pricePerTicketClicked: false })
    }
    processing = (postingId) => {

        /*await new Promise ((resolve,reject)=>{
                setTimeout(()=>{

                    setTimeout(()=>{

                        resolve();
                    },2000)
                },2000)
            }
        ) */

        //this.redirectAfterSuccess(postingId);

    }
    sim1 = (postingId) => {
        this.setState({
            valueStart: 60,
            valueEnd: 100,
            loadingCards: [
                ...this.state.loadingCards, {
                    'text': 'QR Codes Management Initialized'
                }
            ],
            imgLink: 'https://cdn1.iconfinder.com/data/icons/qr-code-1/776/barcode_scan_mobile_hand-51' +
                '2.png'
        })
        this
            .props
            .simulate(2000, postingId, this.sim2);
    }

    sim2 = (postingId) => {

        this.setState({
            loadingCards: [
                ...this.state.loadingCards, {
                    'text': 'Shareable Ticket Links Generated'
                }
            ],
            imgLink: 'https://cdn0.iconfinder.com/data/icons/amusement-park-v2/277/amusement-park-fair' +
                'ground-ride-fun_07-512.png'

        })

        this
            .props
            .simulate(1000, postingId, this.sim3)

    }
    sim3 = (postingId) => {
        this.setState({
            loadingCards: [
                ...this.state.loadingCards, {
                    'text': 'Tickets Issued Successfully',
                    'color': 'green'
                }
            ]
        })
        this
            .props
            .simulate(1000, postingId, this.sim4)
    }

    sim4 = (postingId) => {

        this
            .props
            .history
            .push('/success/' + postingId);

    }

    blur = () => {

        this.setState({ ticketTitleClicked: false, totalTicketsClicked: false, maxTicketClicked: false, pricePerTicketClicked: false })
    }
    pricePerTicketEdit = () => {
        this.setState({ ticketTitleClicked: false, totalTicketsClicked: false, maxTicketClicked: false, pricePerTicketClicked: true })
    }
    ticketTitleEdit = () => {
        this.setState({ ticketTitleClicked: true, totalTicketsClicked: false, maxTicketClicked: false, pricePerTicketClicked: false })
    }
    handleProxyChange = () => {
        ////console.log("Back");
        this.setState({
            proxy: !this.state.proxy
        })
    }
    handleClick = event => {
        this.setState({ helpId: 'simple-popover', anchorEl: event.currentTarget })

    };
    handleClose = () => {
        this.setState({ helpId: undefined, anchorEl: null })
    };

    handleMenuItemClick = (event, index) => {
        if (this.state.options[index].calendar.calendarId == undefined) {
            this.setState({
                openPopper: false,
                selectedIndex: index,
                pickedCalendar: undefined,
                inPickedCalendar: false,
                inPickedEvent: false,
                pickedEvent: undefined

            })
        } else {
            this.setState({
                openPopper: false,
                selectedIndex: index,
                pickedCalendar: this.state.options[index].calendar.calendarId,
                inPickedCalendar: true,
                inPickedEvent: false,
                pickedEvent: undefined

            })

            //console.log(this.state.options[index].calendar.calendarId)
            this
                .props
                .getCalendarEvents(localStorage.getItem('loginToken'), this.state.options[index].calendar.calendarId, (err, payload) => {
                    //console.log(payload.items);
                    this.setState({
                        eventOptions: [
                            {
                                name: 'Pick an event',
                                eventId: undefined
                            },
                            ...payload.items
                        ],
                        selectedIndexEvent: 0
                    })
                })
        }
        this
            .props
            .getCalendarInfoAndStripeStatus(localStorage.getItem('loginToken'), this.state.options[index].calendar.calendarId)

    };
    handleMenuItemClickEvent = (event, index) => {
        if (this.state.eventOptions[index].eventId == undefined) {
            this.setState({ openPopperEvent: false, selectedIndexEvent: index, inPickedEvent: false, pickedEvent: undefined })
        } else {
            this.setState({ openPopperEvent: false, selectedIndexEvent: index, inPickedEvent: true, pickedEvent: this.state.eventOptions[index].eventId })
        }

    };
    handleClickBtnGroup = (event) => {

        if (this.state.options.length > 1) {
            this.setState({
                anchorRef: event.currentTarget
            }, () => {
                //console.log(this.state.anchorRef)
            })
        } else {
            this.setState({

                openPopper: false
            }, () => {
                //console.log(this.state.anchorRef)
            })
        }

    }
    handleClickBtnGroupEvent = (event) => {

        if (this.state.options.length > 0) {
            this.setState({
                anchorRefEvent: event.currentTarget
            }, () => {
                //console.log(this.state.anchorRefEvent)
            })
        } else {
            this.setState({
                openPopperEvent: false
            }, () => {
                //console.log(this.state.anchorRefEvent)
            })
        }

    }

    handleToggle = () => {
        if (this.state.options.length == 1) {
            this.setState({
                inPickedCalendar: !this.state.inPickedCalendar
            })
        } else {
            this.setState({
                openPopper: !this.state.openPopper
            })
        }

    };
    handleToggleEvent = () => {
        this.setState({
            openPopperEvent: !this.state.openPopperEvent
        })
    };

    buttonGroupClick = (bool) => {
        // clicked new calendar
        //console.log(bool);
        this.setState({ inPickedCalendar: bool, inPickedEvent: false, selectedIndex: 0, pickedCalendar: undefined })
        this
            .props
            .getCalendarInfoAndStripeStatus(localStorage.getItem('loginToken'), undefined)

    }

    buttonGroupClickEvent = (bool) => {

        // clicked new event
        //console.log(bool);
        this.setState({ inPickedEvent: bool, inPickedEvent: false, selectedIndexEvent: 0 })

    }

    buttonGroupClickPriPub = (bool) => {
        //console.log(bool);
        this.setState({ isPublic: bool })
    }

    handleStartDateChange = (date) => {
        this.setState({ startDate: date })
        if (!this.state.isAllDay) {
            if (moment(this.state.endDate).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
                this.setState({ isMultiDay: false })

            } else {
                this.setState({
                    isMultiDay: true
                }, () => {
                    if (moment(this.state.endDate).isSameOrBefore(moment(date))) {
                        // Defn end date is before start date as same usecase is handled
                        this.setState({ errStartDateTime: true })
                    } else {
                        this.setState({ errStartDateTime: false })
                    }
                })
            }
        }

    }
    handleEndDateChange = (date) => {
        this.setState({ endDate: date })
        if (moment(this.state.startDate).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
            this.setState({ isMultiDay: false })

        } else {
            this.setState({
                isMultiDay: true
            }, () => {
                if (moment(this.state.startDate).isSameOrAfter(moment(date))) {
                    // Defn start date is after end date as same usecase is handled
                    this.setState({ errEndDateTime: true })
                } else {
                    this.setState({ errEndDateTime: false })
                }
            })
        }

    }
    handleTimeChange = (time, id) => {

        this.setState({ [id]: time })
        if (id == 'endTime') {
            if (moment(this.state.startTime).isSameOrAfter(moment(time))) {
                this.setState({ errEndTime: true })
            } else {
                this.setState({ errEndTime: false, errStartTime: false })
            }

        }
        if (id == 'startTime') {
            if (moment(this.state.endTime).isSameOrBefore(moment(time))) {
                this.setState({ errStartTime: true })
            } else {
                this.setState({ errStartTime: false, errEndTime: false })
            }

        }

    }
    switchHelpVisibilty = (bool) => {
        this.setState({ helpVisibiltySection: bool })
    }
    handleTextFieldChange = (e) => {
        //console.log(e.target.id);
        //console.log(e.target.value);
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    toggleAllDay = (e) => {
        this.setState({
            isAllDay: e.target.checked
        }, () => {
            //console.log(this.state.isAllDay);
        })
    }

    handleCloseBtnGroup = event => {
        if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({ openPopper: false })
    };

    handleCloseBtnGroupEvent = event => {
        if (this.state.anchorRefEvent.current && this.state.anchorRefEvent.current.contains(event.target)) {
            return;
        }

        this.setState({ openPopperEvent: false })
    };

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                {this.state.loading && <div className={classes.processingDiv}>
                    {(() => {

                        return this.state.loading && <ProcessingScreen
                            valueStart={this.state.valueStart}
                            valueEnd={this.state.valueEnd}
                            imgLink={this.state.imgLink}></ProcessingScreen>

                    })()}

                    {(() => {

                        return this.state.loading && this.state.loadingCards.length > 0 && this
                            .state
                            .loadingCards
                            .map((card, index) => (
                                <div key={index} className={classes.statusDiv}>
                                    <BouncyDiv>
                                        <div>
                                            <span
                                                style={{
                                                    color: card.color
                                                        ? card.color
                                                        : ''
                                                }}>{card.text}</span>
                                        </div>
                                    </BouncyDiv>
                                </div>

                            ))

                    })()
                    }

                </div>}

                {!this.state.loading && <div className={classnames(classes.marginTop8, classes.ticketsDiv)}>
                    <div className={classes.headingContainer} onClick={this.blur}>

                        <div className={classes.heading}>
                            Pick a calendar
                        </div>
                    </div>

                    <div className={classes.newGroupForEase} onClick={this.blur}>
                        <ButtonGroup
                            size="small"
                            className={classes.flexed}
                            aria-label="small outlined button group">
                            <div>
                                <ButtonGroup
                                    variant="contained"
                                    color="primary"
                                    className={classes.allowedWidth}
                                    onClick={(e) => {
                                        this.handleClickBtnGroup(e)
                                    }}
                                    aria-label="split button">
                                    <Button
                                        onClick={this.handleToggle}
                                        className={classnames(classes.buttonInGroupNoWidth, this.state.inPickedCalendar
                                            ? classes.clicked
                                            : '')}>{this.state.options && this.state.options.length > 0
                                                ? this.state.options[this.state.selectedIndex].calendar.name
                                                : 'smething'}</Button>
                                    {this.state.options && this.state.options.length > 1 && <Button
                                        className={classnames(classes.buttonInGroupNoWidth, this.state.inPickedCalendar
                                            ? classes.clicked
                                            : '')}
                                        size="small"
                                        onClick={this.handleToggle}>
                                        <ArrowDropDownIcon />
                                    </Button>}
                                </ButtonGroup>
                                <Popper
                                    className={classes.zIndex}
                                    open={this.state.openPopper}
                                    anchorEl={this.state.anchorRef}
                                    role={undefined}
                                    transition
                                    disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin: placement === 'bottom'
                                                    ? 'center top'
                                                    : 'center bottom'
                                            }}>
                                            <Paper>
                                                <ClickAwayListener onClickAway={this.handleCloseBtnGroup}>

                                                    <MenuList id="split-button-menu">

                                                        {this.state.options && this.state.options.length > 0 && this
                                                            .state
                                                            .options
                                                            .map((option, index) => (
                                                                <MenuItem
                                                                    key={option.calendarId
                                                                        ? option.calendarId
                                                                        : 'index-1'}
                                                                    selected={index === this.state.selectedIndex}
                                                                    onClick={event => this.handleMenuItemClick(event, index)}>
                                                                    {option.calendar.name}
                                                                </MenuItem>
                                                            ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>

                            {/* <Button className= {classnames(classes.buttonInGroup, this.state.inPickedCalendar ? classes.clicked : '')} onClick={()=>{this.buttonGroupClick(true)}}>Personal Calendar</Button>  */}
                            <Button
                                className={classnames(classes.flexGrow1, classes.buttonInGroupNoWidth, !this.state.inPickedCalendar
                                    ? classes.clicked
                                    : '')}
                                onClick={() => {
                                    this.buttonGroupClick(false)
                                }}>Create New Calendar</Button>

                        </ButtonGroup>

                        {!this.state.inPickedCalendar && <TextField
                            className={classes.fullWidthTextField}
                            error={this.state.calendarName != undefined && this
                                .state
                                .calendarName
                                .trim() === ""}
                            helperText=
                            {this.state.calendarName.trim() === '' ? 'Please enter calendar name to proceed' : ''}
                            label="Calendar Name"
                            id="calendarName"
                            onChange={this.handleTextFieldChange}
                            value={this.state.calendarName}
                        />}

                        {!this.state.inPickedCalendar && <Fragment>
                            <div
                                className={classnames(classes.calendarShareDiv, classes.spaceBtwn)}
                                onClick={this.blur}>
                                <div>
                                    Calendar Share Settings
                                </div>
                                {!this.state.helpVisibiltySection && <div>
                                    <HelpIcon
                                        className={classes.iconBase}
                                        onClick={() => {
                                            this.switchHelpVisibilty(true)
                                        }}></HelpIcon>
                                </div>}

                            </div>

                            <ButtonGroup
                                size="small"
                                className={classnames(classes.flexed, classes.marginTop16)}
                                aria-label="small outlined button group">
                                <Button
                                    className={classnames(classes.buttonInGroup, this.state.isPublic
                                        ? classes.clicked
                                        : '')}
                                    onClick={() => {
                                        this.buttonGroupClickPriPub(true)
                                    }}>Public</Button>
                                <Button
                                    className={classnames(classes.buttonInGroup, !this.state.isPublic
                                        ? classes.clicked
                                        : '')}
                                    onClick={() => {
                                        this.buttonGroupClickPriPub(false)
                                    }}>Private</Button>

                            </ButtonGroup>
                            {this.state.helpVisibiltySection && <div className={classes.paper}>
                                <div className={classes.absoluteDiv}>
                                    <CancelIcon
                                        className={classes.iconBase}
                                        onClick=
                                        {() => { this.switchHelpVisibilty(false) }}></CancelIcon>
                                </div>
                                <p>Public - Calendar is visible to everyone on allcal platform</p>
                                <p>Private - Calendar is visible to only those invited</p>
                                <p>You can change the settings later as well</p>
                                <p>All Events created either private or public are accessible with a shareable link</p>
                            </div>}

                        </Fragment>
                        }

                        <div className={classes.headingContainer} onClick={this.blur}>

                            {(!this.state.inPickedCalendar || this.state.eventOptions.length == 1)
                                ? <div className={classes.headingNoPadding}>
                                    Create an event
                                    </div>
                                : <div className={classes.headingNoPadding}>
                                    Pick or Create an event
                                </div>
                            }

                        </div>

                        {(this.state.inPickedCalendar && this.state.eventOptions.length > 1) && <div className={classes.marginTop16}>

                            <ButtonGroup
                                size="small"
                                className={classes.flexed}
                                aria-label="small outlined button group">
                                <div>
                                    <ButtonGroup
                                        variant="contained"
                                        color="primary"
                                        className={classes.allowedWidth}
                                        onClick={(e) => {
                                            this.handleClickBtnGroupEvent(e)
                                        }}
                                        aria-label="split button">
                                        <Button
                                            onClick={this.handleToggleEvent}
                                            className={classnames(classes.buttonInGroupNoWidth, this.state.inPickedEvent
                                                ? classes.clicked
                                                : '')}>{this.state.eventOptions[this.state.selectedIndexEvent].name}</Button>
                                        {this.state.eventOptions && this.state.eventOptions.length > 1 && <Button
                                            className={classnames(classes.buttonInGroupNoWidth, this.state.inPickedEvent
                                                ? classes.clicked
                                                : '')}
                                            size="small"
                                            onClick={this.handleToggleEvent}>
                                            <ArrowDropDownIcon />
                                        </Button>}
                                    </ButtonGroup>
                                    <Popper
                                        className={classes.zIndex}
                                        open={this.state.openPopperEvent}
                                        anchorEl={this.state.anchorRefEvent}
                                        role={undefined}
                                        transition
                                        disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin: placement === 'bottom'
                                                        ? 'center top'
                                                        : 'center bottom'
                                                }}>
                                                <Paper>
                                                    <ClickAwayListener onClickAway={this.handleCloseBtnGroupEvent}>
                                                        <MenuList id="split-button-menu">
                                                            {this
                                                                .state
                                                                .eventOptions
                                                                .map((option, index) => (
                                                                    <MenuItem
                                                                        key={option.eventId
                                                                            ? option.eventId
                                                                            : 'item-index0'}
                                                                        selected={index === this.state.selectedIndexEvent}
                                                                        onClick={event => this.handleMenuItemClickEvent(event, index)}>
                                                                        {option.name}
                                                                    </MenuItem>
                                                                ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>

                                {/* <Button className= {classnames(classes.buttonInGroup, this.state.inPickedCalendar ? classes.clicked : '')} onClick={()=>{this.buttonGroupClick(true)}}>Personal Calendar</Button>  */}
                                <Button
                                    className={classnames(classes.flexGrow1, classes.buttonInGroupNoWidth, !this.state.inPickedEvent
                                        ? classes.clicked
                                        : '')}
                                    onClick={() => {
                                        this.buttonGroupClickEvent(false)
                                    }}>Create New Event</Button>

                            </ButtonGroup>

                        </div>
                        }
                        {!this.state.inPickedEvent && <Fragment>
                            <TextField
                                InputLabelProps={{
                                    shrink: true
                                }}
                                className={classnames(classes.fullWidthTextField, classes.marginTop16)}
                                id="eventName"
                                onChange={this.handleTextFieldChange}
                                error={this.state.eventName != undefined && this
                                    .state
                                    .eventName
                                    .trim() === ""}
                                helperText=
                                {this.state.createTicketsClicked && (this.state.eventName === undefined || this.state.eventName.trim() === "") ? 'Please enter event name' : ''}
                                placeholder="Enter Event Name" />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className={classes.pickerDiv}>
                                    <div className={classes.internalPickerDiv}>

                                        <div className={classes.dateTimeSlot}>
                                            <div className={classnames(classes.paddingRight16, classes.labelDT)}>{this.state.isAllDay
                                                ? 'Date'
                                                : 'Start Date'}</div>
                                            <DatePicker
                                                className={classes.datePicker}
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                value={this.state.startDate}
                                                onChange={this.handleStartDateChange} />
                                        </div>
                                        {!this.state.isAllDay && <div className={classnames(classes.dateTimeSlot, classes.marginLeft16)}>
                                            <TimePicker
                                                margin="normal"
                                                id="startTime"
                                                value={this.state.startTime}
                                                className={classes.timePicker}
                                                onChange={(time) => {
                                                    this.handleTimeChange(time, 'startTime')
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time'
                                                }} />
                                        </div>}

                                    </div>
                                    <div className={classes.dateTimeSlotCenter}>
                                        <FormControlLabel
                                            value="All Day"
                                            control={< PurpleSwitch color="primary" checked={
                                                this.state.isAllDay
                                            }
                                                onChange={
                                                    this.toggleAllDay
                                                } />}
                                            label="All Day"
                                            labelPlacement="end" />

                                    </div>
                                </div>
                                {!this.state.isAllDay && <div className={classes.pickerDivEnd}>
                                    <div className={classes.dateTimeSlot}>
                                        <div className={classnames(classes.paddingRight16, classes.labelDT)}>End Date</div>
                                        <DatePicker
                                            className={classes.datePicker}
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yy"
                                            margin="normal"
                                            id="date-picker-inline-1"
                                            value={this.state.endDate}
                                            onChange={this.handleEndDateChange} />
                                    </div>
                                    <div className={classnames(classes.dateTimeSlot, classes.marginLeft16)}>
                                        <TimePicker
                                            margin="normal"
                                            id="endTime"
                                            className={classes.timePicker}
                                            value={this.state.endTime}
                                            onChange={(time) => {
                                                this.handleTimeChange(time, 'endTime')
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time'
                                            }} />
                                    </div>
                                </div>}
                                {!this.state.isMultiDay && <Fragment>
                                    {!this.state.isAllDay && this.state.errStartTime && <FormHelperText error={true}>Start Time cannot be later than end time</FormHelperText>
                                    }
                                    {!this.state.isAllDay && this.state.errEndTime && <FormHelperText error={true}>End Time cannot be earlier than start time</FormHelperText>
                                    }
                                </Fragment>}
                                {this.state.isMultiDay && <Fragment>
                                    {!this.state.isAllDay && this.state.errStartDateTime && <FormHelperText error={true}>Start Date Time cannot be later than end Date Time</FormHelperText>
                                    }
                                    {!this.state.isAllDay && this.state.errEndDateTime && <FormHelperText error={true}>End Date Time cannot be earlier than start Date time</FormHelperText>
                                    }
                                </Fragment>}
                            </MuiPickersUtilsProvider>

                            <TextField
                                InputLabelProps={{
                                    shrink: true
                                }}
                                className={classnames(classes.fullWidthTextField, classes.marginTop16)}
                                id="eventLocation"
                                onChange={this.handleTextFieldChange}
                                placeholder="Enter Event Location" />
                        </Fragment>
                        }

                    </div>

                    <div className={classes.headingContainer} onClick={this.blur}>

                        <div className={classes.heading}>
                            Create Tickets
                        </div>
                    </div>

                    {(this.props.permission === 'EDIT' || this.props.permission == 'ADD') && <div className={classes.noteSection}>
                        <LockOpenIcon className={classnames(classes.green, classes.paddingRight)}></LockOpenIcon>
                        You have {this.props.permission === 'EDIT' ? 'edit ' : 'add '} rights in this calendar
                        <HelpOutline className={classes.emailHelpIcon} onClick={this.handleClick}></HelpOutline>
                        <Grid container item xs={12} sm={6}>
                            <Popover
                                id={this.state.helpId}
                                open={Boolean(this.state.anchorEl)}
                                anchorEl={this.state.anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}>
                                {this.props.permission === 'EDIT' && <Typography className={classnames(classes.typography, classes.popoverDiv)}>
                                    You can create and manage all tickets created on this calendar since you have
                                    edit rights. Who owns the ticket & Payout (if any) will be determined based on
                                the option you choose in Ownership / Payout Section while creating a ticket.</Typography>}

                                {this.props.permission === 'ADD' && <Typography className={classnames(classes.typography, classes.popoverDiv)}>
                                    You can create tickets you own or create tickets on behalf of the calendar
                                    owner. Who owns the ticket & Payout (if any) will be determined based on the
                                option you choose in Ownership / Payout Section below.</Typography>}

                            </Popover>


                        </Grid>


                    </div>}
                    <List className={classes.root}>
                        <ListItem className={classes.stretch}>
                            <ListItemText
                                primary="Ticket Title"
                                className={classes.paddingRight}
                                onClick={this.ticketTitleEdit}
                                secondary="Name of the tickets people will see" /> {this.state.ticketTitleClicked && <TextField
                                    name="ticketTitle"
                                    className={classnames(classes.textField, classes.alignCenter)}
                                    autoFocus={true}
                                    value={this.state.ticketTitle === 'Click to add Title'
                                        ? ''
                                        : this.state.ticketTitle}
                                    onChange={this.ticketTitleChanged}
                                    margin="normal"
                                    inputProps={{
                                        'aria-label': 'bare'
                                    }} />}
                            {!this.state.ticketTitleClicked && <div
                                className={classnames(classes.bold, classes.textAlignRight, classes.titleDiv, classes.alignCenter)}
                                onClick={this.ticketTitleEdit}>{this.state.ticketTitle}
                                <br></br>{this.state.titleMandErr && <div
                                    className={classnames(classes.bold, classes.textAlignCenter, classes.errMsg)}
                                    onClick={this.totalTicketsEdit}>Please enter title</div>}</div>}
                        </ListItem>
                        <Divider component="li" className={classes.inset} />
                        <ListItem className={classnames(classes.stretch, classes.spacedBtwn)}>
                            <ListItemText
                                primary="Total Tickets"
                                className={classes.paddingRight}
                                secondary="Total Availability"
                                onClick={this.totalTicketsEdit}></ListItemText>
                            <div className={classes.fragmentUpload}>
                                {!this.state.totalTicketsClicked && <div
                                    className={classnames(classes.bold, classes.textAlignRight, classes.alignCenter)}
                                    onClick={this.totalTicketsEdit}>{this.state.totalTickets}
                                </div>}

                                {this.state.totalTicketsClicked && <div className={classes.ticketsTotalDiv}>
                                    <div
                                        className={classnames(classes.alignCenter, classes.counterDiv, classes.flexEnd, classes.ticketCountDiv)}>
                                        <NumericInput
                                            name="totalTickets"
                                            className={classes.counter}
                                            value={this.state.totalTickets}
                                            onChange={this.changeCounter}
                                            min={1}
                                            max={100}
                                            step={1}
                                            precision={0}
                                            size={5}
                                            noValidate
                                            inputMode="numeric"
                                            format={this.formatAbs}
                                            mobile></NumericInput>
                                    </div>

                                </div>}
                                {<div className={classes.uploadTicketsDiv}>
                                    {this.state.open && <UploadTickets
                                        numberOfTickets={this.state.totalTickets}
                                        open={this.state.open}
                                        handleClose={this.handleUploadClose}
                                        token={queryString
                                            .parse(this.props.location.search)
                                            .token}></UploadTickets>}

                                    {this.props.ticketImages && this.props.ticketImages.length == 0 && <div className={classes.link} onClick={this.openUpload}>Upload your own tickets ?
                                    </div>}
                                    {< div
                                        className={
                                            classes.link
                                        }
                                        onClick={
                                            this.openUpload
                                        } > {
                                            this
                                                .props
                                                .ticketImages
                                                .map((ticketImg, index) => (index < 3 && <img
                                                    key={index}
                                                    style={{
                                                        width: "30px",
                                                        height: 'auto',
                                                        paddingRight: '4px'
                                                    }}
                                                    src={ticketImg.ticketImage} />))
                                        }
                                        {
                                            this.props.ticketImages && this.props.ticketImages.length > 3 && <span className={classes.moreTicks}>+ {this.props.ticketImages.length - 3}</span>
                                        } </div>}
                                </div>}
                            </div>

                        </ListItem>
                        <Divider component="li" className={classes.insetMid} />
                        <ListItem className={classes.stretch}>
                            <ListItemText
                                primary="Maximum Tickets Per Person"
                                className={classes.paddingRight}
                                onClick={this.maxTicketsPerPersonEdit}
                                secondary="Number of tickets each person can get" /> {!this.state.maxTicketClicked && <div
                                    className={classnames(classes.bold, classes.alignCenter)}
                                    onClick={this.maxTicketsPerPersonEdit}>{this.state.maxTicketsPerPerson}</div>}

                            {this.state.maxTicketClicked && <div className={classnames(classes.alignCenter, classes.counterDiv)}><NumericInput
                                name="maxTicketsPerPerson"
                                className={classes.counter}
                                value={this.state.maxTicketsPerPerson}
                                onChange={this.changeCounter}
                                min={1}
                                max={this.state.totalTickets}
                                step={1}
                                precision={0}
                                size={5}
                                noValidate
                                inputMode="numeric"
                                format={this.formatAbs}
                                mobile /></div>}
                        </ListItem>
                        <Divider component="li" className={classes.inset} />
                        <ListItem className={classes.stretch}>
                            <ListItemText
                                primary="Price Per Ticket"
                                className={classes.paddingRight}
                                onClick={this.pricePerTicketEdit}
                                secondary="" /> {!this.state.pricePerTicketClicked && <div
                                    className={classnames(classes.bold, classes.alignCenter)}
                                    onClick={this.pricePerTicketEdit}>{this.state.pricePerTicket && this.state.pricePerTicket > 0
                                        ? <span>
                                            {this.state.pricePerTicketText}
                                        </span>
                                        : 'Free'}</div>}

                            {this.state.pricePerTicketClicked && <div className={classnames(classes.alignCenter, classes.counterDiv)}><NumericInput
                                name="pricePerTicket"
                                className={classnames(classes.counter, classes.counterCustomWidth)}
                                value={this.state.pricePerTicket}
                                onChange={this.changeCounter}
                                min={0}
                                step={1}
                                precision={2}
                                size={10}
                                noValidate
                                inputMode="numeric"
                                parse={this.parseFn}
                                format={this.formatAmount}
                                mobile /></div>}
                        </ListItem>
                        {(this.props.permission === 'EDIT' || this.props.permission === 'ADD') && <Fragment><Divider component="li" className={classes.insetMid} />

                            <ListItem>
                                <ListItemText
                                    primary={this.state.pricePerTicket > 0
                                        ? 'Payout'
                                        : 'Ownership'}
                                    className={classes.paddingRight}
                                    onClick={this.pricePerTicketEdit} />
                                <div className={classes.flexPayout}>
                                    <div><Switch
                                        color="primary"
                                        checked={!this.state.proxy}
                                        onChange={this.handleProxyChange}
                                        value={this.state.proxy} /></div>
                                    {this.state.pricePerTicket > 0 && <div className={classes.payoutText}>{!this.state.proxy
                                        ? 'I receive the payout'
                                        : this.props.calendarOwner.name + ' (Calendar owner) receives the payout'}</div>}
                                    {this.state.pricePerTicket == 0 && <div className={classes.payoutText}>{!this.state.proxy
                                        ? 'I own this ticket posting'
                                        : this.props.calendarOwner.name + ' (Calendar Owner) is the seller and I am creating this on his behalf'}</div>}

                                </div>
                            </ListItem>
                        </Fragment>}
                        <Divider component="li" className={classes.inset} />
                    </List>
                    {this.state.createTicketsClicked && <div className={classes.helperErrText}>
                        {!this.state.inPickedEvent && (!this.state.eventName || (this.state.eventName && this.state.eventName.trim() == '')) && <FormHelperText error={true}>Please enter event name</FormHelperText>}
                        {!this.state.inPickedCalendar && (!this.state.calendarName || (this.state.calendarName && this.state.calendarName.trim() == '')) && <FormHelperText error={true}>Please enter calendar Name</FormHelperText>}
                        {this.state.titleMandErr && <FormHelperText error={true}>Please enter ticket title
                        </FormHelperText>}
                        {!this.state.isAllDay && (this.state.errEndTime || this.state.errStartTime || this.state.errStartDateTime || this.state.errEndDateTime) && <FormHelperText error={true}>Invalid event time</FormHelperText>}
                    </div>}
                    <div className={classes.scrollDiv} id="scrollDivForStripe">

                        <div className={classes.createBtnDiv} onClick={this.blur}>

                            <Button
                                variant="contained"
                                disabled={this.state.pricePerTicket > 0 && ((!this.state.proxy && !this.props.stripeConnected && !this.state.connectedNow) || (this.state.proxy && !this.props.ownerStripeConnected))}
                                className={classes.button}
                                onClick={this.createTickets}>
                                Create Tickets
                            </Button>

                        </div>
                        {queryString
                            .parse(this.props.location.search)
                            .return == 'true' && <div className={classes.backBtnDiv} onClick={this.blur}>
                                <Button variant="contained" className={classes.buttonBack} onClick={this.back}>
                                    Back
                                </Button>
                            </div>}
                        {!this.state.proxy && !this.props.stripeConnected && this.state.connectedNow && <div className={classes.animationDiv}>
                            <HeartBeatDiv className={classes.heartBeatDiv}>
                                <CheckCircleIcon className={classes.icon}></CheckCircleIcon>

                            </HeartBeatDiv>
                            <div className={classes.welcome}>
                                All Set ! Now you can post paid tickets</div>

                            <div className={classes.welcome}>
                                Reach out to us anytime with questions on
                            </div>
                            <div className={classnames(classes.welcome, classes.green)}>Allcal Help thread in Chat Section</div>
                        </div>
                        }

                        {this.state.pricePerTicket > 0 && ((!this.state.proxy && !this.props.stripeConnected && !this.state.connectedNow) || (this.state.proxy && !this.props.ownerStripeConnected)) && <BouncyDiv>
                            {(!this.state.proxy && !this.props.stripeConnected && !this.state.connectedNow) && <div className={classes.connectWithStripeBtnDiv}>

                                <Link
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => { Event(googleTrackingCategory.CREATE_TICKET_SECTION, "Connect With Stripe Clicked", localStorage.getItem('userId')); }}
                                    href={constants.ticketsBaseUrl + '/authorize/calendar/' + this.props.match.params.calendarId + '/event/' + this.props.match.params.eventId + '?token=' + localStorage.getItem('loginToken') + '&origin=allcal_app'}>
                                    <img src={connectWithStripe}></img>
                                </Link>

                            </div>
                            }
                            {this.state.proxy && !this.props.ownerStripeConnected && <div className={classes.connectWithStripeBtnDivText}>
                                Calendar Owner ({this.props.calendarOwner.name}) not connected with stripe to
                                    receive payouts. You will not be able to proceed until the owner is connected.
                            </div>
                            }

                            <div className={classes.stripeTextDiv}>
                                <img src={poweredByStripe}></img>

                            </div>
                            <div className={classes.disclaimer}>
                                <span>Allcal uses
                                    <Link
                                        rel="noreferrer"
                                        target="_blank"
                                        href="https://stripe.com"
                                        className={classnames(classes.link, classes.linkOverrideColor)}>Stripe</Link>
                                    to get you paid quickly and keep your personal and payment information secure.
                                    <br></br>Set up a Stripe account to get paid with Allcal Tickets.
                                </span>

                            </div>
                            <div className={classes.disclaimer}>
                                This is a one time setup.
                            </div>
                            <div className={classes.note}>Any Questions ? Reach out to us anytime on
                                <br></br>Allcal Help thread in chat section</div>

                            <div
                                className={classnames(classes.stripeTextDiv, classes.marginTopHelpLinksDiv)}>
                                <Link
                                    rel="noreferrer"
                                    className={classes.link}
                                    target="_blank"
                                    href="https://allcal.com/tickets">How to become a seller ?</Link>

                            </div>
                            <div className={classnames(classes.stripeTextDiv, classes.marginTopHelpTexts)}>
                                <Link
                                    rel="noreferrer"
                                    className={classes.link}
                                    target="_blank"
                                    href="https://allcal.com/tickets">Processing Fees</Link>

                            </div>

                            <div
                                className={classnames(classes.stripeTextDiv, classes.marginTopHelpTexts, classes.marginBottom)}>
                                <Link
                                    rel="noreferrer"
                                    className={classes.link}
                                    target="_blank"
                                    href="https://allcal.com/tickets">How are we better than other ticketing platforms</Link>

                            </div>

                        </BouncyDiv>
                        }
                    </div>
                </div>}
            </Fragment>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        stripeConnected: state.authentication.stripeConnected,
        connectedNow: state.authentication.connectedNow,
        currentUser: state.authentication.currentUser,
        permission: state.authentication.permission,
        calendarOwner: state.authentication.calendarOwner,
        ownerStripeConnected: state.authentication.ownerStripeConnected,
        ticketImages: state.ticketsUpload.ticketImages
    };
}
const mapDispatchToProps = (dispatch) => {

    return {
        simulate: (sleep, pid, cb) => dispatch(simulate(sleep, pid, cb)),
        createNewCalendar: (token, calendarName, visibility, callbackFn) => dispatch(createNewCalendar(token, calendarName, visibility, callbackFn)),
        getAllMyCalendars: (token, callbackFn) => dispatch(getAllMyCalendars(token, callbackFn)),
        getCalendarEvents: (token, calendarId, callbackFn) => dispatch(getCalendarEvents(token, calendarId, callbackFn)),
        getCalendarInfoAndStripeStatus: (token, calId) => dispatch(getCalendarInfoAndStripeStatus(token, calId)),
        createNewEvent: (token, eventCreationObj, callbackFn) => dispatch(createNewEvent(token, eventCreationObj, callbackFn)),
        postTickets: (token, type, calendarId, desc, eventId, isValidationNeeded, maxClaimableTickets, noOfTicketsIssued, ticketAmount, title, callbackFn, seller) => dispatch(postTickets(token, type, calendarId, desc, eventId, isValidationNeeded, maxClaimableTickets, noOfTicketsIssued, ticketAmount, title, callbackFn, seller)),
        getTicketImages: (numberOfTickets) => dispatch(getTicketImages(numberOfTickets)),
        uploadTicketImage: (file, token, calendarId, eventId, postingId, ticketNumber, ticketTitle, ticketMoreInfo, callbackFn) => dispatch(uploadTicketImage(file, token, calendarId, eventId, postingId, ticketNumber, ticketTitle, ticketMoreInfo, callbackFn))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(createTickets)))
