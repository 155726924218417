import { useEffect, useState } from 'react'

import { constants } from '../App.constants'


const axios = require('axios');


export default function useEventInfo(calendarId, eventId) {

    const [eventInfo, setEventInfo] = useState(null)
    useEffect(() => {

        async function fetchData() {
            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }
            let payload;
            if (calendarId) {
                payload = await axios.get(constants.apiUrl + "/event?calendarId=" + calendarId + "&eventId=" + eventId, header);
            } else if (eventId) {
                header.headers['access-token'] = '3207498533.1677ed0.6fe195488f934a3f9fc2bd1053ea8356'
                payload = await axios.get(constants.apiUrl + "/events/" + eventId, header);
            }

            payload && payload.data && setEventInfo(payload.data)
        }
        fetchData()

        return () => {
            setEventInfo(null)
        };
    }, [calendarId, eventId])

    return eventInfo
}
