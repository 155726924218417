import { dispatchErrorWrap, dispatchSuccessWrap } from './genericActions';

import { actionTypes } from '../types';
import { constants } from '../../App.constants';
import { filter } from 'lodash';

const axios = require('axios');

export const getTicketPostings = (eventId, postingsToFilterArr, callback) => {
    // Make async call  dispatchSuccessWrap('CHANGE_ORGANIZATION', org, dispatch)

    return async (dispatch, getState) => {
        // Make async call
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            const payload = await axios.get(constants.ticketsBaseUrl + "/admin/events/" + eventId, headers);
            let filteredPostings = [];
            if (getState().authentication.permission == 'ADD') {
                filteredPostings = filter(payload.data.eventInfo, function (o) {
                    return (o.seller.userId === getState().authentication.currentUser.userId || (o.proxyUserInfo && (o.proxyUserInfo.userId === getState().authentication.currentUser.userId)));
                });

            } else {
                filteredPostings = payload.data.eventInfo
            }
            if (!postingsToFilterArr || postingsToFilterArr.length == 0) {
                dispatchSuccessWrap(actionTypes.GET_TICKET_POSTINGS, {
                    'postings': payload.data.eventInfo,
                    'postingsForReport': filteredPostings,

                }, dispatch);
                //await axios.get('https://httpstat.us/200?sleep=200');
                setTimeout(callback, 200);
            } else {
                let userFilteredArrForReports = filter(filteredPostings, function (o) {
                    return postingsToFilterArr.includes(o.posting);
                });

                dispatchSuccessWrap(actionTypes.GET_TICKET_POSTINGS, {
                    'postings': payload.data.eventInfo,
                    'postingsForReport': userFilteredArrForReports,

                }, dispatch);
                //await axios.get('https://httpstat.us/200?sleep=200'); callback();
                setTimeout(callback, 200);
            }

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }
        //dispatchSuccessWrap({type : 'ADD_WORKER', worker : worker});
    }

}

export const addToDictionary = (eventInfo) => {
    // Make async call  dispatchSuccessWrap('CHANGE_ORGANIZATION', org, dispatch)
    return (dispatch, getState) => {
        if (eventInfo)
            dispatchSuccessWrap(actionTypes.ADD_TO_EVENTINFO_DIC, eventInfo, dispatch)
    }

}



export const getAllTicketPostings = (from, to, postingsToFilterArr, callbackFn) => {
    // Make async call  dispatchSuccessWrap('CHANGE_ORGANIZATION', org, dispatch)

    return async (dispatch, getState) => {
        // Make async call
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }
            let apiUrl = "/tickets/postingsInfo";
            if (from) {
                apiUrl = apiUrl + '?from=' + from
            }
            if (to) {
                apiUrl = apiUrl + '&to=' + to
            }
            let filteredPostings = [];
            const payload = await axios.get(constants.ticketsBaseUrl + apiUrl, headers);
            filteredPostings = payload.data.data;
            if (!postingsToFilterArr || postingsToFilterArr.length == 0) {

                !payload.data.error && dispatchSuccessWrap(actionTypes.GET_ALL_TICKET_POSTINGS, {
                    'postings': payload.data.data,
                    'postingsForReport': payload.data.data
                }, dispatch);
                callbackFn && callbackFn(payload.data.error);

            } else {
                let userFilteredArrForReports = filter(filteredPostings, function (o) {
                    return postingsToFilterArr.includes(o.posting);
                });

                !payload.data.error && dispatchSuccessWrap(actionTypes.GET_ALL_TICKET_POSTINGS, {
                    'postings': payload.data.data,
                    'postingsForReport': userFilteredArrForReports
                }, dispatch);
                callbackFn && callbackFn(payload.data.error);

            }

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
            callbackFn && callbackFn(error);
        }
        //dispatchSuccessWrap({type : 'ADD_WORKER', worker : worker});
    }

}