
import Pusher from 'pusher-js'

export function dispatchSuccessWrap(type, payload, dispatch) {

    dispatch({
        type: type,
        payload: payload
    });
    // return success;
}

export function dispatchErrorWrap(payload, dispatch) {
    dispatch({
        type: 'ERROR',
        payload: payload
    });
    // return success;
}

export const pusher = new Pusher('a045a8af979dc4cb0080', {
    cluster: 'mt1',
    forceTLS: true
});