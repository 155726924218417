import React, { useEffect, useState, Fragment } from 'react'
import ProfileCard from './profileCard'
import Grid from '@material-ui/core/Grid'
import { useSelector, useDispatch } from 'react-redux'
import { getRSVPForEvent } from '../../sagas/store/actions/rsvp.actions'
import InfiniteScroll from 'react-infinite-scroll-component'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import PropTypes from 'prop-types'

function EventAttendees({ eventId }) {
    const eventAttendees = useSelector(state => state.rsvps[eventId] && state.rsvps[eventId].users)
    const hasMore = useSelector(state => state.rsvps[eventId] && state.rsvps[eventId].hasMore)
    const [isFetching, setIsFetching] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        (!eventAttendees) ? (() => {
            setIsFetching(true)
            dispatch(getRSVPForEvent(eventId, 0))
        })() : setIsFetching(false)
    }, [dispatch, eventId, eventAttendees, isFetching])

    const fetchMoreData = () => {
        setIsFetching(true)
        hasMore && eventAttendees && dispatch(getRSVPForEvent(eventId, eventAttendees.length))
    }

    return (
        <ScrollableDiv id='scrollableDiv' style={{ height: 600, overflow: 'auto' }}   >
            <InfiniteScroll
                dataLength={eventAttendees ? eventAttendees.length : 10}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Fragment>

                    <Grid container>
                        <Skeleton></Skeleton>
                    </Grid></Fragment>}
                scrollableTarget='scrollableDiv'
            >
                {eventAttendees && eventAttendees.map((attendee, index) =>
                    <Grid key={index} container>
                        <ProfileCard eventId={eventId} user={attendee} rsvpStatus={attendee.status}></ProfileCard>
                    </Grid>
                )}
                {isFetching && [...Array(10).keys()].map((key) => <Grid key={key} container>
                    <ProfileCard></ProfileCard>
                </Grid>)}
            </InfiniteScroll>
        </ScrollableDiv>
    )
}

EventAttendees.propTypes = {
    eventId: PropTypes.string
}

const ScrollableDiv = styled.div`
   height : 100vh;
   overflow : auto
`
export default EventAttendees
