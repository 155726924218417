import React, { Component, Fragment } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { isEqual, map } from 'lodash'
import { Event, PageView, initGA } from './Tracking';

import Accepted from '@material-ui/icons/HowToRegOutlined';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateCard from './ui/cards/dateCard'
import DateRangePicker from './ui/pickers/dateRangePicker'
import Edit from '@material-ui/icons/Edit';
import EditTickets from './editTickets'
import Emailed from '@material-ui/icons/SendOutlined';
import EventInfo from './ui/eventInfo'
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterSummary from './filterSummary'
import FullWidthDialog from './ui/dialogs/fullWidthDialog'
import IconButton from '@material-ui/core/IconButton';
import LocationOff from '@material-ui/icons/LocationOffOutlined';
import LocationOn from '@material-ui/icons/LocationOnOutlined';
import NoEmail from '@material-ui/icons/ReportProblem';
import NotificationsActive from '@material-ui/icons/NotificationsActiveOutlined';
import NotificationsOff from '@material-ui/icons/NotificationsOffOutlined';
import SalesSummary from './salesSummary'
import Table from '../components/ui/tables/table'
import Tooltip from '@material-ui/core/Tooltip';
import Work from '@material-ui/icons/WorkOutline';
import classnames from 'classnames'
import { connect } from 'react-redux'
import { getAllTicketPostings } from '../store/actions/manageTicketsActions';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import moment from 'moment-timezone'
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import { fetchEventsData } from '../utils/genericAPIs'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  content: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  rootExpanded: {
    display: 'flex',
    maxWidth: theme.spacing(70),
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  contentExpanded: {
    flexGrow: 1,
    paddingBottom: theme.spacing(3)
  },
  iconButton: {
    padding: 10,

  },
  editIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1)
  },
  flexWithWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    color: theme.palette.primary.main,
    [
      theme
        .breakpoints
        .up('md')
    ]: {
      width: '80%',
    },
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      width: '100%',
    },

    justifyContent: 'flex-start',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 1,


  },
  containerForDateFilter: {
    display: 'block',
    width: '40%',
    margin: 'auto',

  },
  pullRight: {
    float: 'right'
  },
  avatar: {
    backgroundColor: grey[100],
    color: indigo[500],
    borderRadius: '0px',
    alignItems: 'center'
  },
  statuscell: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'

  },
  grey: {
    color: grey[500]
  },
  center: {
    textAlign: 'center',
    marginRight: theme.spacing.unit * 20
  },
  noFlex: {
    width: '100%',
    flexWrap: 'wrap'
  },
  paper: {
    width: '100%',
    height: '200px'
  },
  marginTop24: {
    marginTop: theme.spacing(3)
  },
  spaceBtwn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  manageDiv: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },

    paddingBottom: theme.spacing(10),
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  higlightedRow: {
    backgroundColor: theme.palette.primary.main,
    color: 'white !important',
    '&:hover': {
      background: 'transparent',
      color: 'black !important'
    }
  }

});


export class manageTickets extends Component {
  componentDidMount() {
    initGA('UA-106455531-7');
    PageView('/manage');

    this.setState({
      isLoading: true
    })

    this.props.modified || this.props.ticketPostings.length == 0 || (this.props.created && !queryString
      .parse(this.props.location.search)
      .posting) ? this
        .props
        .getAllTicketPostings(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString(), undefined, (err) => {

          this.setState({
            isLoading: false
          })

        }) : this.setState({
          isLoading: false
        });

  }
  state = {
    reloadEvents: true,
    startDate: moment(new Date()).subtract(30, 'd'),
    endDate: moment(new Date()),
  }
  handleFilterClick = () => {
    this.setState({
      filterOpen: true
    })
  }
  handleFilterClose = () => {
    this.setState({
      filterOpen: false
    })
  }
  handleFilterDate = (filterDates) => {
    //console.log('filterDates', filterDates);
    this.setState({
      filterOpen: false,
      startDate: moment(filterDates.startDate),
      endDate: moment(filterDates.endDate)
    }, () => {
      this
        .props
        .getAllTicketPostings(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString());


    })

  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }
  onSearchOpen = () => {

  }
  onDownload = (buildHead, buildBody, columns, allRows) => {
    return `${buildHead(columns)}${buildBody(allRows)}`.trim();
    /* const eventDict = this.props.eventDictionary;
     //console.log(eventDict)
     let eventsNotInDict = [];
     allRows.forEach((row, index) => {
       if (eventDict[row.data[2]]) {
         row.data[2] = eventDict[row.data[2]].name
       } else {
         eventsNotInDict.push({ index: index, eventId: row.data[2] })
       }
 
     })
 
     if (eventsNotInDict.length > 0) {
 
       let response = await fetchEventsData(eventsNotInDict)
       //console.log(response)
       return `${buildHead(columns)}${buildBody(allRows)}`.trim();
 
     } else {
       //console.log('No Dict flow')
      
 
     }*/

  }
  render() {
    const { classes } = this.props;

    let columns = [
      {
        label: "Posted On",
        searchable: false,
        name: "postingTime",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {

            return (value && moment(value).format('ll'))
          }
        }

      },
      {
        label: "Ticket Title",
        name: "title",

      },
      {
        label: "calendar",
        searchable: false,
        name: "calendar",
        options: {
          display: 'excluded'
        }


      },
      {
        label: "posting",
        name: "posting",
        searchable: false,
        options: {
          display: 'excluded'
        }

      },

      {
        label: "Event Name",
        searchable: false,
        name: "event",
        options: {
          download: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            //console.log(this.props.eventDictionary)
            /* if (this.props.eventDictionary[tableMeta.rowData[4]]) {
               //console.log('Now Name added')
               return this.props.eventDictionary[tableMeta.rowData[4]].name
             } else { */
            //console.log('Event Info rendered')
            return <EventInfo calendarId={tableMeta.rowData[2]} eventId={tableMeta.rowData[4]} ></EventInfo >

            //}
          }
        }

      },


      {
        label: "Ticket Price",
        searchable: false,
        name: "ticketAmount",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {

            return value == 0 ? 'Free' : '$ ' + value
          }
        }
      },
      {
        label: "Tickets Remaining",
        searchable: false,
        name: "ticketsRemaining",

      }, {
        label: "Total Tickets",
        searchable: false,
        name: "noOfTicketsIssued",

      },
      {
        label: "isModified",
        searchable: false,
        name: "isModified",
        options: {
          display: 'excluded'
        }

      }

    ];

    const options = {
      filter: false,
      selectableRows: 'none',
      filterType: 'textField',
      responsive: 'scroll',
      rowsPerPage: 10,
      viewColumns: false,
      onDownload: this.onDownload,
      onSearchOpen: this.onSearchOpen,
      downloadOptions: {
        filename: 'excel-format.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      },
      searchPlaceholder: 'Enter Ticket title to search',
      /*customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col && col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      }, */
      setRowProps: (row) => {
        //console.log(row);
        return {
          className: classnames(
            {
              [this.props.classes.higlightedRow]: (row[3] === queryString
                .parse(this.props.location.search)
                .posting || row[8] == true)
            }),

        };
      },
      fixedHeaderOptions: {
        xAxis: false,
        yAxis: true
      },
      expandableRows: false,
      renderExpandableRow: (rowData, rowMeta) => {
        //console.log(rowData, rowMeta);
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <div className={classes.rootExpanded}>
                <div className={classes.contentExpanded}>


                </div>
              </div>
            </TableCell>
          </TableRow>




        );
      },

      onRowsSelect: (rowsSelected, allRows) => {
        //console.log(rowsSelected, allRows);

      },
      onRowsDelete: (rowsDeleted) => {
        //////console.log(rowsDeleted, "were deleted!");
      },
      onChangePage: (numberRows) => {
        ////console.log(numberRows);

      },
      onSearchChange: (searchText) => {
        ////console.log(searchText);
      },
      onColumnSortChange: (column, direction) => {
        ////console.log(column, direction);
      },
      onColumnViewChange: (column, action) => {
        ////console.log(column, action);
      },
      onFilterChange: (column, filters) => {
        ////console.log(column, filters);
      },
      onCellClick: (cellIndex, rowIndex) => {
        //console.log(cellIndex, rowIndex);
      },
      onRowClick: (rowData, rowState) => {
        //console.log(rowData)
        this.setState(state => ({
          ...state,
          open: true,
          headingForDialog: rowData[1],
          componentToDisplay:
            <div className={classes.rootExpanded}>
              <div className={classes.contentExpanded}>
                <EditTickets calendarId={rowData[2]} postingId={rowData[3]} eventId={rowData[4].props.eventId} closeDialog={this.handleClose}></EditTickets> </div></div>
        }))
      }
    };


    return (
      <div className={classes.flexWithWrap}>
        {/*<FilterSummary fullWidth={true} maxWidth={'md'} open={this.state.openFilter} handleClose={this.handleCloseFilter} ticketPostings={this.props.ticketPostings} handleChange={this.handleChangeFilter} passedOnStateWithSelectedPostings={this.state}></FilterSummary> */}

        <div className={classes.container}>

          {(() => {
            return !this.state.filterOpen && <span className={classes.spaceBtwn}>
              <div className={classes.container}>
                <DateCard
                  text={this
                    .state
                    .startDate
                    .format('MMM Do YY') + ' - ' + this
                      .state
                      .endDate
                      .format('MMM Do YY')}></DateCard>
                <Edit className={classes.editIcon} onClick={this.handleFilterClick}></Edit>

              </div>
              {/*<div>
                <IconButton onClick={this.onFilterClick} color="primary" className={classes.iconButton} aria-label="filter">
                  <FilterListIcon
                    className={!this.state.isFilterSelected
                      ? classes.filterIcon
                      : classes.filterIconSelected}
                  ></FilterListIcon>
                </IconButton>
              </div> */}
            </span>

          })()}
          {(() => {
            return this.state.filterOpen && <div className={classes.containerForDateFilter}>
              <DateRangePicker
                onFilterClose={this.handleFilterClose}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onFilterDate={this.handleFilterDate}></DateRangePicker>
            </div>
          })()}

        </div>

        {this.state.isLoading ? <div className={classes.circularProgress}><CircularProgress ></CircularProgress></div> : <div className={classnames(classes.marginTop24, classes.manageDiv)}><FullWidthDialog heading={this.state.headingForDialog} open={this.state.open} handleClose={this.handleClose} componentToDisplay={this.state.componentToDisplay}></FullWidthDialog><Table data={this.props.ticketPostings} columns={columns} options={options} /></div>}
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    ticketPostings: state.ticketPostings.postings,
    modified: state.ticketPostings.modified,
    created: state.ticketPostings.created,
    eventDictionary: state.eventDictionary
  };
}
const mapDispatchToProps = (dispatch) => {

  return {
    getAllTicketPostings: (from, to, filter, callback) => dispatch(getAllTicketPostings(from, to, filter, callback)),
  }

}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(manageTickets))

