export const GET_RSVPS = 'GET_RSVPS'
export const SET_RSVPS = 'SET_RSVPS'
export const SET_RSVP_EVENT = 'SET_RSVP_EVENT'
export const GET_RSVP_EVENT = 'GET_RSVP_EVENT'
export const MODIFY_MY_RSVP = 'MODIFY_MY_RSVP'
export const SET_MY_RSVP = 'SET_MY_RSVP'
export const SEND_INVITE = 'SEND_INVITE'
export const ADD_USER = 'ADD_USER'

const getAllRsvps = () => ({
    type: GET_RSVPS

})

const modifyMyRsvp = (status, attendanceStatus, rsvpId) => ({
    type: MODIFY_MY_RSVP,
    payload: {
        status: status,
        attendanceStatus: attendanceStatus,
        rsvpId: rsvpId
    }

})

const setMyRSVP = (rsvp) => ({
    type: SET_MY_RSVP,
    payload: rsvp

})

const setAllRsvps = (rsvps) => ({
    type: SET_RSVPS,
    payload: rsvps
})

const getRSVPForEvent = (eventId, skipNumber) => ({
    type: GET_RSVP_EVENT,
    payload: {
        eventId: eventId,
        skipNumber: skipNumber
    }
})

const setEventRsvpData = (data, eventId, skipNumber) => ({

    type: SET_RSVP_EVENT,
    payload: {
        data: data,
        eventId: eventId,
        skipNumber: skipNumber
    }
})

const sendInvite = (searchString, eventId, groupId, callback) => ({

    type: SEND_INVITE,
    payload: {
        searchString: searchString,
        eventId: eventId,
        groupId: groupId,
        callback: callback
    }

})

const addUser = (user, eventId) => ({

    type: ADD_USER,
    payload: {
        newUser: user,
        eventId: eventId
    }

})



export {
    getAllRsvps,
    setAllRsvps,
    getRSVPForEvent,
    setEventRsvpData,
    modifyMyRsvp,
    setMyRSVP,
    sendInvite,
    addUser
}