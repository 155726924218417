import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

//import Account from '../layout/Account';
//import Notification from '../layout/Notification';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems : 'center'
        },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    
    toolBar:{
      display:'flex',
      justifyContent:'space-between'
    },
    accountBtn:{
      fontSize:'1.5em'
    },
    logoutBtn:{
      cursor : 'pointer'
    },
    fontHeader:{
      fontSize : theme.spacing(3)
    }
});

function AppBarComponent(props) {
  const { classes , isLoginTokenValid ,name ,logoutFn} = props;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        
        <Toolbar className={classes.toolBar}>
          <div >
          
            <h3 className={classes.fontHeader} variant="h6" color="inherit" >
             {name}
             
            </h3>
            
          </div>
          {(()=>{

            return isLoginTokenValid && 
            <div className={classes.root}>
            {<PowerSettingsNewOutlinedIcon className={classes.logoutBtn} onClick={()=>{logoutFn()}} ></PowerSettingsNewOutlinedIcon>}
            
          </div>
          })()}
          
          
        </Toolbar>
      </AppBar>
      
    </div>
  );
}

AppBarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppBarComponent);
