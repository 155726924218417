import React, { Component } from 'react';

import { checkIfLoggedIn } from '../../store/actions/authenticationActions'
import { connect } from 'react-redux';

export default function (ComposedComponent) {
    //console.log("Inside HOC");
    class Authentication extends Component {
        state = {
            connectedNow: false
        }
        componentDidMount() {

            if (this.props.isLoginTokenValid === undefined) {

                this.props.checkIfLoggedIn().then(() => {
                    if (this.props.isLoginTokenValid === false) {
                        this.props.history.push('/signIn');
                    }


                });
            }
            if (this.props.isLoginTokenValid == false) {
                this.props.history.push('/signIn');
            }

        }

        render() {

            return this.props.isLoginTokenValid && this.props.currentUser ? <ComposedComponent {...this.props} /> : ''

        }
    }

    const mapStateToProps = (state) => {
        return {
            isLoginTokenValid: state.authentication.isLoginTokenValid,
            currentUser: state.authentication.currentUser
        };
    }
    const mapDispatchToProps = (dispatch) => {

        return {
            checkIfLoggedIn: () => dispatch(checkIfLoggedIn())
        }

    }
    return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}