import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import {
    GET_RSVPS, GET_RSVP_EVENT, MODIFY_MY_RSVP, SEND_INVITE, ADD_USER,
    setAllRsvps, setEventRsvpData, setMyRSVP, addUser
} from '../store/actions/rsvp.actions'

import { getRsvps, getEventRsvpData, postMyRsvp, shareEvent } from '../store/service/rsvp.datasource'

function* fetchRsvps(action) {
    const response = yield call(getRsvps)
    const rsvpsData = response.data
    yield put(setAllRsvps(rsvpsData))

}

function* postMyRsvpInfo(action) {
    const response = yield call(postMyRsvp, action.payload.status, action.payload.attendanceStatus, action.payload.rsvpId)
    const rsvpsData = response.data
    yield put(setMyRSVP(rsvpsData))

}

function* sendShareInvite(action) {
    yield call(shareEvent, action.payload.searchString, action.payload.eventId, action.payload.groupId, action.payload.callback)

}

export function* RsvpsFetchSaga() {
    yield takeLatest(GET_RSVPS, fetchRsvps)
    yield takeLatest(MODIFY_MY_RSVP, postMyRsvpInfo)
    yield takeLatest(SEND_INVITE, sendShareInvite)
}

function* fetchRsvpForEvent(action) {

    const response = yield call(getEventRsvpData, action.payload.eventId, action.payload.skipNumber)
    const rsvpsData = response.data
    yield put(setEventRsvpData(rsvpsData, action.payload.eventId, action.payload.skipNumber))
}

export function* RsvpCountFetchSaga() {
    yield takeEvery(GET_RSVP_EVENT, fetchRsvpForEvent)
}
