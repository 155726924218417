import React, { Component, Fragment } from 'react'

import { Link, Typography, Grid } from '@material-ui/core';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import classnames from 'classnames'
import { connect } from 'react-redux';
import connectWithStripe from '../assets/images/blue-on-light.png'
import poweredByStripe from '../assets/images/powered_by_stripe.png'
import { signOut } from '../store/actions/authenticationActions'
import { withStyles } from '@material-ui/core/styles';
import { constants } from '../App.constants'
import { pusher } from '../store/actions/genericActions';
import DoneIcon from '@material-ui/icons/Done';
import { updateConnectedNowStatus } from '../store/actions/authenticationActions'
import { checkIfLoggedIn } from '../store/actions/authenticationActions'
import { Event, PageView, initGA } from './Tracking';
import { googleTrackingCategory, googleTrackingLabel } from '../store/types'

const styles = (theme, props) => ({
    flexed: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        marginTop: theme.spacing(4),
        alignItems: 'center',
        flexDirection: 'column'

    },
    heading: {
        paddingRight: theme.spacing(1),
        fontSize: theme.spacing(3),
        fontWeight: 600
    },
    disclaimer: {
        display: 'flex',
        fontSize: theme.spacing(1.75),
        textAlign: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        fontWeight: 600,
        justifyContent: 'center'
    },
    stripeTextDiv: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },

    centered: {
        [
            theme
                .breakpoints
                .down('md')
        ]: {

            width: '100%'
        },
        [
            theme
                .breakpoints
                .up('md')
        ]: {

            width: '500px',
            margin: 'auto'
        }
    },
    poweredByStripe: {
        marginTop: theme.spacing(1)
    },

    marginTopLogout: {
        marginTop: theme.spacing(7)
    },
    fontForLogout: {
        fontSize: theme.spacing(3)
    },
    logoutIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    payoutActive: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    paddingLeft: {
        paddingLeft: theme.spacing(2)
    },
    successIcon: {
        color: theme.palette.success.main,
        paddingRight: theme.spacing(1)
    },
    note: {
        marginTop: theme.spacing(2)
    }
})

export class settings extends Component {
    state = {
        connectedNow: false
    }
    componentDidMount() {
        initGA('UA-106455531-7');
        PageView('/settings');
        var channel = this.props.currentUser.userId && pusher.subscribe(this.props.currentUser.userId);
        //const getShiftsForPusher = this.props.connectedNowFn;
        channel.bind('seller', function (data) {
            if (!this.props.stripeConnected) {
                this.setState({ connectedNow: true })
                this.props.updateConnectedNowStatus();
                Event(googleTrackingCategory.SETTINGS_SECTION, "Socket after successful Stripe Connect", localStorage.getItem('userId'));
            }
        }, this);

        if (!this.props.stripeConnected) {
            this.props.checkIfLoggedIn()
        }
    }
    render() {
        const { classes } = this.props;
        //console.log(this.props)
        return (
            <div className={classes.centered}>

                <div className={classes.flexed}>
                    {!this.props.stripeConnected && !this.state.connectedNow ? <div>
                        <Link
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => { Event(googleTrackingCategory.SETTINGS_SECTION, "Connect With Stripe Clicked", localStorage.getItem('userId')); }}
                            href={constants.ticketsBaseUrl + '/authorize/calendar/' + this.props.match.params.calendarId + '/event/' + this.props.match.params.eventId + '?token=' + localStorage.getItem('loginToken') + '&origin=allcal_app'}>
                            <img src={connectWithStripe}></img>
                        </Link>

                    </div> : <div className={classes.payoutActive}>
                            <Grid conatiner item>
                                <Typography variant="h5">Payouts</Typography>
                            </Grid>
                            <Grid container alignItems="center" className={classes.paddingLeft}>
                                <Grid item>
                                    <DoneIcon className={classes.successIcon}></DoneIcon>
                                </Grid>
                                <Grid item>
                                    You are all set to receive payouts
                                    </Grid>
                            </Grid>
                        </div>
                    }
                    <div className={classes.poweredByStripe}>
                        <img src={poweredByStripe} ></img>
                    </div>

                </div>
                <div>
                    {!this.props.stripeConnected && !this.state.connectedNow && <div className={classes.disclaimer}>
                        <span>Allcal uses
                            <Link
                                rel="noreferrer"
                                target="_blank"
                                href="https://stripe.com"
                                className={classnames(classes.link, classes.linkOverrideColor)}>
                                {' Stripe '}
                            </Link>
                            to get you paid quickly and keep your personal and payment information secure.
                            {!this.props.stripeConnected && !this.state.connectedNow && <span> Set up a Stripe account to get paid with Allcal Tickets.</span>}
                        </span>

                    </div>}
                    {!this.props.stripeConnected && !this.state.connectedNow && <div className={classes.disclaimer}>
                        This is a one time setup.
                    </div>}
                    <div className={classes.note}>Any Questions ? Please reach out to us. We are available 24 x 7 on
                        <br></br>Allcal Help chat thread on Allcal</div>
                    {!this.props.stripeConnected && !this.state.connectedNow && <Fragment>
                        <div
                            className={classnames(classes.stripeTextDiv, classes.marginTopHelpLinksDiv)}>
                            <Link
                                rel="noreferrer"
                                className={classes.link}
                                target="_blank"
                                href="https://allcal.com/tickets">How to become a seller ?</Link>

                        </div>
                        <div className={classnames(classes.stripeTextDiv, classes.marginTopHelpTexts)}>
                            <Link
                                rel="noreferrer"
                                className={classes.link}
                                target="_blank"
                                href="https://allcal.com/tickets">Processing Fees</Link>

                        </div>

                        <div
                            className={classnames(classes.stripeTextDiv, classes.marginTopHelpTexts, classes.marginBottom)}>
                            <Link
                                rel="noreferrer"
                                className={classes.link}
                                target="_blank"
                                href="https://allcal.com/tickets">How are we better than other ticketing platforms</Link>

                        </div>
                    </Fragment>}


                    <div className={classnames(classes.marginTopLogout, classes.fontForLogout, classes.logoutIcon)} onClick={() => { this.props.logout() }}>
                        Logout
                        <PowerSettingsNewOutlinedIcon className={classes.logoutBtn}  ></PowerSettingsNewOutlinedIcon>
                    </div>

                </div>

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoginTokenValid: state.authentication.isLoginTokenValid,
        stripeConnected: state.authentication.stripeConnected
    };
}
const mapDispatchToProps = (dispatch) => {

    return {
        logout: () => dispatch(signOut()),
        updateConnectedNowStatus: () => dispatch(updateConnectedNowStatus()),
        // To check if user has connected with stripe from the tickets section
        checkIfLoggedIn: () => dispatch(checkIfLoggedIn())
    }

}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(settings))
