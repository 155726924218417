import React, { Component, Fragment } from 'react';
import { saveSelectedTicketImages, uploadTicketImage } from '../store/actions/ticketsUploadActions';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Upload from './ui/upload'
import classnames from 'classnames';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Event, PageView, initGA } from './Tracking';
import { googleTrackingCategory, googleTrackingLabel } from '../store/types'

const styles = (theme, props) => ({
    floatLeft: {
        float: 'left'
    },
    floatRight: {
        float: 'right'
    },
    clear: {
        clear: 'both'
    },
    textField: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    padding: {
        padding: theme.spacing(1.5)
    },
    subpadding: {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(1.5)
    },
    card: {
        marginBottom: theme.spacing(2),
        background: '#d3d3d359'
    },
    ticketHeading: {
        fontWeight: 600
    },
    subticketHeading: {
        fontWeight: 600,
        color: 'dimgrey'
    },
    containerForUpload: {
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '500px',
        },

    },
    heading: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        fontSize: theme.spacing(3),
        fontWeight: 600
    },
    editIcon: {
        paddingLeft: theme.spacing(0.75),
        fontSize: theme.spacing(2),
        color: theme.palette.primary.main
    },
    headingTick: {
        display: 'flex',
        alignItems: 'center'
    },
    fixedDiv: {
        maxHeight: '58vh',
        overflowY: 'scroll'
    },
    createBtnDiv: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2)
    },
    backBtnDiv: {

        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2)

    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: theme.palette.primary.main
        }
    },
    buttonBack: {
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        letterSpacing: '1px',
        color: theme.palette.primary.main,
        background: 'white',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        },
        border: '1px solid ' + theme.palette.primary.main
    }
});

class uploadTickets extends Component {

    state = {
        savedImages: []
    }

    componentDidMount() {
        initGA('UA-106455531-7');
        PageView('/uploadTicket');

    }

    titleEditClicked = (index, value) => {
        this.setState({
            [index]: !this.state[index]

        })
    }

    ticketTitleChanged = type => e => {
        //console.log(e.target.name);
        //console.log(e.target.value);
        var index = this
            .props
            .ticketImages
            .findIndex(x => parseInt(x.ticketNumber) === (parseInt(e.target.name.split('_')[1]) + 1));
        let titleOrSub;
        if (type == 'sub') {
            titleOrSub = {
                id: 'upId_' + (parseInt(e
                    .target
                    .name
                    .split('_')[1]) + 1),
                moreInfo: e.target.value,
                ticketNumber: parseInt(e
                    .target
                    .name
                    .split('_')[1]) + 1
            }
        } else {
            titleOrSub = {
                id: 'upId_' + (parseInt(e
                    .target
                    .name
                    .split('_')[1]) + 1),
                ticketTitle: e.target.value,
                ticketNumber: parseInt(e
                    .target
                    .name
                    .split('_')[1]) + 1
            }
        }

        if (index > -1) {

            this.setState({
                savedImages: [
                    ...this
                        .props
                        .ticketImages
                        .slice(0, index),
                    Object.assign({}, this.props.ticketImages[index], titleOrSub),
                    ...this
                        .props
                        .ticketImages
                        .slice(index + 1)
                ],
                modified: true
            }, () => {
                //console.log(this.state);
                this
                    .props
                    .saveSelectedTicketImages(this.state.savedImages)

            });

        } else {
            // New
            this.setState({
                savedImages: [
                    ...this.props.ticketImages,
                    titleOrSub

                ],
                modified: true
            }, () => {
                //console.log(this.state);
                this
                    .props
                    .saveSelectedTicketImages(this.state.savedImages)

            })
        }

    }

    imageSelected = (id, file, rawFile, idx) => {
        var index = this
            .props
            .ticketImages
            .findIndex(x => x.ticketNumber === idx);
        if (index > -1) {
            if (file == null) {
                // file discarded
                this.setState({
                    savedImages: [
                        ...this
                            .props
                            .ticketImages
                            .slice(0, index),

                        ...this
                            .props
                            .ticketImages
                            .slice(index + 1)
                    ],
                    modified: true
                }, () => {
                    //console.log(this.state);
                    this
                        .props
                        .saveSelectedTicketImages(this.state.savedImages)

                });
            } else {

                this.setState({
                    savedImages: [
                        ...this
                            .props
                            .ticketImages
                            .slice(0, index),
                        Object.assign({}, this.props.ticketImages[index], {
                            id: id,
                            ticketImage: file,
                            ticketNumber: idx,
                            rawFile: rawFile

                        }),
                        ...this
                            .props
                            .ticketImages
                            .slice(index + 1)
                    ],
                    modified: true
                }, () => {
                    // //console.log(this.state);
                    this
                        .props
                        .saveSelectedTicketImages(this.state.savedImages)

                });

            }

        } else {
            // New
            this.setState({
                savedImages: [
                    ...this.props.ticketImages, {
                        id: id,
                        ticketImage: file,
                        ticketNumber: idx,
                        rawFile: rawFile
                    }
                ],
                modified: true
            }, () => {
                // //console.log(this.state);
                this
                    .props
                    .saveSelectedTicketImages(this.state.savedImages)

            })
        }

    }
    saveTickets = () => {

        this
            .props
            .handleClose(this.state.modified);
    }
    back = () => {
        let imageExists = false;
        this
            .props
            .ticketImages
            .forEach((image) => {
                if (image.ticketImage) {
                    imageExists = true
                }
            })
        if (!imageExists) {
            this.setState({
                savedImages: []
            }, () => {
                this
                    .props
                    .saveSelectedTicketImages(this.state.savedImages)

                this
                    .props
                    .handleClose(this.state.modified);

            })
        } else {
            this
                .props
                .handleClose(this.state.modified);
        }

    }
    render() {
        const { classes, ticketImages, numberOfTickets, open } = this.props;
        const items = []
        const numOfIters = numberOfTickets;

        for (let i = 0; i < numOfIters; i++) {
            let upId = 'upId_' + (i + 1);
            let title;

            if (this.props.ticketImages.length > 0) {
                if (find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1))) {
                    title = find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1)).ticketTitle
                }
            }
            let subTitle;

            if (this.props.ticketImages.length > 0) {
                if (find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1))) {
                    subTitle = find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1)).moreInfo
                }
            }
            let defaultTitle = 'Title # ' + (i + 1);

            let defaultSubTitle = 'Sub Title # ' + (i + 1);
            items.push(
                <div key={i + '_key'} className={classes.card}>
                    <div
                        className={classnames(classes.ticketHeading, classes.padding, classes.floatLeft)}>
                        {!this.state['titleEdit_' + i] && <div className={classes.headingTick}>
                            {this.props.ticketImages.length > 0 && find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1)) && find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1)).ticketTitle
                                ? find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1)).ticketTitle
                                : defaultTitle}
                            <EditIcon
                                className={classes.editIcon}
                                onClick={() => {
                                    this.titleEditClicked('titleEdit_' + i)
                                }}></EditIcon>
                        </div>}
                        {this.state['titleEdit_' + i] && <TextField
                            name={'title_' + i}
                            className={classnames(classes.textField, classes.alignCenter)}
                            autoFocus={true}
                            value={title
                                ? title
                                : undefined}
                            onBlur={() => {
                                this.titleEditClicked('titleEdit_' + i)
                            }}
                            onChange={this.ticketTitleChanged('title')}
                            margin="normal"
                            inputProps={{
                                'aria-label': 'bare'
                            }} />}
                    </div>
                    <div className={classes.clear}></div>
                    <div
                        className={classnames(classes.subticketHeading, classes.subpadding, classes.floatLeft)}>
                        {!this.state['subTitleEdit_' + i] && <div className={classes.headingTick}>
                            {this.props.ticketImages.length > 0 && find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1)) && find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1)).moreInfo
                                ? find(this.props.ticketImages, (o) => (o.ticketNumber == i + 1)).moreInfo
                                : defaultSubTitle}

                            <EditIcon
                                className={classes.editIcon}
                                onClick={() => {
                                    this.titleEditClicked('subTitleEdit_' + i)
                                }}></EditIcon>
                        </div>}
                        {this.state['subTitleEdit_' + i] && <TextField
                            name={'subTitle_' + i}
                            className={classnames(classes.textField, classes.alignCenter)}
                            autoFocus={true}
                            placeholder={'SEAT XYZ ROW ' + i}
                            value={subTitle
                                ? subTitle
                                : undefined}
                            onBlur={() => {
                                this.titleEditClicked('subTitleEdit_' + i)
                            }}
                            onChange={this.ticketTitleChanged('sub')}
                            margin="normal"
                            inputProps={{
                                'aria-label': 'bare'
                            }} />}
                    </div>
                    <div className={classes.floatRight}>

                        <span className={classes.floatLeft}>
                            <Upload
                                id={upId}
                                index={i + 1}
                                onImageUploadCallback={this.imageSelected}
                                ticketImage=
                                {find(ticketImages, (o) => (o.ticketNumber == (i + 1)))}></Upload>
                        </span>

                    </div>
                    <div className={classes.clear}></div>
                </div>
            )
        }
        return (
            <Dialog fullScreen open={open} onClose={this.back}>
                <div className={classes.containerForUpload}>
                    <div className={classes.heading}>Upload Tickets</div>
                    <div className={classes.fixedDiv}>
                        {items}</div>

                    <div className={classes.backBtnDiv}>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.buttonBack}
                            onClick={this.back}>
                            Done
                        </Button>
                    </div>
                </div>
            </Dialog>

        )

    }
}

const mapStateToProps = (state) => {
    return { ticketImages: state.ticketsUpload.ticketImages };
}

const mapDispatchToProps = (dispatch) => {

    return {
        saveSelectedTicketImages: (imagesToSave) => dispatch(saveSelectedTicketImages(imagesToSave))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(uploadTickets));