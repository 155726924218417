import AppContent from './AppContent';
import BottomNavigation from './BottomNavigation';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import NavItems from './NavItems';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../../assets/images/Logo.svg'
import { Link } from 'react-router-dom';

const drawerWidth = 190;

const styles = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: theme.spacing.unit * 3,
    background: 'azure'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  toolbar: theme.mixins.toolbar,
  versionTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    color: 'grey',
    width: '100%',
    fontSize: '0.75em',
    padding: theme.spacing.unit * 3,

  },
  allcalLogoDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

});
// You should not have written connect for UI comp - but u can ! 
function ClippedDrawer(props) {
  const { classes, isLoginTokenValid } = props;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden mdDown>

        {isLoginTokenValid ? (<Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >

          <div className={classnames(classes.toolbar, classes.allcalLogoDiv)} ><Link to="/create"><img height="60px" src={Logo}></img></Link> </div>

          <NavItems />

          <div className={classes.versionTextContainer}></div>

        </Drawer>) : (<div></div>)}

      </Hidden>

      <AppContent></AppContent>

      <Hidden lgUp>
        {isLoginTokenValid ? (<BottomNavigation />) : (<div></div>)}

      </Hidden>
    </div>
  );
}

ClippedDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(ClippedDrawer);
