import Button from "@material-ui/core/Button";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles, useTheme } from '@material-ui/core/styles'
import FitWidthButton from '../ui/buttons/fitWidthButton'


const styles = theme => ({
    root: {
        width: '100%',
        background: '#DEE0E5'
    },
    input: {
        padding: theme.spacing(0) + 'px' + ' ' + theme.spacing(1) + 'px',
        color: 'black'
    },
    paddingZero: {
        padding: theme.spacing(0) + 'px'
    }

})
function CopyLink({ onCopy, shareUrl, text, classes, bgColor, textColor, disabled }) {

    const theme = useTheme()

    return (
        <TextField
            id="standard-name"
            value={shareUrl}
            disabled
            variant="outlined"
            className={classes.root}
            InputProps={{
                classes: { input: classes.input, adornedEnd: classes.paddingZero },

                endAdornment: (
                    <CopyToClipboard text={shareUrl}
                        onCopy={onCopy}>
                        <FitWidthButton disabled={disabled} variant='outlined' bgColor={bgColor ? bgColor : theme.palette.primary.main} textColor={textColor ? textColor : theme.palette.primary.contrastText}> {text}</FitWidthButton>
                    </CopyToClipboard>

                )
            }}
        />
    );
}

export default withStyles(styles)(CopyLink)
