import React, { useEffect, useState } from 'react'

import useEventInfo from '../../hooks/useEventInfo'
import { useDispatch } from 'react-redux'
import { addToDictionary } from '../../store/actions/manageTicketsActions'
function EventInfo(props) {
    //const [ isLoading, setIsLoading ] = useState(false)
    // setIsLoading(true)
    const dispatch = useDispatch()
    const event = useEventInfo(props.calendarId, props.eventId)
    //setIsLoading(false)
    //console.log(event);
    //if (event)
    //dispatch(addToDictionary(event))

    return event ? event.name : null

}

export default EventInfo
