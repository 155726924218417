import React, { Component } from 'react'

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(0),
    },
})

export class filterSummary extends Component {
    state = {

    }

    handleFilterChange = postingId => e => {
        //console.log(e.target.checked);
        //console.log(e.target.name);
        this.props.handleChange(postingId, e.target.checked, e.target.name);

        this.setState({
            [postingId]: e.target.checked
        })

    }
    render() {
        const { open, handleClose, classes, fullWidth, maxWidth, ticketPostings, passedOnStateWithSelectedPostings } = this.props;
        //console.log(ticketPostings);
        return (
            ticketPostings && ticketPostings.length > 0 ? <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">Filter </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Select postings to filter
                </DialogContentText>
                        <div className={classes.root} >
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    {
                                        ticketPostings && ticketPostings.length > 0 && ticketPostings.map((posting, index) => {
                                            return <FormControlLabel key={index}
                                                control={<Checkbox color="primary" checked={(passedOnStateWithSelectedPostings[posting.posting] && passedOnStateWithSelectedPostings[posting.posting].selected)} onChange={this.handleFilterChange(posting.posting)} value={posting.postingId} />}
                                                label={posting.title} name={posting.title}
                                            />
                                        })
                                    }

                                </FormGroup>

                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment> : <div></div>
        )
    }
}

export default withStyles(styles)(filterSummary)
