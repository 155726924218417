

import { constants } from '../App.constants';
const axios = require('axios');
export async function fetchEventsData(reqPayload) {

    let header = {
        headers: {
            'Content-Type': 'application/json',
            'login-token': localStorage.getItem('loginToken'),
            'access-token': '3207498533.1677ed0.6fe195488f934a3f9fc2bd1053ea8356'
        }
    }
    let payload = {};
    for (var i = 0; i < reqPayload.length; i++) {
        payload[reqPayload[i].index] = await axios.get(constants.apiUrl + "/events/" + reqPayload[i].eventId, header);
    }


    return payload
}