import authenticationReducers from './authenticationReducers';
import { combineReducers } from 'redux'
import ticketPostingsReducer from './ticketPostingsReducer'
import ticketsReportReducer from './ticketsReportReducer';
import ticketsUploadReducer from './ticketsUploadReducer'
import EventInfoReducer from './EventInfoReducer'
import { default as RsvpsReducer } from '../../sagas/store/reducers/rsvp.reducer'

const rootReducer = combineReducers({

    ticketPostings: ticketPostingsReducer,
    authentication: authenticationReducers,
    ticketsUpload: ticketsUploadReducer,
    ticketsReport: ticketsReportReducer,
    eventDictionary: EventInfoReducer,
    rsvps: RsvpsReducer
});

export default rootReducer
