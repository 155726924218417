export enum actionTypes {
    CHECK_AUTHENTICATION = 'CHECK_AUTHENTICATION',
    POST_TICKETS = 'POST_TICKETS',
    GET_TICKET_POSTINGS = 'GET_TICKET_POSTINGS',
    EDIT_TICKETS = 'EDIT_TICKETS',
    STRIPE_CONNECTED = 'STRIPE_CONNECTED',
    BROADCAST_SELLER_CONNECT = 'BROADCAST_SELLER_CONNECT',
    GET_TICKETS_INFO = 'GET_TICKETS_INFO',
    CHECK_IN_OUT = 'CHECK_IN_OUT',
    SEND_TICKET = 'SEND_TICKET',
    MAKE_LIVE_NOT_LIVE = 'MAKE_LIVE_NOT_LIVE',
    SAVE_IMAGES = 'SAVE_IMAGES',
    GET_IMAGES = 'GET_IMAGES',
    GET_TICKET_IMAGES_FROM_DB = 'GET_TICKET_IMAGES_FROM_DB',
    CREATE_NEW_CALENDAR = 'CREATE_NEW_CALENDAR',
    CREATE_NEW_EVENT = 'CREATE_NEW_EVENT',
    GET_MY_CALENDARS = 'GET_MY_CALENDARS',
    CHECK_AUTHENTICATION_TOKEN = 'CHECK_AUTHENTICATION_TOKEN',
    GET_CAL_INFO_STRIPE_STATUS = 'GET_CAL_INFO_STRIPE_STATUS',
    CLEAR_CAL_INFO = 'CLEAR_CAL_INFO',
    GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS',
    SIGN_IN = 'SIGN_IN',
    SIGN_OUT = 'SIGN_OUT',
    GET_ALL_TICKET_POSTINGS = 'GET_ALL_TICKET_POSTINGS',
    ADD_TO_EVENTINFO_DIC = 'ADD_TO_EVENTINFO_DIC',
    RESET_POSTINGS_STATE = 'RESET_POSTINGS_STATE',
    CLEAR_IMAGES = 'CLEAR_IMAGES',
    WAITLIST = 'WAITLIST',
    DYNAMIC = 'DYNAMIC'
}

export enum googleTrackingCategory {
    REPORTS_SECTION = "REPORTS_SECTION",
    CREATE_TICKET_SECTION = "CREATE_TICKET_SECTION",
    EDIT_TICKET = "EDIT_TICKET",
    SETTINGS_SECTION = "SETTINGS_SECTION",
    SHARE_SECTION = "SHARE_SECTION",
    SIGN_IN_SECTION = "SIGN_IN_SECTION"
}

export enum googleTrackingLabel {
    CURRENT_VERSION = "ver 1.0"
}

export enum appStoreLinks {
    ANDROID_TICKET_VALIDATOR_URL = 'https://play.google.com/store/apps/details?id=com.ticketsvalidator',
    IOS_TICKET_VALIDATOR_URL = 'https://apps.apple.com/us/app/allcal-tickets-validation/id1239699793'
}

