import React, { Fragment } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',

    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    },
    filterIcon: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    filterIconSelected: {
        color: 'green',
        cursor: 'pointer',
        fontWeight: 800
    },
}));

export default function SearchBar(props) {
    const classes = useStyles();

    const { open, isFilterSelected, handleClose, onFilterClick, ticketPostings, handleChange, passedOnStateWithSelectedPostings, handleFilterTextChange, searchClickFn } = props;


    function searchClick(e) {
        // console.log("In here")
        e.preventDefault();
        searchClickFn();
    }
    function handleSearchText(e) {
        // console.log("In here")
        e.preventDefault();
        handleFilterTextChange(e.target.value);
    }
    return (<div>
        {/* <FilterSummary fullWidth={true} maxWidth={'md'} open={open} handleClose={handleClose} ticketPostings={ticketPostings} handleChange={handleChange} passedOnStateWithSelectedPostings={passedOnStateWithSelectedPostings}></FilterSummary> */}

        <Paper component="form" className={classes.root} onSubmit={searchClick} >

            <InputBase
                className={classes.input}
                placeholder="Type to search tickets"
                onChange={handleSearchText}
                inputProps={{
                    'aria-label': 'search'


                }} />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <SearchIcon />
            </IconButton>
            { /*<Divider className={classes.divider} orientation="vertical"/>
            <IconButton onClick={onFilterClick} color="primary" className={classes.iconButton} aria-label="filter">
                <FilterListIcon
                    className={!isFilterSelected
                    ? classes.filterIcon
                    : classes.filterIconSelected}
                    ></FilterListIcon>
                    </IconButton> */}
        </Paper>
    </div>

    );
}