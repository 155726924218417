import { findIndex, reduce, sortBy } from 'lodash';

import { actionTypes } from "../types";

const initState = {
    postings: [],
    modified: false,
    created: false,
    meta: {}
};

const ticketPostingsReducer = (state = initState, action) => {

    switch (action.type) {

        case actionTypes.POST_TICKETS:
            {
                if (state.postings.length > 0) {
                    let newState = {
                        ...state,
                        created: true,
                        postings: [
                            {
                                ...action.payload.posting,
                                isModified: true
                            },

                            ...state.postings
                        ]
                    };
                    return newState;

                }

            }

        case actionTypes.MAKE_LIVE_NOT_LIVE:
            {

                let filteredPostingIndexToUpdate = findIndex(state.postings, o => o.posting === action.payload.postingId);
                if (filteredPostingIndexToUpdate >= 0) {
                    let newPostingsArr = [
                        ...state
                            .postings
                            .slice(0, filteredPostingIndexToUpdate), {
                            ...state.postings[filteredPostingIndexToUpdate],
                            isPostingLive: action.payload.isPostingLive
                        },
                        ...state
                            .postings
                            .slice(filteredPostingIndexToUpdate + 1)
                    ]

                    let newState = {
                        ...state,
                        postings: newPostingsArr
                    }
                    return newState;
                }

            }

        case actionTypes.GET_TICKET_POSTINGS:
            {
                let sortedPosting = sortBy(action.payload.postings, 'postingTime').reverse();
                let meta = reduce(action.payload.postingsForReport, (accumulator, posting, key) => {
                    let tempNoOfTicksIssued = parseFloat(posting.noOfTicketsIssued);
                    let tempTicketsRemaining = parseFloat(posting.ticketsRemaining);
                    accumulator['noOfTicketsIssued'] = accumulator['noOfTicketsIssued'] + tempNoOfTicksIssued;
                    accumulator['ticketsRemaining'] = accumulator['ticketsRemaining'] + tempTicketsRemaining;

                    return accumulator
                }, {
                    noOfTicketsIssued: 0,
                    ticketsRemaining: 0
                });

                let newState = {

                    postings: [...sortedPosting],
                    meta: {
                        totalTickets: meta.noOfTicketsIssued,
                        totalRemaining: meta.ticketsRemaining
                    }
                }

                return newState;
            }
        case actionTypes.WAITLIST:
        case actionTypes.DYNAMIC:
        case actionTypes.EDIT_TICKETS:
            {

                let filteredPostingIndexToUpdate = findIndex(state.postings, o => o.posting === action.payload.data.posting);
                if (filteredPostingIndexToUpdate >= 0) {
                    let newPostingsArr = [
                        ...state
                            .postings
                            .slice(0, filteredPostingIndexToUpdate), {
                            isModified: true,
                            ...action.payload.data
                        },
                        ...state
                            .postings
                            .slice(filteredPostingIndexToUpdate + 1)
                    ]

                    let newState = {
                        ...state,
                        modified: true,
                        postings: newPostingsArr
                    }
                    return newState;
                }

            }
        case actionTypes.RESET_POSTINGS_STATE: {
            return {
                postings: [],
                modified: false,
                created: false,
                meta: {}
            }
        }
        case actionTypes.GET_ALL_TICKET_POSTINGS:
            {
                let sortedPosting = sortBy(action.payload.postings, 'postingTime').reverse();
                let meta = reduce(action.payload.postingsForReport, (accumulator, posting, key) => {
                    let tempNoOfTicksIssued = parseFloat(posting.noOfTicketsIssued);
                    let tempTicketsRemaining = parseFloat(posting.ticketsRemaining);
                    accumulator['noOfTicketsIssued'] = accumulator['noOfTicketsIssued'] + tempNoOfTicksIssued;
                    accumulator['ticketsRemaining'] = accumulator['ticketsRemaining'] + tempTicketsRemaining;

                    return accumulator
                }, {
                    noOfTicketsIssued: 0,
                    ticketsRemaining: 0
                });

                let newState = {

                    postings: [...sortedPosting],
                    meta: {
                        totalTickets: meta.noOfTicketsIssued,
                        totalRemaining: meta.ticketsRemaining
                    }

                }

                return newState;
            }

        default:
            {
                return state;
            }
    }

}

export default ticketPostingsReducer
