
import { actionTypes } from "../types";
const initState = {
};

const EventInfoReducer = (state = initState, action) => {

    if (action.type === actionTypes.ADD_TO_EVENTINFO_DIC) {

        if (action.payload.eventId) {
            let newState = {
                ...state,
                [action.payload.eventId]: { ...action.payload }
            }
            return newState;
        }

    } else {
        return state;
    }
}

export default EventInfoReducer