import React, { Fragment } from 'react'
import {
  googleTrackingCategory,
  googleTrackingLabel
} from '../../store/types'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Check from '@material-ui/icons/Check';
import Collapse from '@material-ui/core/Collapse';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Event } from '../Tracking';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HelpOutline from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import ShareIcon from '@material-ui/icons/Share';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import clsx from 'clsx';
import { flexbox } from '@material-ui/system';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({

  card: {
    width: '100%',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    marginBottom: theme.spacing(1)
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    borderRadius: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.3)
  },

  avatarWithName: {
    display: 'flex',

  },
  green: {
    color: 'seagreen'
  },
  status: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1)
  },
  statusTextSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  statusHeading: {
    fontWeight: 400,
    color: 'grey',
    fontSize: theme.spacing(1.5)
  },
  statusText: {
    fontSize: theme.spacing(1.75)
  },

  modRoot: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(0)
  },
  action: {
    alignSelf: 'center'
  },
  btnMoreSection: {
    display: 'flex',
    justifyContent: 'space-between'

  },
  timeDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: theme.spacing(1.5),
    color: 'rgba(0, 0, 0, 0.54)',
    padding: theme.spacing(1)
  },
  emailTicketDiv: {
    display: 'flex'

  },
  emailHelpIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(1.5)
  },
  typography: {
    padding: theme.spacing(2),
    fontSize: theme.spacing(1.75)
  },
  successCheckDiv: {
    display: 'flex',
    alignItems: 'center',
    color: 'seagreen'
  },
  errIcon: {
    paddingRight: theme.spacing(0.3)
  },
  errCheckDiv: {
    display: 'flex',
    alignItems: 'center',
    color: 'indianred'
  },
  smallIcon: {
    fontSize: theme.spacing(2),
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.main
  },
  bold: {
    fontWeight: 600
  },
  sentText: {
    fontSize: theme.spacing(1.5)
  },
  stampFailDiv: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.spacing(1.5),
    color: 'indianred',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    cursor: 'pointer'
  }
});

function TicketCard(props) {
  const { classes, ticketNumber, name, postingName, postingTime, event, calendar, posting, onStampClick, ticketId, statusText, isStamped, onEmailSendClick, email, phone } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [emailSentClick, setEmailSentClicked] = React.useState(false);
  const [emailSentErr, setEmailSentErr] = React.useState(false);

  const [stampErr, setStampErr] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleStampClick = () => {
    onStampClick(ticketId, event, calendar, 'checkin', (err, msg) => {
      if (err) {
        Event(googleTrackingCategory.MORE_SECTION, "Stamping Error - Check In", 'posting_' + posting);
        setStampErr(true)
      }
    })
  }

  const handleUnStampClick = () => {
    onStampClick(ticketId, event, calendar, 'checkout', (err, msg) => {
      if (err) {
        Event(googleTrackingCategory.MORE_SECTION, "Stamping Error - Check Out", 'posting_' + posting);

        setStampErr(true)
      }
    })
  }
  const handleEmailClick = () => {

    setEmailSentClicked(true);

    onEmailSendClick(ticketId, event, calendar, email, posting, (isError, msg) => {
      //console.log("In here withing comp")
      if (isError) {
        Event(googleTrackingCategory.MORE_SECTION, "Error Sending Email", 'posting_' + posting);

        setEmailSentErr(true);
        setEmailSentClicked(false);
      } else {

        setEmailSent(true);

      }


    })
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElNoPerm, setAnchorElNoPerm] = React.useState(null);

  const handleClickNoPerm = event => {
    setAnchorElNoPerm(event.currentTarget);

  };

  const handleCloseNoPerm = () => {
    setAnchorElNoPerm(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openNoPerm = Boolean(anchorElNoPerm);
  const idNoPerm = openNoPerm ? 'simple-popover-no-perm' : undefined;


  return (
    <Card className={classes.card}>
      <CardHeader
        classes={{ root: classes.modRoot, action: classes.action }}
        avatar={
          <div className={classes.avatarWithName}>
            <Avatar aria-label="recipe" className={classes.avatar}>
              # {ticketNumber}
            </Avatar>
          </div>

        }
        action={

          <div className={classes.status}>
            <div className={classes.statusTextSection}><div className={classes.statusHeading}>Status</div><div className={classnames(classes.statusText, statusText == 'Stamped' && classes.green)}>{statusText}</div></div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>

        }
        title={<div style={{ marginBottom: '4px' }}><span>{name}</span> <br></br><span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{email}</span><br></br><span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{phone}</span></div>}
        subheader={postingName}
      />
      <div className={classes.timeDiv}>{postingTime}</div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent classes={{ root: classes.btnMoreSection }}>

          <div className={classes.emailTicketDiv}>
            <Button color="primary" disabled={emailSentClick} className={classes.button} onClick={handleEmailClick}>
              EMAIL TICKET
            </Button>
            {!emailSentClick && !emailSentErr && <HelpOutline className={classes.emailHelpIcon} onClick={handleClick}></HelpOutline>}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography className={classes.typography}>User already would have received confirmation email with ticket QR during purchase. This option <span className={classes.bold}>re-send's</span> the ticket to the user. </Typography>
            </Popover>
            <div className={classes.successCheckDiv}>
              {emailSent && <Fragment><Check></Check> <span className={classes.sentText}> Sent</span> </Fragment>}
            </div>
            <div className={classes.errCheckDiv}>
              {emailSentErr && <Fragment><ErrorOutlineIcon className={classes.errIcon}></ErrorOutlineIcon> <span className={classes.sentText}> Not Sent</span> </Fragment>}
            </div>
          </div>

          {!isStamped &&
            <div>
              <Button color="primary" className={classes.button} onClick={handleStampClick}>
                STAMP TICKET
            </Button>
              {stampErr && <Fragment>

                <div className={classes.stampFailDiv} onClick={handleClickNoPerm}>No Permission <HelpOutline className={classes.smallIcon} ></HelpOutline></div>
                <Popover
                  id={idNoPerm}
                  open={openNoPerm}
                  anchorEl={anchorElNoPerm}
                  onClose={handleCloseNoPerm}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className={classes.typography}> Only an <span className={classes.bold}>event owner (or) user with EDIT permission for the calendar</span> can stamp/unstamp a ticket. Please contact us in <span className={classes.bold}>allcal help thread</span> if you have any questions. Thanks! </Typography>
                </Popover>


              </Fragment>}

            </div>

          }
          {isStamped && <Button color="primary" className={classes.button} onClick={handleUnStampClick}>
            UNSTAMP TICKET
          </Button>}
          {/*<Button color="primary" className={classes.button}>
                                  REVOKE TICKET
          </Button> */ }
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default withStyles(styles)(TicketCard)
