import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import styled, { keyframes } from 'styled-components';
import { withStyles, withTheme } from '@material-ui/core/styles';

import AnimatedProgressProvider from "./AnimatedProgressProvider";
import React from 'react'
import { easeQuadInOut } from 'd3-ease';
import { fadeInLeft } from 'react-animations';

const bounceAnimation = keyframes`${fadeInLeft}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;
const styles = theme => ({
    progressDiv:{
        padding : theme.spacing(4),
        marginTop : '100px',
        minWidth : '320px'
    },
    themeColor : {
        color : theme.palette.primary.main
    }
})

function processingScreen(props) {
    const {classes, theme} = props;
    return (
        <div className={classes.progressDiv}>
        <AnimatedProgressProvider
            valueStart={props.valueStart}
            valueEnd={props.valueEnd}
            duration={2}
            easingFunction={easeQuadInOut}
            >
            {(value) => {
                const roundedValue = Math.round(value);
                return (
                    <CircularProgressbarWithChildren value={roundedValue} styles={buildStyles({ pathColor : roundedValue > 80 ? 'green' : theme.palette.primary.main })}>
                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                    <BouncyDiv><img
                    alt="loaderIconImg"
                    style={{ width: 80, marginTop: -5 }}
                    src= {props.imgLink}
                    /></BouncyDiv>
                    <div style={{ fontSize: 32, marginTop: -5, color : roundedValue > 70 ? 'green' : '' }}>
                    {roundedValue} %

                    </div>
                </CircularProgressbarWithChildren>
                
                );
            }}
            </AnimatedProgressProvider>
        </div>
    )
}

export default withTheme(withStyles(styles)(processingScreen))
