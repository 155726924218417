import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Hoc from '../../hoc/protectedRoute'
import PropTypes from 'prop-types';
import createTickets from '../../createTickets'
import manageTickets from '../../manageTickets'
import salesSummary from '../../salesSummary';
import settings from '../../settings';
import share from '../../share';
import signIn from '../../signIn'
import successPage from '../../ui/successPage'
import autoLogin from '../../autoLogin'
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%',

  },
  content: {
    display: 'flex',
    [theme.breakpoints.up(780)]: {
      width: '90%'
    },
    marginTop: theme.spacing(2)
  },
  contentSignIn: {
    display: 'flex',
    marginTop: theme.spacing(7),
    [theme.breakpoints.up(780)]: {
      width: '100%'
    }
  },
  toolbar: theme.mixins.toolbar
});

class AppContent extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}  >
        <main className={this.props.location.pathname.indexOf('signIn') != -1 ? classes.contentSignIn : classes.content}>
          <div className={classes.toolbar} />
          {<Switch>
            <Route exact path="/" component={Hoc(createTickets)} />
            <Route path="/create" component={Hoc(createTickets)} />
            <Route path="/manageTickets" component={Hoc(manageTickets)} />
            <Route path="/analytics" component={Hoc(salesSummary)} />
            <Route path="/settings" component={Hoc(settings)} />
            <Route path="/share" component={Hoc(share)} />
            <Route path="/signIn" component={signIn} />
            <Route path="/success/:postingId" component={Hoc(successPage)} />
            <Route path="/auto-login/:loginToken" component={autoLogin} />

            {/*<Route path="/Shifts/:id" component={HocAuth(Shifts)} />
                    <Route path="/Shifts" component={HocAuth(ShiftsList)} />
                    <Route path="/workers" component={HocAuth(ShiftWorkers)} />
                    <Route path="/createShift" component={HocAuth(createShift)}/>
                    <Route path="/addWorker" component={HocAuth(addWorker)}/>
                    <Route path="/Analytics" component={HocAuth(reports)} />
                    <Route path="/Activity" component={HocAuth(activity)} />
                    <Route path="/Checkin-Reports" component={HocAuth(checkInReports)} />
                    <Route path="/Worker-Stats" component={HocAuth(workerStats)} />
                    <Route path="/Settings" component={HocAuth(settings)} />
                    <Route path="/SignIn" component={signIn} /> */ }


          </Switch>}


        </main>
      </div>
    );

  }


}

AppContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(AppContent));

