import {
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import React, { PureComponent } from 'react';

import Dot from './dot';

export default class lineChart extends PureComponent {
  handleClick = (e) => {
    //e.preventDefault();
    //console.log('Prevented');

  }
  hover = (e) => {
    //console.log("in here")
  }
  resetActive = (e) => {

  }
  mouseOver = (e) => {

  }
  render() {
    const { data, dataKey, xAxisDataKey } = this.props;
    //console.log(data);
    const c = 'white';
    const site = 'linechart';
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          style={{ background: 'transparent' }}
          data={data}
          margin={{
            top: 10, right: 5, left: 5, bottom: 5,
          }}
        >
          <XAxis tick={{ stroke: 'white' }} dataKey={xAxisDataKey} padding={{ left: 20, right: 20 }} style={{ 'fontSize': '14px', 'fontWeight': '400' }}></XAxis>
          <YAxis allowDecimals={false} tick={{ stroke: 'white' }} width={35} orientation="left" style={{ 'fontSize': '14px', 'fontWeight': '400' }} />
          <Tooltip labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} />
          <Line type="linear" dataKey={dataKey} stroke="white" activeDot={false} dot={
            <Dot
              fill={c}
              id={site}
              onClick={this.hover}
              onMouseOver={this.hover}
              onMouseOut={this.resetActive}
            />
          }> </Line>

        </LineChart>
      </ResponsiveContainer>
    );
  }
}