import {
  findIndex,
  forEach,
  groupBy,
  map,
  reduce,
  sortBy
} from 'lodash';

import {
  actionTypes
} from "../types";
import moment from 'moment-timezone';

const initState = {
  ticketsInfo: [],
  weeklySales : {meta:{}, data :[]},
  summary : {}
};

const ticketsReportReducer = (state = initState, action) => {

  switch (action.type) {
    case actionTypes.CHECK_IN_OUT :{

      let filteredTicketIndexToUpdate = findIndex(state.ticketsInfo, o => o._id === action.payload.ticketId);
      if(filteredTicketIndexToUpdate >= 0){
        let newTicketsInfoArr = [
          ...state.ticketsInfo.slice(0, filteredTicketIndexToUpdate),
          {
            ...state.ticketsInfo[filteredTicketIndexToUpdate],
            isStamped: action.payload.isStamped
          },
          ...state.ticketsInfo.slice(filteredTicketIndexToUpdate + 1)
        ]

        let newState = {
          ...state,
          ticketsInfo: newTicketsInfoArr
        }
        return newState;
      }

    }
    case actionTypes.GET_TICKETS_INFO: {
      let sortedTickets = sortBy(action.payload.ticketsInfo, 'createdTime').reverse();
      let mappedDateObj = map(action.payload.ticketsInfo, (ticket) => {
        ticket.dateText = moment(ticket.createdTime).format('l');
        return ticket;
      });
      let groupedByDate = groupBy(mappedDateObj, 'dateText');
    
      let grouped7DaySales = {};
      let diffDates = moment(moment(action.payload.to).format('YYYY/MM/DD')).diff(moment(moment(action.payload.from).format('YYYY/MM/DD')), 'days');
      
      for (var l = (diffDates-1) ; l >= 0; l--) {

        grouped7DaySales[(moment(new Date()).subtract(l, 'days').format('l'))] = {};
        grouped7DaySales[(moment(new Date()).subtract(l, 'days').format('l'))].name = (moment(new Date()).subtract(l, 'days').format('l'));
        grouped7DaySales[(moment(new Date()).subtract(l, 'days').format('l'))].amount = 0;
        grouped7DaySales[(moment(new Date()).subtract(l, 'days').format('l'))].count = 0;
        
        
      }

      //console.log(groupedByDate);
      let totalSales = 0;
      let totalCount = 0;
      let totalStamped = 0;
      let weeklyTotalSales = 0;
      let weeklyTotalCount = 0;
      forEach(groupedByDate, (ticketsInThatDate, key) => {

        if (grouped7DaySales[key] != undefined && grouped7DaySales[key].amount != undefined) {
          grouped7DaySales[key].amount = reduce(ticketsInThatDate, (result, ticket, date) => {

            return result = result + parseFloat(ticket.amount)
          }, 0)

          
          totalSales = totalSales + grouped7DaySales[key].amount;
          weeklyTotalSales = weeklyTotalSales + grouped7DaySales[key].amount;
        }else{
          totalSales = totalSales + reduce(ticketsInThatDate, (result, ticket, date) => {

            return result = result + parseFloat(ticket.amount)
          }, 0)


        }
        totalStamped = totalStamped +  reduce(ticketsInThatDate, (result, ticket, date) => {
          if(ticket.isStamped){
            return result = result + 1
          }else{
            return result
          }
          
        }, 0)

        if (grouped7DaySales[key] != undefined && grouped7DaySales[key].count != undefined) {
          grouped7DaySales[key].count = ticketsInThatDate.length;
          weeklyTotalCount = weeklyTotalCount + ticketsInThatDate.length;
        }
        totalCount = totalCount + ticketsInThatDate.length;
      });
      let weeklySalesArr = [];
      map(grouped7DaySales, (val, key)=>{
        if(val && val.amount > 0){
          val.amount = parseFloat(val.amount.toFixed(2))
        
        }
        weeklySalesArr.push(val); 
      })

      let newState = {
        ticketsInfo: sortedTickets,
        weeklySales : { meta : {totalCount : weeklyTotalCount, totalSales : weeklyTotalSales.toFixed(2)}, data : weeklySalesArr},
        summary : {totalCount : totalCount, totalSales : totalSales.toFixed(2), totalStamped : totalStamped}
      }

      //console.log(weeklySalesArr);

      return newState;
    }

    default: {
      return state;
    }
  }

}



export default ticketsReportReducer
