import MUIDataTable from 'mui-datatables'
import React from 'react'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  noFlex : {
    flexWrap : 'wrap',
    minWidth : '70%'
  },
  responsiveScroll : {
    height : 'unset'
  }
})
function table(props) {

  const {data, title, options, columns, classes} = props;
  
  return (

    <MUIDataTable
        className={classes.noFlex}
        classes = {{
          responsiveScroll : classes.responsiveScroll
        }}
        columns={columns}
        data={data}
        title={title}
        options={options}
        />         
  )
}

export default withStyles(styles)(table)
