import { actionTypes } from '../types'

const initState = {};

const authenticationReducers = (state = initState, action) => {

    switch (action.type) {
        case actionTypes.SIGN_IN:
            {
                let newState;
                newState = {
                    ...state,
                    isLoginTokenValid: action.payload.isLoginTokenValid,
                    currentUser: action.payload.currentUser,
                    stripeConnected: action.payload.stripeConnected

                }
                return newState;
            }
        case actionTypes.SIGN_OUT:
            {
                let newState;
                newState = {
                    ...state,
                    isLoginTokenValid: action.payload.isLoginTokenValid,
                    currentUser: action.payload.currentUser,
                    stripeConnected: false,
                    ownerStripeConnected: false


                }
                return newState;
            }
        case actionTypes.CHECK_AUTHENTICATION_TOKEN:
            {
                let newState;
                newState = {
                    ...state,
                    isLoginTokenValid: action.payload.isLoginTokenValid,
                    currentUser: action.payload.currentUser,
                    stripeConnected: action.payload.stripeConnected

                }
                //console.log(newState)
                return newState;
            }
        case actionTypes.GET_CAL_INFO_STRIPE_STATUS:
            {
                let newState;
                newState = {
                    ...state,
                    ownerStripeConnected: action.payload.ownerStripeConnected,
                    calendarOwner: action.payload.calendarOwner,
                    visibility: action.payload.visibility,
                    permission: action.payload.permission
                }
                return newState;
            }

        case actionTypes.STRIPE_CONNECTED:
            {
                let newState = {
                    ...state,
                    stripeConnected: action.payload.stripeConnected,
                    connectedNow: action.payload.stripeConnected
                }
                return newState
            }
        default:
            {
                return state
            }
    }

}

export default authenticationReducers
