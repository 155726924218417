import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { getAllTicketPostings } from '../store/actions/manageTicketsActions'
import moment from 'moment-timezone'
import queryString from 'query-string'
import FilterSummary from './filterSummary'
import DateCard from './ui/cards/dateCard'
import Edit from '@material-ui/icons/Edit'
import DateRangePicker from './ui/pickers/dateRangePicker'
import SharePosting from './ui/sharePosting'
import { groupBy, uniq } from 'lodash'
import EventInfo from './ui/eventInfo'
import { Paper, Hidden } from '@material-ui/core'
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarIcon from '@material-ui/icons/Star';
import FullWidthDialog from './ui/dialogs/fullWidthDialog'
import { Event, PageView, initGA } from './Tracking';
import { googleTrackingCategory, googleTrackingLabel } from '../store/types'

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            width: '80%',
        },
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            width: '100%',
            padding: theme.spacing(2)
        },

    },

    listRoot: {
        width: '100%',
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            maxWidth: 480,
            height: '80vh',
            overflowY: 'auto'
        },

        backgroundColor: theme.palette.background.paper,
    },

    flexWithWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center'
    },

    flexed: {
        display: 'flex',
        alignItems: 'center'
    },

    paper: {
        padding: theme.spacing(2)
    },
    editIcon: {
        cursor: 'pointer',
        marginLeft: theme.spacing(1)
    },
    container: {
        display: 'flex',
        color: theme.palette.primary.main,
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            width: '80%',
        },
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            width: '100%',
        },

        justifyContent: 'flex-start',
        overflow: 'auto',
        alignItems: 'center',
        marginTop: theme.spacing.unit * 1,


    },
});



export class share extends Component {

    componentDidMount() {
        initGA('UA-106455531-7');
        PageView('/share');

        this.setState({
            isLoading: true
        })

        this.props.modified || this.props.ticketPostings.length == 0 || (this.props.created && !queryString
            .parse(this.props.location.search)
            .posting) ? this
                .props
                .getAllTicketPostings(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString(), undefined, (err) => {
                    this.setState({
                        isLoading: false,

                    })
                }) : this.setState({
                    isLoading: false
                });
    }

    state = {
        selectedIndex: 0,
        startDate: moment(new Date()).subtract(30, 'd'),
        endDate: moment(new Date()),
    }


    handleFilterClick = () => {
        this.setState({
            filterOpen: true
        })
    }

    handleFilterClose = () => {
        this.setState({
            filterOpen: false
        })
    }


    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleFilterDate = (filterDates) => {
        this.setState({
            filterOpen: false,
            startDate: moment(filterDates.startDate),
            endDate: moment(filterDates.endDate)
        }, () => {
            this
                .props
                .getAllTicketPostings(new Date(this.state.startDate).toISOString(), new Date(this.state.endDate).toISOString());

        })
    }
    handleListItemClick = (event, index) => {
        this.setState({
            selectedIndex: index
        })
    };

    handleListItemClickSm = (event, index) => {
        this.setState({
            selectedIndex: index,
            open: true
        })
    };

    render() {
        const { classes } = this.props;
        const eventsWithPosting = uniq(Object.keys(groupBy(this.props.ticketPostings, 'event')))
        return (

            <div className={classes.flexWithWrap}>

                {eventsWithPosting && eventsWithPosting.length > 0 && <FullWidthDialog heading={<EventInfo eventId={eventsWithPosting[this.state.selectedIndex]}></EventInfo>} open={this.state.open} handleClose={this.handleClose} componentToDisplay={<SharePosting eventId={eventsWithPosting[this.state.selectedIndex]}></SharePosting>}></FullWidthDialog>}

                <div className={classes.container}>

                    {(() => {
                        return !this.state.filterOpen && <span className={classes.spaceBtwn}>
                            <div className={classes.flexed}>
                                <DateCard
                                    text={this
                                        .state
                                        .startDate
                                        .format('MMM Do YY') + ' - ' + this
                                            .state
                                            .endDate
                                            .format('MMM Do YY')}></DateCard>
                                <Edit className={classes.editIcon} onClick={this.handleFilterClick}></Edit>

                            </div>

                        </span>

                    })()}
                    {(() => {
                        return this.state.filterOpen && <div className={classes.containerForDateFilter}>
                            <DateRangePicker
                                onFilterClose={this.handleFilterClose}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onFilterDate={this.handleFilterDate}>
                            </DateRangePicker>
                        </div>
                    })()}

                </div>
                <Grid container className={classes.root} >
                    <Grid item xs={12} sm={12} md={6} className={classes.leftPane}>

                        {eventsWithPosting && eventsWithPosting.length > 0 ? <List component="nav" className={classes.listRoot} aria-label="contacts">
                            {eventsWithPosting
                                .map((event, index) => (
                                    <Fragment>
                                        <Hidden smDown>
                                            <ListItem key={index} selected={this.state.selectedIndex === index} button onClick={(event) => this.handleListItemClick(event, index)}>
                                                <EventInfo eventId={event}></EventInfo>
                                            </ListItem>
                                        </Hidden>
                                        <Hidden mdUp>
                                            <ListItem key={index} selected={this.state.selectedIndex === index} button onClick={(event) => this.handleListItemClickSm(event, index)}>
                                                <EventInfo eventId={event}></EventInfo>
                                            </ListItem>
                                        </Hidden>
                                    </Fragment>
                                ))
                            }
                        </List>
                            : !this.state.isLoading && <div>No event with ticket exists in this date range</div>}
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={6} className={classes.rightPane}>
                            {<SharePosting eventId={eventsWithPosting[this.state.selectedIndex]}></SharePosting>}
                        </Grid>
                    </Hidden>

                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ticketPostings: state.ticketPostings.postings
    };
}
const mapDispatchToProps = (dispatch) => {

    return {
        getAllTicketPostings: (from, to, filter, callback) => dispatch(getAllTicketPostings(from, to, filter, callback)),
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(share))
