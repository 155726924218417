import React from 'react'

import styled from 'styled-components'
import SuccessIcon from '../../assets/SuccessIcon'
import ErrorIcon from '../../assets/ErrorIcon'
import InfoIcon from '../../assets/InfoIcon'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from '@material-ui/styles'
import PropTypes from 'prop-types'

const RsvpStatus = ({ status, size, color }) => {
    const theme = useTheme()
    const iconSize = size ? size : theme.spacing(2)
    const textColor = (status === 'attending' ?
        theme.palette.success.main : status === 'not attending' ? theme.palette.error.main : theme.palette.info.main)
    return (status ?
        <RsvpItem theme={theme}>
            <RsvpItemText
                theme={theme}
                color={color ? color : textColor}>
                {(status === 'attending' ? 'Going' : status === 'not attending' ? 'Not Going' : status === 'pending' ? 'Invitation Sent' : 'Pending')}
            </RsvpItemText>
            {status === 'attending' && <SuccessIcon
                width={iconSize}
                height={iconSize}
                fill={color ? color : theme.palette.success.main} />}
            {status === 'not attending' && <ErrorIcon
                width={iconSize}
                height={iconSize}
                fill={color ? color : theme.palette.error.main} />}
            {status === 'none' && <InfoIcon
                width={iconSize}
                height={iconSize}
                fill={color ? color : theme.palette.info.main} />}
        </RsvpItem> : <Skeleton width={70}></Skeleton>
    )
}

RsvpStatus.propTypes = {
    status: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string
}

const RsvpItem = styled.div`
    
        ${ ({ theme }) => `
            display : flex;
            align-items : center;
            font-size : ${theme.spacing(2)}px;
    ` };
`
const RsvpItemText = styled.div`
    
        ${ ({ theme, color }) => `
            padding-right : ${theme.spacing(1)}px;
            color : ${color};
    ` };
`


export default RsvpStatus
