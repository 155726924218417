import { dispatchErrorWrap, dispatchSuccessWrap } from './genericActions'
import { filter, map } from 'lodash'

import { actionTypes } from '../types';
import { constants } from '../../App.constants';

const axios = require('axios');

export const getTicketsReport = (calendarId, eventId, token, filterPostingsArr, callbackIfAny) => {
    // Make async call  dispatchSuccessWrap('CHANGE_ORGANIZATION', org, dispatch)

    return async (dispatch, getState) => {
        // Make async call console.log("GET STATE" + getState());
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token

                }
            }

            const payload = await axios.get(constants.ticketsBaseUrl + "/admin/tickets/calendar/" + calendarId + "/event/" + eventId, headers);

            if (!filterPostingsArr || filterPostingsArr.length == 0) {
                dispatchSuccessWrap(actionTypes.GET_TICKETS_INFO, {
                    'ticketsInfo': payload.data.ticketsInfo
                }, dispatch);

            } else {
                let filteredTicketsInfo = filter(payload.data.ticketsInfo, function (o) {
                    return filterPostingsArr.includes(o.posting);
                });
                dispatchSuccessWrap(actionTypes.GET_TICKETS_INFO, {
                    'ticketsInfo': filteredTicketsInfo
                }, dispatch);

            }

            if (callbackIfAny != undefined) {
                callbackIfAny()
            }

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }
        //dispatchSuccessWrap({type : 'ADD_WORKER', worker : worker});
    }

}

export const getAllTicketsReport = (from, to, filterPostingsArr, callbackIfAny) => {
    // Make async call  dispatchSuccessWrap('CHANGE_ORGANIZATION', org, dispatch)

    return async (dispatch, getState) => {

        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')

                }
            }
            let reqPayload = {};
            //console.log(map(getState().ticketPostings.postings, 'posting'))
            reqPayload.postings = filterPostingsArr && filterPostingsArr.length > 0 ? filterPostingsArr : map(getState().ticketPostings.postings, 'posting');

            let apiUrl = '/admin/tickets/correspond';
            if (from) {
                apiUrl = apiUrl + '?from=' + from
            }
            if (to) {
                apiUrl = apiUrl + '&to=' + to
            }

            const payload = await axios.post(constants.ticketsBaseUrl + apiUrl, reqPayload, headers);

            dispatchSuccessWrap(actionTypes.GET_TICKETS_INFO, {
                'ticketsInfo': payload.data.ticketsInfo,
                'from': from,
                'to': to
            }, dispatch);


            if (callbackIfAny != undefined) {
                callbackIfAny()
            }

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }
        //dispatchSuccessWrap({type : 'ADD_WORKER', worker : worker});
    }

}