import 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';

import { DateRangePicker } from 'react-date-range';
import OutLinedSmallBtn from '../buttons/outlinedSmallButton'
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  grid: {
    width: '100%',
  },
  marginLeft8: {
    marginRight: '8px'
  }
};

class DateRangePickerWrapper extends React.Component {

  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    key: 'selection',

  }
  handleSelect = (ranges) => {
    this.setState(ranges.selection);
    // {
    // 	selection: {
    // 		startDate: [native Date Object],
    // 		endDate: [native Date Object],
    // 	}
    // }
  }
  handleSaveClick = () => {
    this.props.onFilterDate(this.state);
  }
  handleDiscardClick = () => {
    this.props.onFilterClose();
  }
  render() {

    return (
      <div>
        <DateRangePicker
          ranges={[this.state]}
          onChange={this.handleSelect}
          showSelectionPreview={false}
        />
        <span className={this.props.classes.marginLeft8}><OutLinedSmallBtn btnText="Filter" btnColor='primary' variant='outlined' onClick={this.handleSaveClick} displayEdit='noIcon' displayLive='noIcon' displayDelete='noIcon' displayDiscard='noIcon' displayCopy='noIcon' displaySave='noIcon' ></OutLinedSmallBtn>
        </span><OutLinedSmallBtn btnText="Back" btnColor='warning' variant='outlined' onClick={this.handleDiscardClick} displaySave='noIcon' displayEdit='noIcon' displayDiscard='noIcon' displayLive='noIcon' displayDelete='noIcon' displayCopy='noIcon' push="right" ></OutLinedSmallBtn>

      </div>

    )
  }
}

DateRangePickerWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateRangePickerWrapper);
