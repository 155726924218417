import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    button: props => ({
        width: '100%',
        padding: theme.spacing(1.25),
        fontSize: theme.spacing(2),
        textTransform: 'capitalize',
        fontWeight: 500,
        color: props.textColor,
        backgroundColor: props.variant === 'outlined' ? 'transparent' : props.bgColor,
        border: props.variant === 'outlined' ? '2px solid ' + props.textColor : 0,
        '&:hover': {
            backgroundColor: props.variant === 'outlined' ? 'transparent' : props.bgColor,
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: props.variant === 'outlined' ? 'transparent' : props.bgColor
            },
        },
    }),
    label: props => ({
        paddingRight: props.labelGutter === false ? theme.spacing(0) : theme.spacing(2)
    })
}));

export default function RegularButton(props) {
    const { children } = props;
    const classes = useStyles(props);

    return (

        <Button
            variant={props.variant}
            color="default"
            className={classes.button}
            disabled={props.disabled}
            onClick={props.onClick}
            classes={{ startIcon: classes.label }}
            startIcon={props.icon}
        >
            {children}
        </Button>

    );
}