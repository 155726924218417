import {
    dispatchErrorWrap,
    dispatchSuccessWrap
} from './genericActions'

import {
    actionTypes
} from '../types'
import {
    constants
} from '../../App.constants';

const axios = require('axios');

export const editTicket = (token, eventId, postingId, desc, isValidationNeeded, maxClaimableTickets, noOfTicketsIssued, ticketAmount, title, callbackFn) => {
    return async (dispatch, getState) => {

        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }
            let reqPayload = {};
            reqPayload.desc = desc;
            reqPayload.isValidationNeeded = isValidationNeeded;
            reqPayload.noOfTicketsForEach = maxClaimableTickets;
            reqPayload.noOfTicketsIssued = noOfTicketsIssued;
            reqPayload.ticketAmount = ticketAmount;
            reqPayload.title = title;

            const payload = await axios.put(constants.ticketsBaseUrl + "/admin/tickets/" + eventId + '/posting/' + postingId, reqPayload, headers);
            //console.log(payload);
            payload && payload.data && !payload.data.error ? dispatchSuccessWrap(actionTypes.EDIT_TICKETS, { 'data': payload.data.data }, dispatch) : dispatchErrorWrap('Unable to update Record', dispatch);
            //await axios.get('https://httpstat.us/200?sleep=200');
            //callbackFn();
            setTimeout(callbackFn, 200);

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }

    }


}

export const changeWaitlist = (token, eventId, postingId, waitlist, callbackFn) => {
    return async (dispatch, getState) => {

        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }
            let reqPayload = {};
            reqPayload.waitlistOptions = {};
            reqPayload.waitlistOptions.waitlist = waitlist;

            const payload = await axios.put(constants.ticketsBaseUrl + "/admin/tickets/" + eventId + '/posting/' + postingId + '/waitlist', reqPayload, headers);
            //console.log(payload);
            payload && payload.data && !payload.data.error ? dispatchSuccessWrap(actionTypes.WAITLIST, { 'data': payload.data.data }, dispatch) : dispatchErrorWrap('Unable to update Record', dispatch);
            //await axios.get('https://httpstat.us/200?sleep=200');
            //callbackFn();
            setTimeout(callbackFn, 200);

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }

    }


}

export const dynamicOptions = (token, eventId, postingId, ticketText, boughtTicketText, callbackFn) => {
    return async (dispatch, getState) => {

        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }
            let reqPayload = {};
            reqPayload.ticketText = ticketText;
            reqPayload.boughtTicketText = boughtTicketText;

            const payload = await axios.put(constants.ticketsBaseUrl + "/admin/tickets/" + eventId + '/posting/' + postingId + '/dynamic', reqPayload, headers);
            //console.log(payload);
            payload && payload.data && !payload.data.error ? dispatchSuccessWrap(actionTypes.DYNAMIC, { 'data': payload.data.data }, dispatch) : dispatchErrorWrap('Unable to update Record', dispatch);
            setTimeout(callbackFn, 200);

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }

    }


}


export const makeTicketLive = (token, eventId, postingId, callbackFn) => {
    return async (dispatch, getState) => {

        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }
            let reqPayload = {};
            reqPayload.modifyType = "stop";
            reqPayload.posting = postingId;

            const payload = await axios.put(constants.ticketsBaseUrl + "/admin/tickets/" + eventId, reqPayload, headers);
            //console.log(payload);
            payload && payload.data && !payload.data.error ? dispatchSuccessWrap(actionTypes.MAKE_LIVE_NOT_LIVE, { 'postingId': payload.data.posting.posting, 'isPostingLive': payload.data.posting.isPostingLive }, dispatch) : dispatchErrorWrap('Unable to update Record', dispatch);
            //await axios.get('https://httpstat.us/200?sleep=200');
            payload && payload.data && !payload.data.error && setTimeout(callbackFn, 200);
        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }

    }


}
