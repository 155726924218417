export const constants = {
  environment: 'production',
  version: '1.0',
  apiUrl: 'https://api.allcal.com',
  dashboardBaseUrl: 'https://api.dashboard.allcal.com',
  ticketsBaseUrl: 'https://api.tickets.allcal.com',
  ticketsBaseUrlAlt: 'https://purchase.tickets.allcal.com', // has to be purchase.allcal.com for prod
  appBaseUrl: 'https://app.allcal.com',
  templatesBaseUrl: 'http://templates.allcal.com',
  templatesBaseUrlHttps: 'https://templates.allcal.com',
  localhost: 'http://localhost:1338'
};
