import Button from '@material-ui/core/Button';
import Copy  from '@material-ui/icons/FileCopy';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Discard from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit'
import Lens from '@material-ui/icons/Lens';
import PropTypes from 'prop-types';
import React from 'react';
import Save from '@material-ui/icons/Save'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
 
  live : {
    color : 'green',
    fontSize: '0.8em'
  },
  pending:{
    color: 'orange',
    fontSize: '0.8em'
  },
  label:{
    justifyContent : 'space-between',
   
  },
 
  showIcon:{
    display: 'block',
    marginRight : '5px'
  },
  iconSize:{
    fontSize: '1em'
  },
  lessWidth:{
    minWidth : '10px'
  },
  noIcon:{
    display:'none'
  },
  
  editIcon:{
    marginRight:'5px'
  },
  saveIcon:{
    marginRight:'5px'
  },
  
  right:{
    marginLeft: 'auto'
  },
  left10:{
    marginLeft: '10px'
  },
  primary:{
    border : '1px solid '+  theme.palette.primary.main,
    color : theme.palette.primary.main
  },
  error:{
    border : '1px solid '+  theme.palette.error.main,
    color : theme.palette.error.main
  },
  warning : {
    border : '1px solid '+  theme.palette.error.light,
    color : theme.palette.error.main
  }
 
});


function CustomizedButtons(props) {
  const { classes } = props;
  const {btnText} = props;
  const {onClick} = props;
  const disabledRipple = true;
  const {liveOrNot, displayCopy, displayLive,displayDelete ,displayEdit,displayDiscard, displaySave, push, margin, variant, btnColor} = props;
   return (
   
      <Button
        size = "small"
        variant= {variant}
        onClick = {onClick}
        disableRipple= {disabledRipple}
        classes = {{
                label : classes.label
              }}
        className= { classNames(classes.lessWidth, classes[push], classes[margin], classes[btnColor]) }
      >
      <DeleteForever className={classNames(classes[displayDelete], classes.iconSize)} />
      <Copy className={classNames(classes[displayCopy], classes.iconSize)} />
      <Lens className={classNames(classes[liveOrNot], classes[displayLive])} /> 
      <Save className={classNames(classes[displaySave], classes.iconSize, classes.saveIcon)} /> 
      <Edit className={classNames(classes[displayEdit], classes.iconSize, classes.editIcon)} />
      <Discard className={classNames(classes[displayDiscard], classes.iconSize)} />
      {btnText}
      </Button>

  );
}

CustomizedButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedButtons);