import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    button: props => ({
        padding: theme.spacing(1.25),
        fontSize: theme.spacing(2),
        textTransform: 'capitalize',
        fontWeight: 500,
        minWidth: 'fit-content',
        color: props.variant === 'outlined' ? props.bgColor : props.textColor,
        backgroundColor: props.variant === 'outlined' ? 'white' : props.bgColor,
        border: props.variant === 'outlined' ? '2px solid ' + props.bgColor : 0,
        '&:hover': {
            backgroundColor: props.variant === 'outlined' ? 'white' : props.bgColor,
            '@media (hover: none)': {
                backgroundColor: props.variant === 'outlined' ? 'white' : props.bgColor
            },
        },
    }),

}));

export default function FitWidthButton(props) {
    const { children } = props;
    const classes = useStyles(props);

    return (

        <Button
            variant={props.variant}
            color="default"
            className={classes.button}
            disabled={props.disabled}
            onClick={props.onClick}
            disableElevation
            classes={{ startIcon: classes.label }}
        >
            {children}
        </Button>

    );
}