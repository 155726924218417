import React, { PureComponent } from 'react';
export default class Dot extends PureComponent {
    render() {
        const {
            cx,
            cy,
            opacity,
            id,
            fill,
            onClick,
            onMouseOver,
            onMouseOut,
            value,
        } = this.props;
        if (!value) return null;
        return (
            <circle
                onClick={onMouseOver}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                r={6}
                id={id}
                opacity={opacity}
                cx={cx}
                cy={cy}
                strokeWidth={3}
                stroke={fill}
                fill={fill}
            />
        );
    }
}