import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    TelegramIcon,
    FacebookShareButton,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import { Event, PageView, initGA } from '../Tracking';
import { Grid, Link, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import {
    googleTrackingCategory,
    googleTrackingLabel
} from '../../store/types'
import styled, { keyframes } from 'styled-components';

import useEventInfo from '../../hooks/useEventInfo'
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CodeIcon from '@material-ui/icons/Code';
import CopyLink from './CopyLInk'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Fragment } from 'react';
import { IconButton } from '@material-ui/core';
import RegularButton from './buttons/RegularButton';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Warning from '@material-ui/icons/Warning';
import classnames from 'classnames';
import { constants } from '../../App.constants';
import { flipInX } from 'react-animations';
import { withStyles, useTheme } from '@material-ui/core/styles'
import fullWidthDialog from './dialogs/fullWidthDialog';
import EventAttendees from './EventAttendees';
import { AllcalIcon } from '../../assets/AllcalIcon'
import LabelTypeButton from '../ui/buttons/LabelTypeButton'
import FitWidthButton from '../ui/buttons/fitWidthButton'
import { useDispatch } from 'react-redux'
import { sendInvite, getRSVPForEvent } from '../../sagas/store/actions/rsvp.actions'

import TextField from "@material-ui/core/TextField";

const bounceAnimation = keyframes`${flipInX}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation} 1;
`;
const styles = theme => ({
    root: {
        padding: theme.spacing(2, 2, 10, 2)
    },
    noteIcon: {
        color: 'skyblue'
    },
    switchDiv: {
        display: 'flex',
        justifyContent: 'space-evenly',

    },
    btnsDiv: {
        marginTop: theme.spacing(5)
    },
    backBtnDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },

    buttonCustom2: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        color: theme.palette.primary.main,
        background: 'white',
        border: '1px solid ' + theme.palette.primary.main
    },
    inviteTextField: {
        width: '100%',
        marginBottom: theme.spacing(4)
    },
    btnsSection: {
        display: 'flex',
        justifyContent: 'center',

    },
    noteSummary: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    },
    marginBottom: {
        marginBottom: theme.spacing(3)
    },
    socialSection: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap'
    },
    note: {
        textAlign: 'center',
        fontSize: theme.spacing(3),
        justifyContent: 'center'
    },
    noteTop: {
        fontSize: theme.spacing(1.75)
    },
    chip: {
        fontSize: theme.spacing(2)
    },
    embedSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    embedHeading: {
        fontSize: theme.spacing(1.75),
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(0.5)
    },
    close: {
        cursor: 'pointer'
    },
    closeContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    copiedTextDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    marginTop: {
        marginTop: theme.spacing(3)
    },
    copiedUrlSection: {
        display: 'flex',
        justifyContent: 'center',
        color: 'green'

    },
    errDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'lightcoral'
    },
    errIcon: {
        fontSize: theme.spacing(2),
        paddingRight: theme.spacing(0.75)
    },
    afterCopy: {
        color: 'green'
    },
    marginBottom: {
        marginBottom: theme.spacing(5)
    },
    input: {
        padding: theme.spacing(0) + 'px' + ' ' + theme.spacing(1) + 'px',
        color: 'black'
    },
    paddingZero: {
        padding: theme.spacing(0) + 'px'
    }

})

const SharePosting = (props) => {

    const { classes } = props;
    const dispatch = useDispatch();
    let title = 'Hurry! Tickets are available now';
    const event = useEventInfo(props.calendarId, props.eventId)

    const [attendeeCount, setAttendeeCount] = useState(0)
    const [shareUrl, setShareUrl] = useState(null)
    const [shareBtnUrl, setShareBtnUrl] = useState(null)
    const [shareIframeUrl, setShareIframeUrl] = useState(null)
    const [embedClicked, setEmbedClicked] = useState(false)
    const [activeCopy, setActiveCopy] = useState()
    const [shareOnAllcal, setShareOnAllcal] = useState(false)
    const [inviteClicked, setInviteClicked] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [shareEmail, setShareEmail] = useState()
    const [invited, setInvited] = useState(false)
    const theme = useTheme()

    const onInviteClick = () => {
        Event(googleTrackingCategory.SHARE_SECTION, "Share event invite button clicked", props.eventId)

        setInvited(false)

        setInviteClicked(true)
    }
    const onCloseInvite = () => {

        setInviteClicked(false)
    }

    useEffect(() => {

        if (event) {
            let count =
                event.rsvpsStatistics['pending'] +
                event.rsvpsStatistics.attending +
                event.rsvpsStatistics['not attending'] +
                event.rsvpsStatistics.none;
            setAttendeeCount(count)
            setShareUrl(constants.ticketsBaseUrlAlt + "/calendar/" + event.originatingCalendar.calendarId + "/event/" + event.eventId + "/standalone")
            setShareBtnUrl('<button style="border: none;text-align: center;text-decoration: none;margin: 4px 2px;padding: 10px 16px!important;border-radius: 2px!important;color: #ffffff!important;font-family:Spen-serif !important;font-size: 14px!important;font-weight:bold;text-transform: uppercase!important;background-color: #8dc63f!important;" onclick="window.open(&quot;' + constants.ticketsBaseUrlAlt + "/calendar/" + event.originatingCalendar.calendarId + "/event/" + event.eventId + "/standalone" + '&quot;, &quot;_blank&quot; )">Buy Tickets</button>')
            setShareIframeUrl('<section><iframe src="' + constants.ticketsBaseUrlAlt + "/calendar/" + event.originatingCalendar.calendarId + "/event/" + event.eventId + '/standalone" width="100%" style="border: 0px; height: 100vh;" frameborder="0"></iframe></section>')
            setValidEmail(false)
            setInvited(false)
        } else {
            setShareUrl('')
        }

    }, [event])

    const embedClick = () => {
        Event(googleTrackingCategory.SHARE_SECTION, "EMBED Section Click", props.eventId);
        setEmbedClicked(true)
    }

    const back = () => {
        props.history && props.history.goBack();
    }

    const onTextChange = (e) => {
        setShareEmail(e.target.value)
        if (e.target.value && e.target.value.toLowerCase().match(/^\S+@\S+\.\S+$/i)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }
    }

    const shareEvent = () => {
        validEmail && dispatch(sendInvite(shareEmail, event.eventId, event.originatingCalendar.calendarId, (err) => {
            if (!err) {
                Event(googleTrackingCategory.SHARE_SECTION, "Share on allcal succeeded", props.eventId)

                dispatch(getRSVPForEvent(event.eventId, 0))
                setInviteClicked(false)
                let newCount = attendeeCount + 1
                setAttendeeCount(newCount)
                setInvited(true)
            } else {
                setInvited(false)
            }

        }))
    }

    const closeEmbed = () => {

        setEmbedClicked(false)
    }

    const shareOnAllcalClick = () => {
        Event(googleTrackingCategory.SHARE_SECTION, "Share on allcal button clicked", props.eventId)
        setShareOnAllcal(true)
    }

    const closeShareOnAllcal = () => {
        setInvited(false)

        setShareOnAllcal(false)
    }
    return (
        <div className={classes.root}>
            <Fragment>
                <Grid container spacing={2} direction="column" className={classnames(classes.socialSection, classes.marginBottom)}>
                    <Grid item xs>
                        {invited && <SuccessDiv theme={theme}>Invitation Sent</SuccessDiv>}
                        <RegularButton bgColor="red" onClick={shareOnAllcalClick} variant={'outlined'} textColor="#0075B8" icon={<AllcalIcon
                            filled={true} />}>Share On Allcal {attendeeCount > 0 && <Fragment>({attendeeCount && attendeeCount - 1})</Fragment>}</RegularButton>

                    </Grid>
                    {shareOnAllcal && <Fragment>
                        {!inviteClicked && <Fragment><Grid container justify='space-between' alignItems='center'>
                            <Grid container item xs={2} justify="flex-start" item xs={10}> <HeaderDiv item noWrap theme={theme}>{event && event.name}</HeaderDiv>
                            </Grid>
                            <Grid container item xs={2} justify="flex-end"><IconButton noWrap onClick={closeShareOnAllcal} aria-label="close" color="default">
                                <CloseIcon />
                            </IconButton>
                            </Grid>

                        </Grid>
                            <EventRsvpHeader theme={theme} container justify='space-between' alignItems='center'>
                                <Grid item><HeaderDivLight theme={theme}>Attendees ({attendeeCount && attendeeCount - 1})</HeaderDivLight></Grid>
                                <Grid item>
                                    <LabelTypeButton
                                        onClick={onInviteClick}
                                        variant='contained'
                                        bgColor={theme.palette.success.main}
                                        textColor={theme.palette.primary.contrastText}>{
                                            'Send Event Invite'
                                        }
                                    </LabelTypeButton>
                                </Grid>
                            </EventRsvpHeader> </Fragment>}

                        {inviteClicked && <Grid container justify='space-between' alignItems='center'>
                            <Grid container item xs={2} justify="flex-start" item xs={10}> <HeaderDiv item noWrap theme={theme}>{event && event.name}</HeaderDiv>
                            </Grid>
                            <Grid container item xs={2} justify="flex-end"><IconButton noWrap onClick={onCloseInvite} aria-label="close" color="default">
                                <CloseIcon />
                            </IconButton>
                            </Grid>
                            <Grid container item xs={12}>
                                <TextField
                                    id="standard-name"
                                    variant="outlined"
                                    className={classes.inviteTextField}
                                    placeholder='Please enter an email'
                                    onChange={onTextChange}
                                    InputProps={{
                                        classes: { input: classes.input, adornedEnd: classes.paddingZero },
                                        endAdornment: (
                                            <FitWidthButton onClick={shareEvent} disabled={!validEmail} variant='outlined' bgColor={theme.palette.primary.main} textColor={theme.palette.primary.contrastText}> Send Invite</FitWidthButton>
                                        )
                                    }}
                                />
                            </Grid>


                        </Grid>}

                        {props.eventId && <EventAttendees eventId={props.eventId}></EventAttendees>}
                    </Fragment>}


                </Grid>
                {!shareOnAllcal &&
                    <Fragment>
                        <Grid container >
                            <Grid item xs>
                                <CopyLink disabled={!!!event} text={activeCopy === 'url' ? 'COPIED TO CLIPBOARD' : 'COPY LINK'} bgColor={activeCopy === 'url' ? 'Green' : null} onCopy={() => { setActiveCopy('url'); Event(googleTrackingCategory.SHARE_SECTION, "Copied URL to clipboard", props.eventId) }} shareUrl={shareUrl}></CopyLink>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="column" className={classnames(classes.socialSection, classes.marginTop)}>
                            <Grid container item>
                                <Grid item xs>
                                    <FacebookShareButton
                                        url={shareUrl}
                                        quote={title}
                                        disabled={!!!event}
                                    >
                                        <RegularButton onClick={() => { Event(googleTrackingCategory.SHARE_SECTION, "FB share clicked", props.eventId) }} bgColor="red" variant={'outlined'} textColor="#3B5998" icon={<FacebookIcon
                                            size={32}
                                            round />}>Share On Facebook</RegularButton>


                                    </FacebookShareButton>
                                </Grid>

                            </Grid>
                            <Grid container item>
                                <Grid item xs>
                                    <TwitterShareButton disabled={!!!event} size={32} round={true} title={title} hashtags={['allcal', 'tickets']} url={shareUrl}>

                                        <RegularButton onClick={() => { Event(googleTrackingCategory.SHARE_SECTION, "Twitter share clicked", props.eventId) }} bgColor="red" variant={'outlined'} textColor="#00ACED" icon={<TwitterIcon
                                            size={32}
                                            round />}>Share On Twitter</RegularButton>


                                    </TwitterShareButton>
                                </Grid>

                            </Grid>
                            <Grid container item>
                                <Grid item xs>
                                    <WhatsappShareButton disabled={!!!event} size={32} round={true} title={title} url={shareUrl}>

                                        <RegularButton onClick={() => { Event(googleTrackingCategory.SHARE_SECTION, "Whatsapp share clicked", props.eventId) }} bgColor="red" variant={'outlined'} textColor="#2CB742" icon={<WhatsappIcon
                                            size={32}
                                            round />}>Share On Whatsapp</RegularButton>


                                    </WhatsappShareButton>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs>
                                    <EmailShareButton disabled={!!!event} size={32} subject={title} body={"Hurry ! Purchase Your Tickets"} round={true} title={title} url={shareUrl}>

                                        <RegularButton onClick={() => { Event(googleTrackingCategory.SHARE_SECTION, "Share over email", props.eventId) }} bgColor="red" variant={'outlined'} textColor="#7F7F7F" icon={<EmailIcon
                                            size={32}
                                            round />}>Share over email</RegularButton>

                                    </EmailShareButton>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs>
                                    <TelegramShareButton disabled={!!!event} size={32} round={true} title={title} url={shareUrl}>

                                        <RegularButton onClick={() => { Event(googleTrackingCategory.SHARE_SECTION, "Telegram share clicked", props.eventId) }} bgColor="red" variant={'outlined'} textColor="#37AEE2" icon={<TelegramIcon
                                            size={32}
                                            round />}>Share on Telegram</RegularButton>

                                    </TelegramShareButton>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs>
                                    <RegularButton disabled={!!!event} onClick={() => { embedClick() }} bgColor="red" variant={'outlined'} textColor="#4B626F" icon={<CodeIcon>
                                    </CodeIcon>}>Get Embed Code</RegularButton>
                                </Grid>
                            </Grid>

                            {embedClicked &&
                                <div style={{ marginTop: '16px', padding: '16px' }}>
                                    <div className={classes.closeContainer} > <CloseIcon className={classes.close} onClick={closeEmbed}></CloseIcon>
                                    </div>
                                    <div className={classes.embedHeading}>EMBED AS IFRAME </div>
                                    <div className={classes.embedSection}>
                                        <TextareaAutosize
                                            style={{ border: 'solid 1px skyblue', width: '100%' }}
                                            rowsMax={4}
                                            placeholder="Maximum 4 rows"
                                            value={shareIframeUrl}
                                        />
                                        <CopyToClipboard text={shareIframeUrl}
                                            onCopy={() => { setActiveCopy('iframe'); Event(googleTrackingCategory.SHARE_SECTION, "Embed Iframe code copied", props.eventId) }}>
                                            <Button color="primary" className={classes.button} >
                                                {activeCopy === 'iframe' ? 'COPIED' : 'COPY'}
                                            </Button>
                                        </CopyToClipboard>

                                    </div>
                                    <div className={classes.embedHeading}>EMBED AS BUTTON</div>
                                    <div className={classes.embedSection}>
                                        <TextareaAutosize
                                            style={{ border: 'solid 1px skyblue', width: '100%' }}
                                            rowsMax={4}
                                            placeholder="Maximum 4 rows"
                                            value={shareBtnUrl}
                                            defaultValue={shareBtnUrl}
                                        />
                                        <CopyToClipboard text={shareBtnUrl}
                                            onCopy={() => { setActiveCopy('button'); Event(googleTrackingCategory.SHARE_SECTION, "Embed Button code copied", props.eventId) }}>
                                            <Button color="primary" className={classes.button} >
                                                {activeCopy === 'button' ? 'COPIED' : 'COPY'}
                                            </Button>
                                        </CopyToClipboard>

                                    </div>
                                </div>
                            }

                        </Grid>
                    </Fragment>

                }



            </Fragment>

        </div >
    )



}

const HeaderDiv = styled(Typography)`
  font-weight : 500;
  ${ ({ theme }) => `
  font-size : ${ theme.spacing(2)}px;
  ` };
`
const SuccessDiv = styled(Typography)`
${ ({ theme }) => `
  color : ${ theme.palette.success.main};
margin-bottom: ${ theme.spacing(2)}px;
  ` };
display: flex;
justify-content: center;

`

const EventRsvpHeader = styled(Grid)`
  ${ ({ theme }) => `
      padding: ${theme.spacing(2)}px ${theme.spacing(0)}px   
  ` };
`
const HeaderDivLight = styled.div`
  font-weight : 500;
  ${ ({ theme }) => `
  font-size : ${ theme.spacing(2)}px;
  ` };
`

export default (withStyles(styles)(SharePosting))


