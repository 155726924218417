import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1,
    fontWeight:500
  },
});

function DateCard(props) {
  const { classes } = props;
  const {text} = props;
  return (
    <div>
      <Paper className={classes.root} elevation={1}>
        
          <p>{text}</p>
          
      </Paper>
    </div>
  );
}

DateCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateCard);