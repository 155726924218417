import { Event, PageView, initGA } from './Tracking';
import React, { Component } from 'react'
import { checkInOutTicket, emailTicket } from '../store/actions/purchasedTicketActions'
import { googleTrackingCategory, googleTrackingLabel } from '../store/types'

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import SearchBar from './ui/searchBar'
import TicketCard from './ui/ticketCard'
import classnames from 'classnames';
import { connect } from 'react-redux'
import { filter } from 'lodash'
import { getTicketPostings } from '../store/actions/manageTicketsActions'
import { getTicketsReport } from '../store/actions/ticketsReportActions'
import moment from 'moment-timezone';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),


    },
    icon: {
        fontSize: theme.spacing(8),
        color: 'indianRed'
    },
    text: {
        fontSize: theme.spacing(2)
    },
    scrollDiv: {
        height: '75vh',
        overflow: 'auto',
        marginTop: theme.spacing(2)
    },
    heading: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: theme.spacing(3),
        fontWeight: 600
    },

    btnsDiv: {
        marginTop: theme.spacing(5)
    },
    backBtnDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },
    button: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        letterSpacing: '1px',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            background: 'transparent'
        }
    },

    buttonCustom2: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        color: theme.palette.primary.main,
        background: 'white',
        border: '1px solid ' + theme.palette.primary.main
    },
    noTicks: {
        display: 'flex',
        justifyContent: 'center',
        color: 'grey',
        paddingTop: theme.spacing(2)
    },
    chipsDiv: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    paddingChip: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
})
export class moreReports extends Component {
    state = {
        openFilter: false,
        selectedPostings: {},
        isFilterSelected: false,
        filterText: undefined,
        chipsArr: [],
        loadingTicks: false
    }
    callbackAfterTicketsInfo = () => {
        this.setState({ loadingTicks: false })
    }
    componentDidMount() {

        /*initGA('UA-106455531-6');
        PageView('/more'); */
        // this.setState({loadingTicks: true})


    }

    back = () => {
        //console.log("Back");
        this.props.history && this
            .props
            .history
            .goBack();
    }
    onStampClick = (ticketId, event, calendar, type, callbackAfterStamp) => {
        //console.log("Stamp Clicked" + ticketId)
        Event(googleTrackingCategory.MORE_SECTION, "Stamp Ticket Clicked", event);

        this
            .props
            .checkInOutTicket(ticketId, event, type, callbackAfterStamp)

    }
    onEmailSendClick = (ticketId, event, calendar, email, posting, callbackToComp) => {
        //console.log("Email Send Clicked");
        Event(googleTrackingCategory.MORE_SECTION, "Email Send Clicked", event);

        this
            .props
            .emailTicket(ticketId, posting, event, calendar, email, callbackToComp)

    }

    callbackAfterEmailSending = (isError, msg) => {
        //console.log(isError); console.log(msg)
    }
    onFilterClick = () => {
        //console.log("IN here")
        this.setState({
            openFilter: !this.state.openFilter
        })
    }
    handleCloseFilter = () => {
        // console.log("filter close clicked");
        this.setState({ openFilter: false })
    }
    searchClickFn = () => {
        //console.log("Search clicked !! ")
    }
    handleFilterTextChange = (val) => {
        //console.log(val)

        this.setState({ filterText: val })
    }

    render() {
        const { classes } = this.props;

        let filteredTickets = (this.state.filterText && this.state.filterText != '' && this.props.ticketsInfo.length > 0)
            ? filter(this.props.ticketsInfo, (ticket) => {
                let re = this.state.filterText.toUpperCase();
                return ticket && (ticket.postingMeta && ticket
                    .postingMeta
                    .title
                    .toUpperCase()
                    .indexOf(re) !== -1) ||
                    (ticket.user && ticket.user.name.toUpperCase().indexOf(re) !== -1) ||
                    (ticket.user && ticket.user.email.toUpperCase().indexOf(re) !== -1) ||
                    (ticket.owner && ticket.owner.name.toUpperCase().indexOf(re) !== -1) ||
                    (ticket.owner && ticket.owner.email.toUpperCase().indexOf(re) !== -1) ||
                    (ticket.phone && ticket.phone.indexOf(re) !== -1) ||
                    (ticket.number.toUpperCase().indexOf(re) !== -1) ||
                    (ticket.amount.toUpperCase().indexOf(re) !== -1) ||
                    ((this.state.filterText.toUpperCase() != 'UNSTAMPED' && this.state.filterText.toUpperCase().indexOf('STAMPED') !== -1) && ticket.isStamped) ||
                    ((this.state.filterText.toUpperCase().indexOf('UNSTAMPED') !== -1) && !ticket.isStamped)

            })
            : this.props.ticketsInfo;

        //console.log(filteredTickets);
        return (
            <div className={classes.root}>

                <SearchBar
                    open={this.state.openFilter}
                    isFilterSelected={this.state.isFilterSelected}
                    handleClose={this.handleCloseFilter}
                    ticketPostings={this.props.ticketPostings}
                    handleChange={this.handleChangeFilter}
                    passedOnStateWithSelectedPostings={this.state}
                    onFilterClick={this.onFilterClick}
                    searchClickFn={this.searchClickFn}
                    handleFilterTextChange={this.handleFilterTextChange}></SearchBar>
                {filteredTickets && filteredTickets.length > 0
                    ? <div className={classes.scrollDiv}>
                        {filteredTickets.map((ticket, index) => {
                            return <TicketCard
                                key={index}
                                ticketId={ticket._id}
                                name={ticket.user ? ticket.user.name : ''}
                                email={ticket.user ? ticket.user.email : ''}
                                phone={ticket.phone}
                                event={ticket.event}
                                calendar={ticket.calendar}
                                statusText={ticket.isStamped
                                    ? 'Stamped'
                                    : 'None'}
                                posting={ticket.posting}
                                postingName={ticket.postingMeta ? ticket.postingMeta.title : ''}
                                ticketNumber={ticket.number}
                                postingTime={moment(ticket.createdTime).format('lll')}
                                onStampClick={this.onStampClick}
                                onEmailSendClick={this.onEmailSendClick}
                                isStamped={ticket.isStamped}></TicketCard>

                        })
                        }

                    </div>
                    : <div className={classes.scrollDiv}>
                        {!this.state.loadingTicks && <div className={classes.noTicks}>No tickets to display</div>}
                    </div>
                }

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        ticketsInfo: state.ticketsReport.ticketsInfo,
        ticketPostings: state.ticketPostings.postings
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        getTicketsReport: (calendarId, eventId, token, postingsToFilter, callbackIfAny) => dispatch(getTicketsReport(calendarId, eventId, token, postingsToFilter, callbackIfAny)),
        checkInOutTicket: (ticketId, eventId, stampType, callbackFn) => dispatch(checkInOutTicket(ticketId, eventId, stampType, callbackFn)),
        emailTicket: (ticketId, posting, eventId, calendarId, email, callbackFn) => dispatch(emailTicket(ticketId, posting, eventId, calendarId, email, callbackFn)),
        getTicketPostings: (eventId, filterArr, callback) => dispatch(getTicketPostings(eventId, filterArr, callback))

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(moreReports))
