import { dispatchErrorWrap, dispatchSuccessWrap } from './genericActions'
import { filter, findIndex } from 'lodash'

import { actionTypes } from '../types';
import { constants } from '../../App.constants';

const axios = require('axios');

export const saveSelectedTicketImages = (imagesToSave) => {
    // Make async call  dispatchSuccessWrap('CHANGE_ORGANIZATION', org, dispatch)

    return (dispatch, getState) => {
        // Make async call console.log("GET STATE" + getState());
        try {
            dispatchSuccessWrap(actionTypes.SAVE_IMAGES, {
                'imagesToSave': imagesToSave
            }, dispatch)

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }
        //dispatchSuccessWrap({type : 'ADD_WORKER', worker : worker});
    }

}
export const getTicketImagesFromDB = (token, calendarId, eventId, postingId) => {

    return async (dispatch, getState) => {
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }

            const responsefromDB = await axios.get(constants.ticketsBaseUrl + "/calendar/" + calendarId + "/event/" + eventId + "/posting/" + postingId + "/image", headers);

            !responsefromDB.data.error && dispatchSuccessWrap(actionTypes.GET_TICKET_IMAGES_FROM_DB, {
                'ticketImages': responsefromDB.data.message
            }, dispatch);

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }
    }
}
export const getTicketImages = (numberOfTickets) => {
    // Make async call  dispatchSuccessWrap('CHANGE_ORGANIZATION', org, dispatch)

    return (dispatch, getState) => {
        // Make async call console.log("GET STATE" + getState());
        try {
            dispatchSuccessWrap(actionTypes.GET_IMAGES, {
                'numberOfTickets': numberOfTickets
            }, dispatch)

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }
        //dispatchSuccessWrap({type : 'ADD_WORKER', worker : worker});
    }

}

export const uploadTicketImage = (file, token, calendarId, eventId, postingId, ticketNumber, ticketTitle, ticketMoreInfo, callbackFn) => {

    return async (dispatch, getState) => {
        // Make async call console.log("GET STATE" + getState());
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }
            let reqPayload = {};
            let randomStr = +new Date()
            reqPayload.fileName = file
                .name
                .split('.')[0] + '_' + randomStr;
            reqPayload.fileType = file.type;
            //reqPayload.file = file;

            const response = await axios.post(constants.ticketsBaseUrl + "/signS3", reqPayload, headers);

            var returnData = response.data.message;
            var signedRequest = returnData.signedRequest;

            try {
                var options = {
                    headers: {
                        'Content-Type': file.type
                    }
                };
                const responseAfterUploadingImg = await axios.put(signedRequest, file, options)

                const headers = {
                    headers: {
                        'Content-Type': 'application/json',
                        'login-token': token
                    }
                }
                let payload = {};
                payload.ticketNumber = ticketNumber;
                payload.ticketImage = returnData.url;

                if (ticketTitle) {
                    payload.ticketTitle = ticketTitle;
                }
                if (ticketMoreInfo) {
                    payload.moreInfo = ticketMoreInfo;
                }
                //reqPayload.file = file;

                await axios.put(constants.ticketsBaseUrl + "/calendar/" + calendarId + "/event/" + eventId + "/posting/" + postingId + "/image", payload, headers);
                callbackFn();
            } catch (error) {
                // console.log(error);
            }

        } catch (error) {
            dispatchErrorWrap(error, dispatch);
        }
        //dispatchSuccessWrap({type : 'ADD_WORKER', worker : worker});
    }
}

export const updateTicketUploads = (token, calendarId, eventId, postingId, callbackFn) => {

    return async (dispatch, getState) => {
        try {
            let modifiedTicketUploads = getState().ticketsUpload.ticketImages;
            let uploadsFromDB = [];

            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': token
                }
            }

            const responsefromDB = await axios.get(constants.ticketsBaseUrl + "/calendar/" + calendarId + "/event/" + eventId + "/posting/" + postingId + "/image", headers);

            uploadsFromDB = !responsefromDB.data.error
                ? responsefromDB.data.message
                : [];

            let filesToDelete = [];
            let filesToConsiderForModifyCheck = [];
            let filesToModify = [];
            let filesModified = [];

            if (uploadsFromDB.length > 0 && uploadsFromDB.length >= modifiedTicketUploads.length) {

                filesToDelete = filter(uploadsFromDB, function (o1) {
                    // if match found return false
                    return findIndex(modifiedTicketUploads, { '_id': o1._id }) !== -1
                        ? false
                        : true;
                });

                filesToConsiderForModifyCheck = filter(uploadsFromDB, function (o1) {
                    // if match found return false
                    return findIndex(modifiedTicketUploads, { '_id': o1._id }) === -1
                        ? false
                        : true;
                });

                filesToModify = filter(filesToConsiderForModifyCheck, function (o1) {
                    // if match found return false
                    return findIndex(modifiedTicketUploads, {
                        '_id': o1._id,
                        'ticketImage': o1.ticketImage,
                        'ticketTitle': o1.ticketTitle,
                        'moreInfo': o1.moreInfo
                    }) !== -1
                        ? false
                        : true;
                });

                filesModified = filter(modifiedTicketUploads, function (o1) {
                    // if match found return false
                    return findIndex(filesToModify, { '_id': o1._id }) === -1
                        ? false
                        : true;
                });

            } else {
                filesModified = modifiedTicketUploads;
            }


            // DELETE API

            if (filesToDelete.length > 0) {
                // DELETE
                filesToDelete.forEach(async (fileToDelete) => {
                    const headers = {
                        headers: {
                            'Content-Type': 'application/json',
                            'login-token': token
                        }
                    }
                    await axios.delete(constants.ticketsBaseUrl + "/calendar/" + calendarId + "/event/" + eventId + "/posting/" + postingId + "/image/" + fileToDelete._id, headers);

                })
            }

            if (filesModified.length > 0) {

                filesModified.forEach(async (fileToModify) => {

                    let payload = {};

                    if (fileToModify.rawFile) {
                        const headers = {
                            headers: {
                                'Content-Type': 'application/json',
                                'login-token': token
                            }
                        }
                        let reqPayload = {};
                        reqPayload.fileName = fileToModify
                            .rawFile
                            .name
                            .split('.')[0];
                        reqPayload.fileType = fileToModify.rawFile.type;

                        const response = await axios.post(constants.ticketsBaseUrl + "/signS3", reqPayload, headers);

                        var returnData = response.data.message;
                        var signedRequest = returnData.signedRequest;

                        var options = {
                            headers: {
                                'Content-Type': fileToModify.rawFile.type
                            }
                        };
                        await axios.put(signedRequest, fileToModify.rawFile, options);
                        payload.ticketImage = returnData.url;
                    }

                    const headers = {
                        headers: {
                            'Content-Type': 'application/json',
                            'login-token': token
                        }
                    }
                    payload.ticketNumber = fileToModify.ticketNumber;

                    if (fileToModify.ticketTitle) {
                        payload.ticketTitle = fileToModify.ticketTitle;
                    }
                    if (fileToModify.moreInfo) {
                        payload.moreInfo = fileToModify.moreInfo;
                    }

                    await axios.put(constants.ticketsBaseUrl + "/calendar/" + calendarId + "/event/" + eventId + "/posting/" + postingId + "/image", payload, headers);

                })
            }
            callbackFn(null);
        } catch (error) {
            callbackFn(error)
        }

    }
}
