import {
  actionTypes
} from "../types";
import { filter } from 'lodash';

const initState = {
  ticketImages: []
};

const ticketsReportReducer = (state = initState, action) => {

  switch (action.type) {
    case actionTypes.SAVE_IMAGES: {

      let newState = {
        ticketImages: action.payload.imagesToSave
      }
      return newState;

    }
    case actionTypes.GET_IMAGES: {

      let newState = {
        ticketImages: [
          ...filter(state.ticketImages, (img) => (img.ticketNumber < action.payload.numberOfTickets))
        ]
      }
      return newState;

    }
    case actionTypes.CLEAR_IMAGES: {
      return {
        ticketImages: []
      }
    }
    case actionTypes.GET_TICKET_IMAGES_FROM_DB: {

      let newState = {
        ticketImages: action.payload.ticketImages
      }
      return newState;

    }

    default: {
      return state;
    }
  }

}



export default ticketsReportReducer
