import {Link, withRouter} from 'react-router-dom';
import React,{Component} from 'react';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AssesmentIcon from '@material-ui/icons/Assessment'
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import MultilineChartOutlinedIcon from '@material-ui/icons/MultilineChartOutlined';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex : '9999'
  },
};

class LabelBottomNavigation extends Component {
  //Later we will introduce Reduc and map Common Store to Props
  state = {
    value: 'recents',
  };

  handleChange = (event, value) => {
    this.setState({ 
      value : value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <BottomNavigation showLabels value={value} onChange={this.handleChange} className={classes.root}>
        <BottomNavigationAction  label="Create" value="create" icon={<AddCircleOutlineIcon />} component={Link} to="/create" />
        <BottomNavigationAction label="Manage" value="manage" icon={<CreateOutlinedIcon />} component={Link} to="/manageTickets" />
        <BottomNavigationAction label="Share" value="share" icon={<ShareOutlinedIcon />} component={Link} to="/share"/>
        <BottomNavigationAction label="Analytics" value="analytics" icon={<MultilineChartOutlinedIcon />} component={Link} to="/analytics"/>
        <BottomNavigationAction label="Settings" value="settings" icon={<SettingsOutlinedIcon/>} component={Link} to="/settings"/>
    </BottomNavigation>
    );
  }
}

LabelBottomNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(LabelBottomNavigation));