import {
    dispatchErrorWrap,
    dispatchSuccessWrap
} from './genericActions'

import {
    actionTypes
} from '../types';
import {
    constants
} from '../../App.constants';

const axios = require('axios');

export const checkInOutTicket = (ticketId, eventId, stampType, callbackFn) => {
    return async (dispatch, getState) => {

        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }
            let reqPayload = {};
            reqPayload.eventId = eventId;
            reqPayload.stampType = stampType;

            const payload = await axios.put(constants.ticketsBaseUrl + "/ticket/" + ticketId + '?isManual=true', reqPayload, headers);
            //console.log(payload);
            payload && !payload.data.error ? dispatchSuccessWrap(actionTypes.CHECK_IN_OUT, {
                'ticketId': payload.data.message.ticketId,
                'isStamped': payload.data.message.isStamped
            }, dispatch) : dispatchErrorWrap('Unable to check in out ticket', dispatch);
            setTimeout(() => {
                callbackFn(payload.data.error, payload.data.message)
            }, 200);
        } catch (error) {
            callbackFn(true, 'Error');
            dispatchErrorWrap(error, dispatch);
        }

    }


}

export const emailTicket = (ticketId, posting, eventId, calendarId, email, callbackFn) => {
    return async (dispatch, getState) => {

        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'login-token': localStorage.getItem('loginToken')
                }
            }
            let reqPayload = {};
            reqPayload.email = email;
            reqPayload.posting = posting;

            const payload = await axios.post(constants.ticketsBaseUrl + "/calendar/" + calendarId + '/event/' + eventId + '/ticket/' + ticketId + '/email', reqPayload, headers);
            //console.log(payload);
            payload && !payload.data.error ? dispatchSuccessWrap(actionTypes.SEND_TICKET, {
                'data': payload.data
            }, dispatch) : dispatchErrorWrap('Unable to send ticket', dispatch);
            setTimeout(() => {
                callbackFn(payload.data.error, payload.data.message)
            }, 200);
        } catch (error) {
            callbackFn(true, 'Error');
            dispatchErrorWrap(error, dispatch);
        }

    }


}